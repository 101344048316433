<template>
    <el-dialog
        :visible.sync="visible"
        width="1000px"
        :close-on-click-modal="false"
    >
        <div v-loading="isLoading">
            <template v-if="!isLoading">
                <div
                    v-for="(formItem, index) in formData"
                    :key="index"
                >
                    <edit-transfer-form-item
                        v-model="formData[index]"
                        style="border: 1px solid black"
                    />
                </div>
            </template>
            <el-row
                type="flex"
                justify="center"
                align="middle"
            >
                <el-button
                    type="primary"
                    @click="_onSaveBtnClick"
                >
                    Сохранить
                </el-button>
                <el-button
                    type="danger"
                    @click="_onCancelBtnClick"
                >
                    Отмена
                </el-button>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import EditTransferFormItem from '@/modules/event/components/card/transfer/EditTransferFormItem';
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
    name: 'EditTransferForm',
    components: { EditTransferFormItem },
    props: {
        participantId: {
            type: String,
            default: null
        },
        formVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            formData: {}
        };
    },
    computed: {
        visible: {
            get() {
                return this.formVisible;
            },
            set(value) {
                this.$emit('update:form-visible', value);
            }

        }
    },
    watch: {
        visible(value) {
            if (value === true) {
                this._fetchData();
            }
        }
    },
    methods: {
        ...mapActions('event/transfer', [
            'getParticipantTransferGroup',
            'updateParticipantTransferGroup'
        ]),

        _fetchData() {
            this.isLoading = true;
            this.getParticipantTransferGroup(this.participantId)
                .then(response => {
                    this.formData = {};
                    response.forEach(item => {
                        /**
                         * Статус NOT_BOOKED и REFUSED меняем на REFUSED, т.к. NOT_BOOKED - излишний
                         * todo: убрать лишние статусы NOT_BOOKED и REFUSED.
                         * @returns {{}}
                         */
                        let preparedData = { ...item.attributes };

                        const hasRefusedStatuses = ['REFUSED', 'NOT_BOOKED'].includes(preparedData.status);
                        if (hasRefusedStatuses) {
                            preparedData['status'] = 'REFUSED';
                        }
                        this.formData[item.id] = preparedData;

                    });
                })
                .catch(error => {
                    let message = _.get(error, 'response.data.message') || error.message;
                    this.$message.error(message);
                })
                .finally(() => this.isLoading = false);
        },

        _onSaveBtnClick() {
            let dataToUpdate = _.values(this.formData);
            this.updateParticipantTransferGroup(dataToUpdate)
                .then(response => {
                    let message = response.message;
                    this.$message.success(message);
                })
                .catch(error => {
                    let message = _.get(error, 'response.data.message') || error.message;
                    this.$message.error(message);
                })
                .finally(() => {
                    this.$emit('saved');
                    this.visible = false;
                });
        },

        _onCancelBtnClick() {
            this.visible = false;
        }
    }
};
</script>

<style lang="scss">

</style>
