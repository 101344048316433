<template>
    <div>
        <el-table :data="transferList">
            <el-table-column
                label="Фамилия, имя, отчество"
                prop="fullname"
            >
                <template slot-scope="scope">
                    <router-link
                        :to="_participantCard(scope.row)"
                    >
                        {{ scope.row.fullname }}
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column
                label="Телефон"
                prop="phone"
            />
            <el-table-column
                v-if="columnIsVisible"
                label="Где забрать"
                prop="arrivalTransferPlace"
            />
            <el-table-column
                v-if="columnIsVisible"
                label="Время"
                prop="arrivalTime"
            />
            <el-table-column
                v-if="columnIsVisible"
                label="Откуда уедет"
                prop="departureTransferPlace"
            />
            <el-table-column
                v-if="columnIsVisible"
                label="Время"
                prop="departureTime"
            />
            <el-table-column
                label="Статус"
                prop="statusTranslated"
            />
            <el-table-column>
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="primary"
                        @click="onEditBtnClick(scope.row)"
                    >
                        Редактировать
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <book-transfer-form
            ref="bookTransferForm"
            @submit="onBookTransferFormSubmit"
        />
        <edit-transfer-form
            :participant-id="participantTransferToEdit"
            :form-visible.sync="isParticipantTransferFormVisible"
            @saved="onEditTransferFormSaved"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import { STATUSES } from './config';
import { mapActions, mapState } from 'vuex';
import BookTransferForm from '@/modules/event/components/card/transfer/BookTransferForm';
import { ROUTES } from '@/enums';
import EditTransferForm from '@/modules/event/components/card/transfer/EditTransferForm';

export default {
    name: 'TransferList',
    components: {
        EditTransferForm,
        BookTransferForm
    },
    data() {
        return {
            statuses: STATUSES,
            isBookTransferFormVisible: false,
            isParticipantTransferFormVisible: false,
            participantTransferToEdit: null
        };
    },
    computed: {
        ...mapState('event/transfer', [
            'participantTransferList'
        ]),
        transferList() {
            return this.participantTransferList.map(item => {
                return {
                    id: item.id,
                    fullname: _.get(item, 'attributes.fullname'),
                    participantId: _.get(item, 'attributes.participantId'),
                    phone: _.get(item, 'attributes.phone'),
                    arrivalTime: _.get(item, 'attributes.arrivalTime'),
                    arrivalTransferPlace: _.get(item, 'attributes.arrivalTransferPlace.name'),
                    departureTime: _.get(item, 'attributes.departureTime'),
                    departureTransferPlace: _.get(item, 'attributes.departureTransferPlace.name'),
                    status: _.get(item, 'attributes.status'),
                    statusTranslated: _.get(item, 'attributes.statusTranslated')
                };
            });
        },
        status() {
            return this.$route.query.status;
        },
        eventId() {
            return this.$route.params.eventId;
        },
        columnIsVisible() {
            return this.status === STATUSES.BOOKED_STATUS;
        },
        bookBtnIsVisible() {
            return [STATUSES.REFUSED_STATUS, STATUSES.NOT_BOOKED_STATUS].includes(this.status);
        },
        refuseBtnIsVisible() {
            return [STATUSES.BOOKED_STATUS, STATUSES.NOT_BOOKED_STATUS].includes(this.status);
        }
    },
    methods: {
        ...mapActions('event/transfer', [
            'updateParticipantTransfer'
        ]),

        _participantCard(row) {
            let participantId = row.participantId;
            return {
                name: ROUTES.EVENT.PARTICIPANT.CARD,
                params: {
                    eventId: this.eventId,
                    participantId
                }
            };
        },

        _tryUpdateTransfer(participantTransferId, data) {
            this.updateParticipantTransfer({
                eventId: this.eventId,
                participantTransferId,
                data
            })
                .then((response) => {
                    if (response.success) {
                        this.$message.success(response.message);
                        return;
                    }
                    throw new Error(response.message);
                })
                .catch((error) => {
                    let message = _.get(error, 'response.data.message') || error.message;
                    this.$message.error(message);
                })
                .finally(() => {
                    this.$emit('transferUpdated');
                    this.isLoading = false;
                });
        },
        onEditBtnClick(row) {
            const participantId = row.participantId;
            this.participantTransferToEdit = participantId;
            this.isParticipantTransferFormVisible = true;
        },
        onBookBtnClick(row) {
            this.$refs.bookTransferForm.open(row.id);
        },
        onRefuseBtnClick(row) {
            let data = {
                status: STATUSES.REFUSED_STATUS
            };
            this._tryUpdateTransfer(row.id, data);
        },
        onBookTransferFormSubmit(formData) {
            this._tryUpdateTransfer(formData.id, formData.data);
        },
        onEditTransferFormSaved() {
            this.$emit('transferUpdated');
        }
    }
};
</script>
