<template>
    <el-tabs
        v-model="currentTab"
        type="card"
        @tab-click="_select"
    >
        <el-tab-pane
            v-for="item in tabs"
            :key="item.route"
            :name="item.route"
            :label="item.title"
        />
    </el-tabs>
</template>

<script>
import { ROUTES } from '@/enums';
import { mapState } from 'vuex';

export default {
    name: 'EventCardTabs',

    data() {
        return {
            currentTab: null
        };
    },

    computed: {
        ...mapState('event', [
            'activeTabRoute'
        ]),

        tab() {
            return this.activeTabRoute || ROUTES.EVENT.CARD.PARTICIPANTS;
        },

        tabs() {
            return [
                {
                    title: 'Список гостей',
                    route: ROUTES.EVENT.CARD.PARTICIPANTS
                },
                {
                    title: 'Трансфер',
                    route: ROUTES.EVENT.CARD.TRANSFER
                },
                {
                    title: 'Отель',
                    route: ROUTES.EVENT.CARD.HOTEL
                },
                {
                    title: 'Статистика',
                    route: ROUTES.EVENT.CARD.STATS
                },
                {
                    title: 'Услуги',
                    route: ROUTES.EVENT.CARD.SERVICE.ADD_PARTICIPANT
                }
            ];
        }
    },

    watch: {
        activeTabRoute: {
            handler() {
                this._onActiveTabRouteChange();
            },
            immediate: true
        }
    },

    mounted() {
        this.currentTab = this.tab;
    },

    methods: {
        _onActiveTabRouteChange() {
            this.currentTab = this.activeTabRoute;
        },

        _select(tab) {
            this.$router.push({
                name: tab.name
            });
        }
    }
};
</script>
