<template>
    <el-card
        v-loading="isLoading"
        class="stats-box-card"
        shadow="hover"
    >
        <div class="stats-box-card__title">
            Количество гостей
        </div>
        <el-table
            :data="participantsByStatus"
            style="width: 100%"
            :show-header="false"
            :row-class-name="tableRowClassName"
        >
            <el-table-column
                prop="title"
            />
            <el-table-column
                prop="count"
                align="center"
            />
        </el-table>
    </el-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'ParticipantsByStatusAndEvent',
    props: {
        eventId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    computed: {
        ...mapState('stats', [
            'participantsByStatus'
        ])
    },
    async mounted() {
        this.isLoading = true;
        await this.getParticipantsByStatus({ eventId: this.eventId });
        this.isLoading = false;
    },
    methods: {
        ...mapActions('stats', [
            'getParticipantsByStatus'
        ]),
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 0) {
                return 'stats-box-card-row--green';
            } else if (rowIndex === this.participantsByStatus.length - 1) {
                return 'stats-box-card-row--big stats-box-card-row--green';
            }
            return '';
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.stats-box-card {
    max-width: 308px;
    font-size: 14px;
    margin-bottom: 25px;
    word-break: break-all;

    &__title {
        font-size: 24px;
        margin-bottom: 10px;
    }

    &-row {
        &--green {
            color: $color-secondary-text;
        }

        &--big {
            font-size: 24px;
        }
    }
}
</style>
