<template>
    <div class="schedule">
        <schedule-form
            ref="form"
            @failed="_onFailed"
        />

        <schedule-edit-form
            ref="formEdit"
            @failed="_onEditError"
        />

        <div v-if="isParticipantSelectorAvailable">
            <table>
                <tr>
                    <td>
                        <h1 class="app-h1 app-h1--no-margin">
                            Записать гостя
                        </h1>
                    </td>
                    <td>
                        <event-participant-selector
                            v-model="selectedParticipant"
                            class="schedule__participant-selector"
                        />
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td>
                        <h1 class="app-h1 app-h1--no-margin">
                            на услугу
                        </h1>
                    </td>
                    <td>
                        <event-service-selector
                            :event-id="eventId"
                            @select="_onServiceSelect"
                        />
                    </td>
                </tr>
            </table>
        </div>
        <div v-else>
            <table>
                <tr>
                    <td>
                        <h1 class="app-h1 app-h1--no-margin">
                            Расписание услуги
                        </h1>
                    </td>
                    <td>
                        <event-service-selector
                            :event-id="eventId"
                            @select="_onServiceSelect"
                        />
                    </td>
                </tr>
            </table>
        </div>

        <el-alert
            v-if="!weeks.length"
            type="info"
            title="Нет данных"
            :closable="false"
            show-icon
        />

        <el-row
            type="flex"
            class="schedule-table"
        >
            <el-col
                :sm="24"
                :md="16"
                :lg="18"
            >
                <div class="schedule-cols schedule-cols--days">
                    <div
                        v-for="dayIndex in [0,1,2,3,4,5,6]"
                        :key="dayIndex"
                        class="schedule-cols__col"
                    >
                        <div class="schedule-cols__week-day">
                            {{ dayNames[dayIndex] }}
                        </div>
                    </div>
                </div>
                <div
                    v-for="(data, weekIndex) in weeks"
                    :key="weekIndex"
                >
                    <div class="schedule-cols">
                        <div
                            v-for="(day, dayIndex) in data.week"
                            :key="dayIndex"
                            class="schedule-cols__col"
                        >
                            <div class="schedule-cols__header">
                                <div class="schedule-cols__date">
                                    {{ data.dates[dayIndex] ? data.dates[dayIndex].format('DD MMM') : null }}
                                </div>
                            </div>
                            <schedule-event
                                v-for="(scheduleInfo, scheduleIndex) in day"
                                :key="scheduleIndex"
                                :schedule-info="scheduleInfo"
                                :is-registration-mode="isRegistrationOnScheduleAvailable"
                                :is-service-title-visible="isServiceTitleVisible"
                                :taken-by-participant-schedules="takenByParticipantSchedules"
                                :taken-times-of-not-shown-schedules="takenTimesOfNotShownSchedules"
                                @remove="_onScheduleRemove"
                                @schedulePlanRemove="_onSchedulePlanRemove"
                                @edit="_onScheduleEdit"
                                @register="_onScheduleRegister"
                            />
                            <add-schedule-button
                                v-show="isModeService(data.dates[dayIndex])"
                                @click="_showAddForm(weekIndex, dayIndex)"
                            />
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col
                :sm="24"
                :md="4"
                :lg="6"
            >
                <schedule-participant-enrol-list
                    v-if="participantId"
                    @unregisterFromSchedule="_onEnrollListUnregister"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { EVENT } from '@/enums';
import { values, filter, get, mapValues, groupBy, sortBy, compose, concat, range, map, omitBy }
    from 'lodash/fp';
import { mapActions, mapMutations, mapState } from 'vuex';
import ScheduleEvent from '@/modules/event/components/card/service/schedule/ScheduleEvent';
import AddScheduleButton from '@/modules/event/components/card/service/schedule/AddScheduleButton';
import ScheduleForm from '@/modules/event/components/card/service/schedule/ScheduleForm';
import EventParticipantSelector from '@/modules/event/components/EventParticipantSelector';
import { Event } from '@/api';
import showError from '@/utils/showError';
import ScheduleEditForm from '@/modules/event/components/card/service/schedule/ScheduleEditForm';
import ScheduleParticipantEnrolList
    from '@/modules/event/components/card/service/schedule/ScheduleParticipantEnrolList';
import EventServiceSelector from '@/modules/event/components/card/service/common/EventServiceSelector';

export default {
    name: 'Schedule',

    components: {
        EventServiceSelector,
        ScheduleParticipantEnrolList,
        EventParticipantSelector,
        ScheduleForm,
        ScheduleEditForm,
        AddScheduleButton,
        ScheduleEvent
    },

    data() {
        return {
            /**
             * @type {ParticipantResource | null}
             */
            selectedParticipant: null
        };
    },

    computed: {
        ...mapState('event', {
            /** @type {EventResource | null} */
            event: 'resource'
        }),

        ...mapState('event/service/schedule', [
            /**
             * специалист, чье расписание отображаем
             * @type {SpecialistResource | null}
             */
            'selectedSpecialist',
            /** @type {ServiceResource | null} */
            'selectedService',
            /** @type {ScheduleInfoResource[]} */
            'scheduleInfos',
            /** @type {ScheduleParticipantInfoResource | null} */
            'participantInfo',
            'mode'
        ]),

        /**
         * @returns {ScheduleResource[]}
         */
        participantSchedules() {
            return this.$prop('participantInfo.relationships.schedules', []);
        },

        /**
         * @returns {Object<UUID, ScheduleResource>}
         */
        takenByParticipantSchedules() {
            if (!this.participantInfo) {
                return {};
            }
            /**
             * @param acc
             * @param {ScheduleResource} schedule
             */
            const reducer = (acc, schedule) => {
                acc[schedule.id] = schedule;
                return acc;
            };
            return this.participantSchedules.reduce(reducer, {});
        },

        /**
         * @return {ScheduleResource[]}
         */
        notShownSchedules() {
            const scheduleInfos = this.scheduleInfos || [];
            /**
             * @param {ScheduleResource} schedule
             */
            const isNotInShownSchedules = schedule => {
                /**
                 * @param {ScheduleInfoResource} x
                 * @return {boolean}
                 */
                const find = x => _.get(x, 'resources.schedule.id') === schedule.id;
                return !scheduleInfos.find(find);
            };
            return this.participantSchedules.filter(isNotInShownSchedules);
        },

        /**
         * @returns {{start: moment.Moment, end: moment.Moment, service_id: UUID}[]}
         */
        takenTimesOfNotShownSchedules() {
            if (!this.participantInfo) {
                return [];
            }
            /**
             * @param {ScheduleResource} schedule
             */
            const mapper = schedule => {
                return {
                    start: moment(_.get(schedule, 'attributes.timestamp_start')),
                    end: moment(_.get(schedule, 'attributes.timestamp_end')),
                    service_id: _.get(schedule, 'attributes.service_id')
                };
            };
            return this.notShownSchedules.map(mapper);
        },

        isParticipantSelectorAvailable() {
            return this.isModeParticipant;
        },

        isModeParticipant() {
            return this.mode === EVENT.SCHEDULE.MODE.PARTICIPANT;
        },

        isEditingAvailable() {
            return this.isModeService;
        },

        /**
         * Предлагать записать выбранного участника?
         */
        isRegistrationOnScheduleAvailable() {
            return this.mode === 'participant' && !!this.selectedParticipant;
        },

        /**
         * Если в данный момент показаны услуги одного типа,
         * убираем название услуги из каждого элемента расписания.
         */
        isServiceTitleVisible() {
            return !this.selectedServiceId;
        },

        eventId() {
            return this.$route.params.eventId;
        },

        /**
         * @returns {DateIso}
         */
        eventStartDate() {
            return this.$prop('event.attributes.start_at');
        },

        /**
         * @returns {DateIso}
         */
        eventEndDate() {
            return this.$prop('event.attributes.end_at');
        },

        participantId() {
            return this.$prop('participantInfo.id');
        },

        dayNames() {
            return ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресение'];
        },

        selectedServiceId() {
            return this.$prop('selectedService.id');
        },

        selectedSpecialistId() {
            return this.$prop('selectedSpecialist.attributes.id');
        },

        /**
         * Подготавливаем массив недель, входящих в событие,
         * для того, чтобы сетка расписания отображала все недели, пусть и пустые.
         * @returns {{
         *     week: [],
         *     weekNumber: Number,
         *     dates: []
         * }[]}
         */
        eventEmptyWeeks() {
            let start = moment(this.eventStartDate);
            let startWeek = start.week();
            let startDayOfWeek = start.weekday();
            let end = moment(this.eventEndDate);
            let endWeek = end.week();
            const week = range(0, 7)
                .map(() => []);
            return range(startWeek, endWeek + 1)
                .map(weekNumber => {
                    const dates = range(0, 7)
                        .map(i => {
                            const shift = ((weekNumber - startWeek) * 7) + i - startDayOfWeek;
                            return moment(this.eventStartDate)
                                .add(shift, 'd');
                        });
                    return {
                        weekNumber,
                        dates,
                        week
                    };
                });
        },

        /**
         * @return {{
         *     week: ScheduleInfoResource[][],
         *     weekNumber: Number,
         *     dates: String[]
         * }[]}
         */
        weeks() {
            const emptyWeek = map(i => ({ omitMe: true, attributes: { dayOfWeek: i } }))(range(0, 7));
            const filterByService = this.selectedServiceId
                ? x => get('relationships.service.id', x) === this.selectedServiceId
                : () => true;
            /** @param {ScheduleInfoResource[][]} week */
            const computeDates = week => {
                /**
                 * @type {ScheduleInfoResource}
                 */
                const s = week.reduce((a, x) => a || (x ? x[0] : null), null);
                if (!s || !s.attributes) {
                    return null;
                }
                const dow = get('attributes.dayOfWeek', s) || 0;
                const weekNumber = moment(s.attributes.date)
                    .week();
                const dates = map(i => s ? moment(s.attributes.date)
                    .add(i - dow, 'd') : null)(range(0, 7));
                return { week, weekNumber, dates };
            };
            let weeks = compose(
                values,
                map(computeDates),
                mapValues(compose(
                    values,
                    mapValues(compose(
                        sortBy('attributes.hours'),
                        omitBy('omitMe')
                    )),
                    groupBy('attributes.dayOfWeek'),
                    concat(emptyWeek),
                    filter(filterByService)
                )),
                groupBy('attributes.weekOfYear')
            )(this.scheduleInfos);
            /**
             * @param {{
             *     week: [],
             *     weekNumber: Number,
             *     dates: []
             * }} emptyWeek
             */
            const mapper = emptyWeek => {
                const week = weeks.find(w => w ? w.weekNumber === emptyWeek.weekNumber : false);
                return week || emptyWeek;
            };
            return this.eventEmptyWeeks.map(mapper);
        }
    },

    watch: {

        selectedParticipant() {
            this._getScheduleParticipantInfo();
        },

        selectedSpecialistId: {
            handler: function(val, oldVal) {
                this._loadData();
                if (oldVal) {
                    this.$echo.leave('Specialist.' + oldVal);
                }
                if (val) {
                    this.$echo.private('Specialist.' + val)
                        .listen('ScheduleEditedEcho', () => {
                            this._loadData();
                        })
                        .listen('RegistrationToSpecialistEcho', () => {
                            this._loadData();
                        });
                }
            },
            immediate: true
        }
    },

    mounted() {
        this._loadData();
    },

    destroyed() {
        this._cleanUp();
        this.$echo.leave(this.selectedSpecialistId);
    },

    methods: {
        ...mapActions('event/service/schedule', [
            'getScheduleInfos',
            'getScheduleParticipantInfo',
            'removeSchedule',
            'removeSchedulePlan',
            'addParticipantToSchedule'
        ]),

        ...mapMutations('event/service/schedule', [
            'setParticipantInfo',
            'setScheduleInfoToEdit',
            'setSelectedSpecialist',
            'setSelectedService',
            'setScheduleInfos'
        ]),

        isModeService(day) {
            const eventFirstDay = moment(this.eventStartDate);
            const eventLastDay = moment(this.eventEndDate);
            const correctDay = day.isSameOrAfter(eventFirstDay) && day.isSameOrBefore(eventLastDay);
            return this.mode === EVENT.SCHEDULE.MODE.SERVICE && correctDay;
        },

        _cleanUp() {
            this.setScheduleInfos([]);
            this.setSelectedSpecialist({ selectedSpecialist: null });
            this.setParticipantInfo({ participantInfo: null });
        },

        /**
         * @param {ServiceSelectorItem} item
         */
        _onServiceSelect({ item }) {
            this.setSelectedService({
                selectedService: _.get(item, 'item.relationships.service', null)
            });
            this.setSelectedSpecialist({
                selectedSpecialist: _.get(item, 'item.relationships.specialist', null)
            });
        },

        /**
         * @param {ScheduleInfoResource} scheduleInfo
         */
        _onScheduleEdit({ scheduleInfo }) {
            this._showEditForm(scheduleInfo);
        },

        /**
         * @param {ScheduleInfoResource} scheduleInfo
         * @param {SchedulePlanResource} plan
         */
        _onScheduleRegister({ scheduleInfo, plan }) {
            this._addSelectedParticipantToSchedule(scheduleInfo, plan);
        },

        _onEnrollListUnregister(scheduleId) {
            this._removeSelectedParticipantFromSchedule(scheduleId);
        },

        _showAddForm(weekIndex = -1, dayIndex = -1) {
            if (!this.isEditingAvailable) {
                return;
            }
            let date = null;
            let day = null;
            if (weekIndex !== -1 && dayIndex !== -1) {
                const week = this.weeks[weekIndex];
                day = week.week[dayIndex];
                date = week.dates[dayIndex];
            }
            this.$refs.form.open(date, day);
        },

        /**
         * @param {ScheduleInfoResource} scheduleInfoToEdit
         */
        _showEditForm(scheduleInfoToEdit) {
            if (!this.isEditingAvailable) {
                return;
            }
            this.setScheduleInfoToEdit({ scheduleInfoToEdit });
            this.$refs.formEdit.open();
        },

        /**
         * Нужно обновить актуальное состояние расписания.
         * В зависимости от выполняемого дествия делаем разные запросы:
         * - всегда грузим расписания
         * - если производим запись участника, обновляем инфу по участнику
         */
        _loadData() {
            const scheduleInfo = this._getScheduleInfos();
            if (!scheduleInfo) {
                return;
            }
            this._getScheduleInfos().then(() => {
                if (this.isRegistrationOnScheduleAvailable) {
                    this._getScheduleParticipantInfo();
                }
            });
        },

        /**
         * @param {UUID} scheduleId
         */
        _removeSelectedParticipantFromSchedule(scheduleId) {
            if (!this.selectedParticipant) {
                this.$message.error('Не выбран ни один участник');
                return;
            }
            /** @param {ScheduleParticipantRemoveResponse} response */
            const onResponse = response => {
                if (response && response.success) {
                    this.$message.success('Запись удалена');
                } else {
                    this.$message.error(_.get(response, 'errorMessage', 'Не удалось удалить запись участника'));
                }
            };
            Event.Service.Schedule.removeParticipantFromSchedule({
                eventId: this.eventId,
                scheduleId,
                participantId: this.selectedParticipant.id
            })
                .then(onResponse)
                .catch(showError('Не удалось удалить участника'));
        },

        /**
         * @param {ScheduleInfoResource} scheduleInfo
         * @param {SchedulePlanResource} plan
         */
        _addSelectedParticipantToSchedule(scheduleInfo, plan) {
            if (!this.selectedParticipant) {
                this.$message.error('Не выбран ни один участник');
                return;
            }
            if (!plan) {
                this.$message.error('Не указан тип участия');
                return;
            }

            /** @type {ScheduleParticipantStoreRequest} */
            const request = {
                participant_id: this.selectedParticipant.id,
                schedule_plan_id: plan.id
            };
            this.addParticipantToSchedule({
                eventId: this.eventId,
                scheduleId: scheduleInfo.relationships.schedule.id,
                request
            })
                .then(response => {
                    if (response && response.success) {
                        this.$message.success('Участник записан');
                    }
                })
                .catch(showError('Не удалось добавить участника'));
        },

        _onEditError() {
            this._loadData();
        },

        _onFailed() {
            this._loadData();
        },

        /**
         * @param {ScheduleInfoResource} scheduleInfo
         */
        _onScheduleRemove({ scheduleInfo }) {
            this._doRemove(scheduleInfo);
        },

        /**
         * @param {ScheduleInfoResource} scheduleInfo
         */
        _doRemove(scheduleInfo) {
            this.removeSchedule({
                eventId: this.eventId,
                scheduleId: scheduleInfo.relationships.schedule.id
            })
                .then(this._onRemoved())
                .catch(showError('Не удалось удалить расписание'));
        },

        /**
         * @param {SchedulePlanResource} plan
         */
        _onSchedulePlanRemove({ plan }) {
            this.$confirm('Вы уверены, что хотите удалить время?')
                .then(() => {
                    this._doPlanRemove(plan);
                })
                .catch(() => {
                });
        },

        /**
         * @param {SchedulePlanResource} plan
         */
        _doPlanRemove(plan) {
            if (!plan || !plan.id || !plan.attributes) {
                return;
            }
            this.removeSchedulePlan({
                eventId: this.eventId,
                scheduleId: plan.attributes.schedule_id,
                schedulePlanId: plan.id
            })
                .then(this._onRemoved())
                .catch(showError('Не удалось удалить время'));
        },

        _getScheduleInfos: _.debounce(function() {
            if (!this.selectedSpecialistId || !this.eventId) {
                return;
            }
            return this.getScheduleInfos({
                eventId: this.eventId,
                specialistId: this.selectedSpecialistId
            });
        }, 30),

        _getScheduleParticipantInfo: _.debounce(function() {
            if (!this.selectedParticipant || !this.eventId) {
                this.setParticipantInfo({ participantInfo: null });
                return;
            }
            return this.getScheduleParticipantInfo({
                eventId: this.eventId,
                participantId: this.selectedParticipant.id
            });
        }, 30)
    }
};
</script>

<style lang="scss">
@import "@vars";

.schedule {
    max-width: 1200px;
    margin: auto;

    &__participant-selector {
        width: 900px;
    }
}

.schedule-table {
    flex-wrap: wrap;
    justify-content: space-around;
}

.schedule-cols {
    font-size: 12px;
    @include flex-row-centered;
    width: 100%;
    margin: 16px -10px;

    &--days {
        border-bottom: 1px solid #dcdfe6;

        .schedule-cols__col {
            padding: 0 10px;
        }
    }

    &__col {
        width: 100%;
        padding: 10px;
        align-self: flex-start;
    }

    &__header {
        min-height: 24px;
    }

    &__date {
        font-size: 14px;
        color: #000;
    }

    &__week-day {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #000;
    }
}

</style>
