<template>
    <el-dialog
        :visible.sync="isVisible"
        width="315px"
        :close-on-click-modal="false"
        @close="onFormClose"
    >
        <el-form
            ref="form"
            :model="form"
        >
            <el-form-item
                label="Куда приезжает"
                prop="arrivalTransferPlaceId"
            >
                <el-select
                    v-model="form.arrivalTransferPlaceId"
                >
                    <el-option
                        v-for="place in transferPlaces"
                        :key="place.id"
                        :label="place.name"
                        :value="place.id"
                    />
                </el-select>
            </el-form-item>
            <el-row class="transfer-registration-form__time-row">
                <el-col :span="11">
                    <el-form-item
                        label="Во сколько"
                        prop="arrivalTime"
                    >
                        <flat-pickr
                            v-model="form.arrivalTime"
                            placeholder="        :        "
                            class="el-input__inner app-lk-participant-modal__date-input"
                            :config="timePickerConfig"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <p style="font-size: 13px; color: darkgray; margin-top: -15px;">
                Укажите время из билета
            </p>
            <el-form-item
                label="Откуда уезжает"
                prop="departureTransferPlaceId"
            >
                <el-select
                    v-model="form.departureTransferPlaceId"
                >
                    <el-option
                        v-for="place in transferPlaces"
                        :key="place.id"
                        :value="place.id"
                        :label="place.name"
                    />
                </el-select>
            </el-form-item>
            <el-row class="transfer-registration-form__time-row">
                <el-col :span="11">
                    <el-form-item
                        label="Во сколько"
                        prop="departureTime"
                    >
                        <flat-pickr
                            v-model="form.departureTime"
                            placeholder="        :        "
                            class="el-input__inner app-lk-participant-modal__date-input"
                            :config="timePickerConfig"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <p style="font-size: 13px; color: darkgray; margin-top: -15px;">
                Укажите время из билета
            </p>
            <div style="display: flex; justify-content: center;">
                <el-button
                    type="primary"
                    @click="submitForm"
                >
                    Сохранить
                </el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { STATUSES } from '@/modules/event/components/card/transfer/config';
import flatPickr from 'vue-flatpickr-component';
import _ from 'lodash';

export default {
    name: 'BookTransferForm',
    components: {
        flatPickr
    },
    props: {
        formData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            isVisible: false,
            transferParticipantId: null,
            form: {
                arrivalTime: null,
                arrivalTransferPlaceId: null,
                departureTime: null,
                departureTransferPlaceId: null
            },
            timePickerConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i'
            }
        };
    },
    computed: {
        ...mapState('transfer', [
            'places'
        ]),

        transferPlaces() {
            return this.places.map(item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
        }
    },
    mounted() {
        this._getTransferPlaces();
    },
    methods: {
        ...mapActions('transfer', [
            'getPlaces'
        ]),
        _getTransferPlaces() {
            return this.getPlaces();
        },
        open(transferId) {
            this.transferParticipantId = transferId;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
        },
        onFormClose() {
            this._resetFields();
        },
        _resetFields() {
            this.$refs.form.resetFields();
            this.form = {};
            this.transferParticipantId = null;
        },
        submitForm() {
            this.$emit('submit', {
                id: this.transferParticipantId,
                data: {
                    ...this.form, status: STATUSES.BOOKED_STATUS
                }
            });
            this.close();
        }
    }
};
</script>

<style lang="scss">
.app-transfer-modal {
  width: 100%;
  max-width: 305px;
}
</style>
