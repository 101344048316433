<template>
    <div class="event-participant-selector">
        <el-row
            :gutter="16"
            style="margin: 0"
        >
            <el-col :span="14">
                <el-autocomplete
                    v-model="selectedParticipantFullname"
                    style="width: 100%;"
                    :fetch-suggestions="_fetcher"
                    placeholder="Начните вводить имя участника"
                    :trigger-on-focus="false"
                    clearable
                    @select="_onParticipantSelect"
                    @clear="_onClear"
                >
                    <template slot-scope="{ item }">
                        <el-row
                            :gutter="24"
                            style="margin: 0;"
                        >
                            <el-col :span="14">
                                <participant-name-with-age
                                    :with-link="false"
                                    :name="item.value"
                                    :age="item.age"
                                    class="event-participant-selector__name"
                                />
                            </el-col>
                            <el-col
                                :span="4"
                                align="right"
                                class="event-participant-selector__city"
                            >
                                {{ item.city }}
                            </el-col>
                            <el-col :span="1">
                                <gender-icon :gender="item.gender" />
                            </el-col>
                            <el-col
                                :span="4"
                                align="left"
                                class="event-participant-selector__status"
                            >
                                {{ item.status }}
                            </el-col>
                        </el-row>
                    </template>
                </el-autocomplete>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { Event } from '@/api';
import ParticipantNameWithAge from '@/modules/participant/components/common/ParticipantNameWithAge';
import GenderIcon from '@/components/common/GenderIcon';
import { PARTICIPANTS } from '@/enums/index';

export default {
    name: 'EventParticipantSelector',

    components: {
        GenderIcon,
        ParticipantNameWithAge
    },

    data() {
        return {
            selectedParticipantFullname: null,
            /**
             * @type {ParticipantResource | null}
             */
            value: null
        };
    },

    computed: {
        ...mapState('event', {
            event: 'resource'
        }),
        eventId() {
            return this.$prop('event.id');
        }
    },

    watch: {
        /**
         * todo: проверить
         * @param {ParticipantResource | null} value
         */
        value(value) {
            if (value && this.selectedParticipantFullname !== value.attributes.fullname) {
                this.selectedParticipantFullname = value.attributes.fullname;
            }
        },

        selectedParticipantFullname(value) {
            if (!value) {
                this._onClear();
            }
        }

    },

    methods: {
        _fetcher: _.debounce(function(search, cb) {
            /**
             * @param {{data: ParticipantResource[]}} resource
             */
            const onResponse = resource => {
                this._onClear();
                /**
                 * @param {ParticipantResource} participant
                 * @return {Object}
                 */
                const mapper = participant => {
                    return {
                        id: participant.id,
                        value: participant.attributes.fullname,
                        city: participant.attributes.city,
                        status: participant.attributes.status,
                        gender: participant.attributes.gender,
                        is_child: participant.attributes.is_child,
                        age: participant.attributes.age,
                        participant
                    };
                };
                cb(resource.data.map(mapper));
            };
            Event.Participant.list({
                eventId: this.eventId,
                fio: search,
                _with: []
            })
                .then(onResponse)
                .catch(() => {
                    this._onClear();
                    cb(null);
                });
        }, 500),

        _onParticipantSelect(item) {
            this.$emit('input', item ? item.participant : null);
        },

        _onClear() {
            this.$emit('input', null);
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-participant-selector {
    &__status, &__city, &__name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

</style>
