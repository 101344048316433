<template>
    <div
        class="schedule-participant-enrol-list-item"
        :class="isItemSelectedClass"
    >
        <div
            class="schedule-participant-enrol-list-item-left"
            @click="_onItemClick"
        >
            <div class="schedule-participant-enrol-list-item__date">
                {{ item.dateStart }}
            </div>
            <div class="schedule-participant-enrol-list-item__title">
                {{ item.serviceTitle }}
            </div>
            <template v-for="specialist in item.specialists">
                <div
                    :key="specialist.id"
                    class="schedule-participant-enrol-list-item__specialist"
                >
                    {{ specialist.attributes.name }}
                </div>
            </template>
            <div class="schedule-participant-enrol-list-item__title">
                {{ item.plan.title }} {{ planPrice }}
            </div>
        </div>
        <div class="schedule-participant-enrol-list-item-right">
            <div
                class="schedule-participant-enrol-list-item__leave"
                @click="_onRemoveClick"
            >
                <i class="fas fa-times" />
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';

export default {
    name: 'ScheduleParticipantEnrolListItem',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    scheduleId: null,
                    dateStart: null,
                    serviceTitle: null,
                    specialists: []
                };
            }
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        withoutPrice: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isItemSelectedClass() {
            return {
                'is-item-selected': this.isSelected
            };
        },

        planPrice() {
            return parseInt(this.item.plan.price) > 0 ? this.item.plan.price + ' ₽' : 'Бесплатно';
        }
    },
    methods: {
        _onItemClick() {
            this.$emit('itemClick', this.item);
        },

        _onRemoveClick: _.debounce(function() {
            this.$emit('removeFromSchedule', this.item);
        }, 150)
    }
};
</script>

<style lang="scss">
.schedule-participant-enrol-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.is-item-selected {
        background-color: #c0eabc;
    }

    &-left {
        padding-left: 25px;
        padding-top: 14px;
        padding-bottom: 24px;
        flex: 1;
    }

    &:hover {
        background-color: #f5f7fa;

        .schedule-participant-enrol-list-item__leave {
            visibility: visible;
        }
    }

    &__date {
        font-weight: bold;
    }

    &__specialist {
        color: #979797;
    }

    &__leave {
        color: #eb2929;
        cursor: pointer;
        visibility: hidden;
        margin-right: 19px;
        transition: .05s;
    }

    &__leave:hover {
        transform: scale(1.5);
    }

}
</style>
