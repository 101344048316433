<template>
    <div>
        <el-popover
            v-model="isVisible"
        >
            <el-form
                ref="filterForm"
                :model="filterForm"
            >
                <el-form-item>
                    <el-select
                        v-model="filterForm.arrivalTransferPlaceId"
                        clearable
                        placeholder="Где забрать?"
                    >
                        <el-option
                            v-for="place in transferPlaces"
                            :key="place.id"
                            :value="place.id"
                            :label="place.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="filterForm.arrivalTime"
                        placeholder="Во сколько?"
                        multiple
                        filterable
                    >
                        <el-option
                            v-for="(time, key) in bookedArrivalTime"
                            :key="key"
                            :value="time"
                            :label="time"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="filterForm.departureTransferPlaceId"
                        clearable
                        placeholder="Откуда уедет"
                    >
                        <el-option
                            v-for="place in transferPlaces"
                            :key="place.id"
                            :value="place.id"
                            :label="place.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="filterForm.departureTime"
                        placeholder="Во сколько?"
                        multiple
                    >
                        <el-option
                            v-for="(time, key) in bookedDepartureTime"
                            :key="key"
                            :value="time"
                            :label="time"
                        />
                    </el-select>
                </el-form-item>
                <el-button
                    size="small"
                    type="primary"
                    @click=" _applyFilter"
                >
                    Применить
                </el-button>
            </el-form>
            <el-button
                slot="reference"
                class="event-transfer-info__top-button"
                size="default"
                type="text"
            >
                <i class="fas fa-filter" />
            </el-button>
        </el-popover>
        <el-link
            v-if="isFiltersApplied"
            type="danger"
            @click="_resetFilter"
        >
            Отменить
        </el-link>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import _ from 'lodash';

const defaultFilterForm = {
    arrivalTransferPlaceId: null,
    departureTransferPlaceId: null,
    departureTime: null,
    arrivalTime: null
};

export default {
    name: 'TransferListFilter',
    data() {
        return {
            isVisible: false,
            filterForm: {}
        };
    },
    computed: {
        ...mapState('transfer', [
            'places',
            'arrivalTime',
            'departureTime',
            'bookedFilter'
        ]),
        arrivalPlaceId() {
            return this.filterForm.arrivalTransferPlaceId;
        },
        departurePlaceId() {
            return this.filterForm.departureTransferPlaceId;
        },
        eventId() {
            return this.$route.params.eventId;
        },
        bookedArrivalTime() {
            return _.get(this.arrivalTime, 'arrival_time', []);
        },
        bookedDepartureTime() {
            return _.get(this.departureTime, 'departure_time', []);
        },
        transferPlaces() {
            return this.places.map(item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
        },
        isFiltersApplied() {
            return Object.values(this.bookedFilter).some(item => !_.isEmpty(item));
        }
    },
    watch: {
        arrivalPlaceId(id) {
            this.filterForm.arrivalTime = null;
            let params = {};
            if (id) {
                params['arrival_transfer_place_id'] = id;
            }
            this.getArrivalTime({ eventId: this.eventId, params });
        },
        departurePlaceId(id) {
            this.filterForm.departureTime = null;
            let params = {};
            if (id) {
                params['departure_transfer_place_id'] = id;
            }
            this.getDepartureTime({ eventId: this.eventId, params });
        },
        bookedFilter(filter) {
            let routerQuery = { ...this.$route.query };
            Object.keys(filter).forEach((key) => {
                let filterProp = _.snakeCase(key);
                if (_.isEmpty(filter[key])) {
                    delete routerQuery[filterProp];
                    return;
                }
                routerQuery[filterProp] = filter[key];
            });
            this.$router.push({ query: { ...routerQuery } }, () => {
            });
        }
    },
    mounted() {
        this._loadData();
    },
    methods: {
        ...mapActions('transfer', [
            'getPlaces',
            'getArrivalTime',
            'getDepartureTime'
        ]),
        ...mapMutations('transfer', [
            'setBookedFilter'
        ]),
        _loadData() {
            let requestData = {
                eventId: this.eventId
            };
            Promise.all([
                this.getPlaces(),
                this.getArrivalTime(requestData),
                this.getDepartureTime(requestData)
            ]);
        },
        _getTransferPlaces() {
            return this.getPlaces();
        },
        _applyFilter() {
            this.setBookedFilter({ ...this.filterForm });
            this.isVisible = false;
        },
        _resetFilter() {
            this.filterForm = { ...defaultFilterForm };
            this.setBookedFilter({ ...defaultFilterForm });
            this.isVisible = false;
        }
    }
};
</script>

<style scoped>

</style>
