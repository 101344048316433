<template>
    <div v-if="isReady">
        <h2 class="app-h2">
            Корпус {{ building.attributes.title }}
        </h2>
        <div
            v-for="(roomGroups, floor) in preparedData"
            :key="floor"
        >
            <div v-if="floor && roomGroups && roomGroups.length">
                <h3 class="app-h3">
                    Этаж {{ floor }}
                </h3>
                <el-row
                    v-for="(roomItems, index) in roomGroups"
                    :key="index"
                    :gutter="16"
                    style="margin: 6px 0;"
                >
                    <el-col
                        v-for="room in roomItems"
                        :key="room.id"
                        :span="6"
                    >
                        <room-card-small
                            :room="room"
                            :participants-to-check-in="selectedParticipants"
                            @checkIn="_onCheckIn"
                        />
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import RoomCardSmall from '@/modules/event/components/card/hotel/RoomCardSmall';
import { PARTICIPANTS } from '@/enums';

export default {
    name: 'EventHotelBuildingCard',

    components: {
        RoomCardSmall
    },

    computed: {
        ...mapState('event/hotel', [
            'selectedBuildingId',
            'buildingCard',
            'isLoading'
        ]),

        ...mapState('event/participants', [
            'selectedParticipants',
            'reCheckInMode'
        ]),

        ...mapState('event', {
            event: 'resource'
        }),

        preparedData() {
            /**
             * @param acc
             * @param {RoomResource} room
             */
            const reducer = (acc, room) => {
                const floor = room.attributes.floor;
                if (!acc[floor]) {
                    acc[floor] = [];
                }
                acc[floor].push(room);
                return acc;
            };
            const roomGroupsReducer = (acc, rooms, floor) => {
                if (typeof acc[floor] === 'undefined') {
                    acc[floor] = [];
                }
                acc[floor] = _.chunk(rooms, 4);
                return acc;
            };
            return this.rooms
                .reduce(reducer, [])
                .reduce(roomGroupsReducer, []);
        },

        hotelId() {
            return this.$prop('event.attributes.hotel_id');
        },

        eventId() {
            return this.$prop('event.id');
        },

        /**
         * @returns {HotelResource | null}
         */
        hotel() {
            return this.$prop('event.relationships.hotel');
        },

        /**
         * @returns {BuildingResource[]}
         */
        building() {
            return this.buildingCard;
        },

        /**
         * @returns {RoomResource[]}
         */
        rooms() {
            return this.$prop('buildingCard.relationships.rooms', []);
        },

        isReady() {
            return !!this.building;
        },

        hasDescription() {
            return !!this.$prop('building.attributes.description');
        }
    },

    methods: {
        ...mapMutations('event/hotel', [
            'setSelectedBuildingId',
            'setBuildingCard'
        ]),

        ...mapMutations('event/participants', [
            'setSelectedParticipants'
        ]),

        ...mapActions('event', [
            'checkIn',
            'reCheckIn'
        ]),

        ...mapActions('event/hotel', [
            'getBuildingData'
        ]),

        /**
         *
         * @param {PlaceResource} place
         * @param {ParticipantResource[]} participants
         * @private
         */
        async _onCheckIn({ place, participants }) {
            const placeId = place.id;
            const participantId = participants[0].id;
            let result = {};
            if (this.reCheckInMode) {
                let notifyReception = false;
                if (participants[0].attributes.status === PARTICIPANTS.STATUSES.ARRIVED) {
                    await this.$confirm(
                        '',
                        'Уведомить ресепшен о переселении?',
                        {
                            confirmButtonText: 'Да',
                            cancelButtonText: 'Не нужно'
                        })
                        .then(() => {
                            notifyReception = true;
                        })
                        .catch(() => {});
                }

                result = await this.reCheckIn({ placeId, participantId, notifyReception });
            } else {
                result = await this.checkIn({ placeId, participantId });
            }

            if (result.success) {
                this.$message.success(result.message);
                participants.shift();
                this.setSelectedParticipants({
                    selectedParticipants: participants
                });
            } else {
                this.$message.error(result.message);
            }

            await this.getBuildingData({
                eventId: this.eventId,
                hotelId: this.hotelId,
                buildingId: this.selectedBuildingId
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-hotel-building-card {
    margin: 32px 0;
}
</style>
