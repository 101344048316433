<template>
    <div
        v-if="isReady"
        class="event-hotel-place-selector"
    >
        <el-row :gutter="16">
            <el-col :span="24">
                <el-alert
                    v-if="isLoadingMessageVisible"
                    type="info"
                    title="Данные загружаются"
                    :closable="false"
                    show-icon
                />
            </el-col>
        </el-row>
        <el-tabs
            v-model="activeTab"
            class="event-hotel-place-selector__tabs"
            @tab-click="_onTabClick"
        >
            <el-tab-pane
                label="Изображением"
                name="map"
            >
                <span slot="label"><i
                    class="far fa-images"
                    style="margin-right:5px;"
                />Изображением</span>
                <event-hotel-map
                    :buildings="preparedBuildings"
                />
            </el-tab-pane>
            <el-tab-pane
                label="Карточками"
                name="cards"
                style="max-width: 1000px;"
            >
                <span slot="label"> <i
                    class="fas fa-th"
                    style="margin-right: 5px;"
                /> Карточками</span>
                <el-row
                    v-for="(buildingsGroup, index) in preparedBuildings"
                    :key="index"
                    :gutter="12"
                >
                    <el-col
                        v-for="building in buildingsGroup"
                        :key="building.id"
                        :span="elColSpan"
                        style="margin: 6px 0;"
                    >
                        <building-card-small
                            :building="building"
                            :row-index="index"
                            @select="_onSelect"
                        />
                    </el-col>
                    <div v-if="showHotelInfoMountPoint(index)">
                        <el-col>
                            <building-card-wrapper
                                :ref="makeHotelInfoRef(index)"
                                @component-close="_hotelInfoUnmount"
                                @component-ready="_hotelInfoReady"
                            />
                        </el-col>
                    </div>
                </el-row>
            </el-tab-pane>
        </el-tabs>

        <event-selected-participants-indicator v-if="isIndicatorVisible" />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';
import { Event } from '@/api';
import BuildingCardSmall from '@/modules/event/components/card/hotel/BuildingCardSmall';
import EventSelectedParticipantsIndicator from '@/modules/event/components/card/EventSelectedParticipantsIndicator';
import BuildingCardWrapper from '@/modules/event/components/card/hotel/BuildingCardWrapper';
import EventHotelMap from '@/modules/event/components/card/hotel/EventHotelMap';

export default {
    name: 'EventHotelPlaceSelector',

    components: {
        EventHotelMap,
        BuildingCardWrapper,
        EventSelectedParticipantsIndicator,
        BuildingCardSmall
    },

    data() {
        return {
            areBuildingsLoading: false,
            /** @type {BuildingResource[]} */
            buildings: [],
            /** @type {PlaceResource[]} */
            places: [],
            selectedBuildingDialogVisible: false,
            hotelInfoMountPoints: [],
            currentMountPoint: null,
            activeTab: 'cards'
        };
    },

    computed: {
        ...mapState('event/hotel', [
            'selectedBuildingId',
            'isLoading'
        ]),

        ...mapState('event/participants', [
            'selectedParticipants'
        ]),

        ...mapState('event', {
            // todo: rename
            event: 'resource'
        }),

        isLoadingMessageVisible() {
            return this.isLoading || this.areBuildingsLoading;
        },

        isReady() {
            return this.eventId && this.hotelId;
        },

        eventId() {
            return this.$prop('event.id');
        },

        hotelId() {
            return this.$prop('event.attributes.hotel_id');
        },

        hasSelectedParticipants() {
            return !!this.selectedParticipants && !!this.selectedParticipants.length;
        },

        isIndicatorVisible() {
            return this.hasSelectedParticipants;
        },

        rowSize() {
            return 4;
        },

        elColSpan() {
            return 24 / this.rowSize;
        },

        preparedBuildings() {
            return _.chunk(this.buildings, this.rowSize);
        }
    },

    watch: {
        isReady: {
            handler(isReady, isReadyOld) {
                isReady && !isReadyOld && this._onReady();
            },
            immediate: true
        }
    },

    mounted() {
        this._getCurrentTabPosition();
    },

    destroyed() {
        this._clearSelectedBuilding();
    },

    methods: {
        ...mapActions('event/hotel', [
            'getBuildingData',
            'getEventPlacesWithParticipants'
        ]),

        ...mapMutations('event/hotel', [
            'setSelectedBuildingId'
        ]),

        showHotelInfoMountPoint(position) {
            return position % this.rowSize >= 0;
        },

        makeHotelInfoRef(position) {
            return `info-${position}`;
        },

        _onTabClick(tab) {
            this._rememberTabPosition(tab.name);
        },

        _rememberTabPosition(activeTab) {
            localStorage.setItem('event-hotel-map-tab-open', activeTab);
        },

        _getCurrentTabPosition() {
            this.activeTab = localStorage.getItem('event-hotel-map-tab-open') || this.activeTab;
        },

        _hotelInfoUnmount() {
            this.$refs[this.currentMountPoint][0]._hideComponent();
            this.setSelectedBuildingId({
                selectedBuildingId: null
            });
        },

        /**
         * Метод поиска корневого ряда отелей, для скролла до этого ряда, при загрузке карточки
         * */
        _hotelInfoReady() {
            let currentSelectRow = this.$refs[this.currentMountPoint][0].$el.parentNode.parentNode.parentNode;
            currentSelectRow.scrollIntoView({ behavior: 'smooth' });
        },

        _onBuildingDialogCancel() {
            this.selectedBuildingDialogVisible = false;
        },

        _onBuildingDialogClose() {
            this._clearSelectedBuilding();
        },

        _clearSelectedBuilding() {
            this.setSelectedBuildingId({
                selectedBuildingId: null
            });
        },

        _onReady() {
            this._loadEventHotelBuildings();
            this._loadPlacesWithParticipants();
        },

        /**
         * На основе этих данных считаются, например,
         * участники типа "Один в номере".
         * @private
         */
        _loadPlacesWithParticipants() {
            this.getEventPlacesWithParticipants({
                eventId: this.eventId,
                hotelId: this.hotelId
            });
        },

        _loadEventHotelBuildings() {
            this.areBuildingsLoading = true;
            /** @param {BuildingResource[]} buildings */
            const onResponse = buildings => {
                this.buildings = buildings;
            };
            Event.Hotel.getBuildings({
                eventId: this.eventId,
                hotelId: this.hotelId
            })
                .then(onResponse)
                .catch(e => {
                    this.$message.error('Не удалось получить информацию о корпусах');
                })
                .finally(() => {
                    this.areBuildingsLoading = false;
                });
        },

        /**
         * @param {BuildingResource} building
         * @private
         */
        async _onSelect({ building, rowIndex }) {
            const infoRef = `info-${rowIndex}`;
            let selectedBuildingId = _.get(building, 'id', null);
            if (this.selectedBuildingId === selectedBuildingId) {
                /** При повторном клике на выбранный корпус */
                this.setSelectedBuildingId({ building: null });
                selectedBuildingId = null;
                return this.$refs[infoRef][0]._hideComponent();
            }
            await this.getBuildingData({
                eventId: this.eventId,
                hotelId: this.hotelId,
                buildingId: selectedBuildingId
            });

            this.setSelectedBuildingId({ selectedBuildingId });

            if (this.$refs[infoRef][0]._componentStatus()) {
                this.$refs[infoRef][0]._hideComponent();
            }

            if (this.currentMountPoint && this.currentMountPoint === infoRef) {
                this.$refs[this.currentMountPoint][0]._hideComponent();
            }

            if (this.currentMountPoint && this.currentMountPoint !== infoRef) {
                this.$refs[this.currentMountPoint][0]._hideComponent();
            }

            this.currentMountPoint = infoRef;

            return this.$refs[infoRef][0]._showComponent();
        }
    }
};
</script>

<style lang="scss">
    @import "@vars";
    .event-hotel-place-selector {
        //max-width: 1000px;
        &__tabs {
            .el-tabs__nav {
                //float: right;
            }
            .el-tabs__nav-wrap{
                &::after {
                    content: none;
                }
            }
            .el-tabs__active-bar {
                background-color: #FFFFFF;
            }
        }
    }

    </style>
