<template>
    <div
        v-loading="isLoading"
        class="event-hotel-info"
    >
        <h1 class="app-h1">
            {{ title }}
        </h1>
        <event-hotel-place-selector />
        <el-button @click="openHotelCard">
            Карточка отеля
            <i class="el-icon-arrow-right" />
        </el-button>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ROUTES } from '@/enums';
import EventHotelPlaceSelector from '@/modules/event/components/card/hotel/EventHotelPlaceSelector';

export default {
    name: 'EventHotelInfo',

    components: {
        EventHotelPlaceSelector
    },
    data() {
        return {
            activeTab: 'cards'
        };
    },

    computed: {
        ...mapState('event/hotel', [
            'hotel',
            'isLoading'
        ]),

        ...mapState('event', {
            event: 'resource'
        }),

        eventId() {
            return this.$prop('event.id');
        },

        hotelId() {
            return this.$prop('event.attributes.hotel_id');
        },

        title() {
            return this.$prop('hotel.attributes.title');
        },

        isReady() {
            return this.hotelId && this.eventId;
        }
    },

    watch: {
        isReady: {
            handler(isReady, old) {
                if (isReady && !old) {
                    this._loadHotel();
                }
            },
            immediate: true
        }
    },

    destroyed() {
        /** Нужно выключить режим переселения */
        this.setRecheckInMode({ mode: false });
    },
    methods: {
        ...mapActions('event/hotel', [
            'getHotel'
        ]),
        ...mapMutations('event/participants', [
            'setRecheckInMode'
        ]),
        openHotelCard() {
            this.$router.push({
                name: ROUTES.HOTEL.CARD.SHOW,
                params: {
                    hotelId: this.hotelId
                }
            });
        },
        _loadHotel() {
            this.getHotel({
                eventId: this.eventId,
                hotelId: this.hotelId
            });
        }
    }
};
</script>
