<template>
    <el-dialog
        title="Редактирование расписания"
        :visible.sync="isVisible"
        width="60%"
        @close="_onDialogClose"
        @open="_onDialogOpen"
    >
        <div class="schedule-form">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-position="top"
                @submit.native.prevent
            >
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item
                            label="Дата"
                            prop="date"
                        >
                            <el-date-picker
                                v-model="form.date"
                                :picker-options="dateOptions"
                                value-format="yyyy-MM-dd"
                                :format="datepickerDateFormat"
                                :default-value="eventStartsAtDate"
                                style="width: 100%;"
                                placeholder="Выберите дату"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item
                            label="Время"
                            prop="time"
                        >
                            <schedule-time-select v-model="form.time" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item
                            label="Прод-ть"
                            prop="duration"
                        >
                            <el-input
                                v-model="form.duration"
                                type="number"
                            >
                                <div
                                    slot="suffix"
                                    style="line-height: 38px;"
                                >
                                    мин
                                </div>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-alert
                    v-if="warningMessage"
                    style="margin-top: 40px;"
                    :title="warningMessage"
                    type="warning"
                />

                <schedule-plans-edit-form
                    :plans="plans"
                    :schedule="scheduleToEdit"
                    @added="_onSchedulePlanAdded"
                />

                <table class="table">
                    <tr>
                        <td style="width: 200px;">
                            Специалист:
                        </td>
                        <td>{{ specialistName }}</td>
                    </tr>
                    <tr>
                        <td>Услуга:</td>
                        <td>{{ serviceTitle }}</td>
                    </tr>
                    <tr>
                        <td>Место проведения:</td>
                        <td>{{ locationTitle }}</td>
                    </tr>
                </table>

                <el-button
                    type="primary"
                    @click="_send"
                >
                    Сохранить
                </el-button>
                <el-button @click="_cancel">
                    Отмена
                </el-button>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';
import { Event } from '@/api';
import ScheduleTimeSelect from '@/modules/event/components/card/service/schedule/ScheduleTimeSelect';
import SchedulePlansEditForm from '@/modules/event/components/card/service/schedule/SchedulePlansEditForm';
import showError from '@/utils/showError';

const defaultTime = '09:00';

export default {
    name: 'ScheduleEditForm',

    components: {
        SchedulePlansEditForm,
        ScheduleTimeSelect
    },

    data() {
        return {
            isVisible: false,
            /** @type {EventServiceResource | null} */
            eventService: null,
            form: {},
            warningMessage: null,
            errorMessage: null
        };
    },

    computed: {
        ...mapState('event', {
            event: 'resource'
        }),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('event/service', [
            /** @type {EventSpecialistResource[]} */
            'specialists',
            'locations'
        ]),

        ...mapState('event/service/schedule', [
            /** @type {SpecialistResource} */
            'selectedSpecialist',
            /** @type {ServiceResource} */
            'selectedService',
            /** @type {ScheduleInfoResource | null} */
            'scheduleInfoToEdit'
        ]),

        /**
         * @returns {ScheduleResource}
         */
        scheduleToEdit() {
            return this.$prop('scheduleInfoToEdit.relationships.schedule', null);
        },

        /**
         * @returns {SchedulePlanResource[]}
         */
        plans() {
            return this.$prop('scheduleToEdit.relationships.plans', []);
        },

        /**
         * @returns {ServiceResource | null}
         */
        scheduleService() {
            return this.$prop('scheduleInfoToEdit.relationships.service', null);
        },

        serviceTitle() {
            return this.$prop('scheduleService.attributes.title', null);
        },

        /**
         * @returns {SpecialistResource | null}
         */
        scheduleMainSpecialist() {
            return this.$prop('scheduleInfoToEdit.relationships.mainSpecialist.relationships.specialist', null);
        },

        specialistName() {
            return this.$prop('scheduleMainSpecialist.attributes.name');
        },

        locationTitle() {
            return this.$prop('scheduleInfoToEdit.relationships.location.attributes.title');
        },

        scheduleToEditId() {
            return this.$prop('scheduleToEdit.id', null);
        },

        takenPlaces() {
            return this.$prop('scheduleToEdit.meta.taken_places', 0);
        },

        rules() {
            return {
                date: [
                    {
                        required: true,
                        message: 'Укажите дату расписания'
                    }
                ],
                duration: [
                    {
                        required: true,
                        validator: (r, v, cb) => v < 1 ? cb(new Error('Недопустимая продолжительность')) : cb()
                    }
                ]
            };
        },

        /**
         * @return {DateObject}
         */
        eventStartsAt() {
            return this.$prop('event.attributes.start_at');
        },

        eventStartsAtDate() {
            return this.eventStartsAt
                ? this.eventStartsAt.date
                : new Date();
        },

        /**
         * @return {DateObject}
         */
        eventEndsAt() {
            return this.$prop('event.attributes.end_at');
        },

        dateOptions() {
            return {
                disabledDate: function(date) {
                    const startsAt = moment(this.eventStartsAt.date);
                    const endsAt = moment(this.eventEndsAt.date);
                    return !moment(date)
                        .isBetween(startsAt, endsAt);
                }.bind(this)
            };
        },

        specialistId() {
            return this.$prop('selectedSpecialist.id');
        },

        serviceId() {
            return this.$prop('selectedService.id');
        },

        eventId() {
            return this.$prop('event.id');
        },

        specialistsPrepared() {
            return this.specialists.map(spec => {
                return {
                    id: _.get(spec, 'relationships.specialist.id'),
                    name: _.get(spec, 'relationships.specialist.attributes.name')
                };
            });
        }
    },

    watch: {
        scheduleInfoToEdit: {
            deep: true,
            handler: function() {
                this._fillFormFromScheduleToEdit();
            }
        },
        form: {
            deep: true,
            handler: function() {
                this._check();
            }
        }
    },

    methods: {
        ...mapMutations('event/service/schedule', [
            'addSchedulePlanToScheduleInfoToEdit'
        ]),

        open() {
            this.isVisible = true;
        },

        close() {
            this.$emit('close');
            this.isVisible = false;
        },

        _fillFormFromScheduleToEdit() {
            let time = this.$prop('scheduleToEdit.attributes.time', '');
            this.form = {
                id: this.$prop('scheduleToEdit.id'),
                date: this.$prop('scheduleToEdit.attributes.date'),
                time: time ? time.slice(0, 5) : defaultTime,
                duration: this.$prop('scheduleToEdit.attributes.duration')
            };
        },

        /**
         * Успешно добавлен новый тип участия
         * @param {SchedulePlanResource} schedulePlan
         */
        _onSchedulePlanAdded({ schedulePlan }) {
            this.addSchedulePlanToScheduleInfoToEdit({ schedulePlan });
        },

        /**
         * @return {UpdateScheduleRequest}
         * @private
         */
        _buildRequest() {
            return {
                id: this.form.id,
                date: this.form.date,
                time: (this.form.time + '').slice(0, 5),
                duration: this.form.duration
            };
        },

        _check: _.debounce(function() {
            /** @type {UpdateScheduleRequest} */
            const request = this._buildRequest();
            if (!request.date ||
                !request.time ||
                typeof request.duration === 'undefined') {
                return;
            }
            /**
             * @param {{
             *  date: String,
             *  time: String,
             *  duration: Number,
             *  success: Boolean,
             *  status: {
             *      error: String,
             *      errorMessage: String
             *  },
             * }} response
             */
            const onResponse = response => {
                if (response && response.success) {
                    this.warningMessage = null;
                    this.$emit('checked', response);
                } else {
                    const message = _.get(response, 'status.errorMessage', 'Проверьте расписание');
                    this.$message.warning(message);
                    this.warningMessage = message;
                }
            };
            Event.Service.Schedule.checkScheduleUpdateRequest({
                eventId: this.eventId,
                scheduleId: request.id,
                request
            })
                .then(onResponse)
                .catch(() => {
                    this.warningMessage = null;
                    this.errorMessage = null;
                });
        }, 300),

        _cancel() {
            this.close();
        },

        _send() {
            if (this.sendInProgress) {
                return;
            }
            this.$refs.form.validate()
                .then(this._doSend.bind(this))
                .catch(() => {
                    this.$message.warning('Проверьте форму');
                });
        },

        _doSend() {
            if (this.sendInProgress) {
                return;
            }
            this.sendInProgress = true;
            this.warningMessage = null;
            this.errorMessage = null;
            /**
             * @param {{success: Boolean}} response
             */
            const onResponse = response => {
                if (response && response.success) {
                    const message = `Обновлено успешно`;
                    this.$message.success(message);
                    this.$emit('created');
                    this.close();
                } else {
                    const message = 'Не удалось создать расписание';
                    this.$message.error(message);
                    this.$emit('failed');
                }
            };
            Event.Service.Schedule.updateSchedule({
                eventId: this.eventId,
                scheduleId: this.scheduleToEditId,
                request: this._buildRequest()
            })
                .then(onResponse)
                .catch(showError('Не удалось создать расписание'))
                .finally(() => {
                    this.sendInProgress = false;
                });
        },

        _onDialogOpen() {
            this._fillFormFromScheduleToEdit();
        },

        _onDialogClose() {
            this._resetForm();
        },

        _resetForm() {
            this.form = {};
            this.warningMessage = null;
            this.errorMessage = null;
            this.$refs.form.clearValidate();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.schedule-form {
}

</style>
