<template>
    <el-card
        class="stats-box-card"
        shadow="hover"
    >
        <div class="stats-box-card__title">
            Ссылка на регистрацию
        </div>
        <a
            :href="registrationLinkHref"
            target="_blank"
        >{{ registrationLinkHref }}</a>
    </el-card>
</template>

<script>
import { ROUTES } from '@/enums';

export default {
    name: 'RegistrationLink',

    props: {
        eventId: {
            type: String,
            default: null
        }
    },

    computed: {
        registrationLink() {
            return {
                name: ROUTES.CABINET.EVENT.REGISTER,
                params: {
                    eventId: this.eventId
                }
            };
        },

        registrationLinkHref() {
            return location.origin + this.$router.resolve(this.registrationLink).href;
        }
    }
};
</script>
