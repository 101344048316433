<template>
    <component :is="component" />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { ROUTES } from '@/enums';
import EventParticipantsList from './EventParticipantsList';
import EventHotelInfo from './hotel/EventHotelInfo';
import EventTransferInfo from './EventTransferInfo';
import EventStats from './EventStats';
import EventServices from './EventServices';
import EventCardTabs from '@/modules/event/components/common/EventCardTabs';

export default {
    name: 'EventCardFull',

    components: {
        EventCardTabs,
        EventParticipantsList,
        EventTransferInfo,
        EventHotelInfo,
        EventStats,
        EventServices
    },

    props: {
        /**
         * ID события, которое собираемся показывать.
         * Компонент загрузит информацию о нем в store.
         */
        eventIdToLoad: {
            type: String,
            default: null
        }
    },

    computed: {
        ...mapState('event', [
            'activeTabRoute'
        ]),

        component() {
            let tab;
            switch (this.activeTabRoute) {
            case ROUTES.EVENT.CARD.SERVICES:
                tab = 'EventServices';
                break;
            case ROUTES.EVENT.CARD.HOTEL:
                tab = 'EventHotelInfo';
                break;
            case ROUTES.EVENT.CARD.TRANSFER:
                tab = 'EventTransferInfo';
                break;
            case ROUTES.EVENT.CARD.STATS:
                tab = 'EventStats';
                break;
            case ROUTES.EVENT.CARD.PARTICIPANTS:
                tab = 'EventParticipantsList';
                break;
            }
            return tab;
        }

    },

    watch: {
        eventIdToLoad: {
            handler() {
                this._onEventIdToLoadChange();
            },
            immediate: true
        }
    },

    destroyed() {
        this._reset();
    },

    methods: {
        ...mapActions('event', [
            'getResource'
        ]),

        ...mapMutations('event/participants', [
            'setSelectedParticipants'
        ]),

        ...mapMutations('event', [
            'setResource'
        ]),

        _onEventIdToLoadChange() {
            this._loadEvent();
        },

        _loadEvent() {
            this.getResource({
                eventId: this.eventIdToLoad
            });
        },

        _reset() {
            this.setSelectedParticipants({
                selectedParticipants: []
            });
            this.setResource({
                event: null
            });
        }
    }
};
</script>
