<template>
    <el-form
        ref="planForm"
        :model="planForm"
        :rules="rules"
        label-position="top"
    >
        <el-row :gutter="16">
            <el-col :span="11">
                <el-form-item prop="title">
                    <el-input
                        v-model="planForm.title"
                        :disabled="sendInProgress"
                        placeholder="Название типа участия"
                        @change="_onPlanFormChanged"
                    />
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item prop="places">
                    <el-input
                        v-model="planForm.places"
                        :disabled="sendInProgress"
                        type="number"
                        @change="_onPlanFormChanged"
                    >
                        <div
                            slot="suffix"
                            style="line-height: 38px;"
                        >
                            мест
                        </div>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item prop="price">
                    <el-input
                        v-model="planForm.price"
                        :disabled="sendInProgress"
                        type="number"
                        @change="_onPlanFormChanged"
                    >
                        <div
                            slot="suffix"
                            style="line-height: 38px;"
                        >
                            руб.
                        </div>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="3">
                <el-tooltip content="Удалить тип участия">
                    <el-button
                        style="width: 100%;"
                        type="warning"
                        :disabled="removeInProgress"
                        plain
                        icon="el-icon-delete"
                        @click="_remove"
                    />
                </el-tooltip>
            </el-col>
            <el-col :span="24">
                <el-form-item prop="description">
                    <el-input
                        v-model="planForm.description"
                        :disabled="sendInProgress"
                        type="textarea"
                        :rows="2"
                        placeholder="Описание"
                        @change="_onPlanFormChanged"
                    />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                &nbsp;
            <!--                Записано участников: 10-->
            </el-col>
            <el-col
                :span="12"
                align="right"
            >
                <div style="margin-top: -10px;">
                    <span v-if="isStatusVisible">{{ status }}</span>
                    <span v-if="sendInProgress">Отправка...</span>
                </div>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import showError from '@/utils/showError';
import { Event } from '@/api';

export default {
    name: 'SchedulePlanEditForm',

    props: {
        /** @type {SchedulePlanResource | null} */
        schedulePlan: { type: Object, default: null },
        /** @type {ScheduleResource | null} */
        schedule: { type: Object, default: null }
    },

    data() {
        return {
            removeInProgress: false,
            sendInProgress: false,
            planForm: {},
            status: 'Сохранено'
        };
    },

    computed: {
        ...mapState('event', {
            event: 'resource'
        }),

        ...mapState('event/service/schedule', [
            /** @type {ScheduleInfoResource | null} */
            'scheduleInfoToEdit'
        ]),

        schedulePlanId() {
            return this.$prop('schedulePlan.id', null);
        },

        isStatusVisible() {
            return !this.sendInProgress && this.status;
        },

        scheduleId() {
            return this.$prop('schedule.id', null);
        },

        rules() {
            return {};
        },

        eventId() {
            return this.$prop('event.id');
        }
    },

    watch: {
        schedulePlan: {
            deep: true,
            handler: function() {
                this._fillFormFromPlan();
            }
        }
    },

    mounted() {
        this._fillFormFromPlan();
    },

    methods: {
        ...mapMutations('event/service/schedule', [
            'removeSchedulePlanFromScheduleInfoToEdit'
        ]),

        _remove() {
            this.$confirm('Удалить тип участия?')
                .then(this._doRemove.bind(this))
                .catch(() => {
                });
        },

        _doRemove() {
            if (this.removeInProgress) {
                return;
            }
            this.removeInProgress = true;
            /**
             * @param {{
             *     success: Boolean,
             *     error: String | null,
             *     errorMessage: String | null
             * }} response
             */
            const onResponse = response => {
                if (!response || !response.success) {
                    this.$message.error(response.errorMessage);
                    return;
                }
                this.$emit('removed');
                this.removeSchedulePlanFromScheduleInfoToEdit({
                    schedulePlan: this.schedulePlan
                });
                this.$message.success('Тип участия удален');
            };
            Event.Service.Plan.removeSchedulePlan({
                eventId: this.eventId,
                scheduleId: this.scheduleId,
                schedulePlanId: this.schedulePlanId
            })
                .then(onResponse)
                .catch(showError('message', 'Не удалось обновить тип участия'))
                .finally(() => {
                    this.removeInProgress = false;
                });
        },

        _onPlanFormChanged: _.debounce(function() {
            this._send();
        }, 300),

        _fillFormFromPlan() {
            this.planForm = {
                ...{
                    title: this.$prop('schedulePlan.attributes.title'),
                    description: this.$prop('schedulePlan.attributes.description'),
                    places: this.$prop('schedulePlan.attributes.places'),
                    price: this.$prop('schedulePlan.attributes.price')
                }
            };
        },

        /**
         * @return {Object}
         * @private
         */
        _buildRequest() {
            return {
                schedule_plan_id: this.schedulePlanId,
                title: this.planForm.title,
                description: this.planForm.description,
                places: parseInt(this.planForm.places),
                price: parseFloat(this.planForm.price)
            };
        },

        _send() {
            if (this.sendInProgress) {
                return;
            }
            this.$refs.planForm.validate()
                .then(this._doSend.bind(this))
                .catch((e) => {
                    this.$message.warning('Проверьте форму');
                });
        },

        _doSend() {
            if (this.sendInProgress) {
                return;
            }
            this.sendInProgress = true;
            /**
             * @param {{success: Boolean}} response
             */
            const onResponse = response => {
                if (response && response.success) {
                    const message = `Обновлено успешно`;
                    this.$message.success(message);
                    this.$emit('updated');
                } else {
                    const message = 'Не удалось обновить тип участия';
                    this.$message.error(message);
                    this.$emit('update_failed');
                }
            };
            Event.Service.Plan.updateSchedulePlan({
                eventId: this.eventId,
                scheduleId: this.scheduleId,
                schedulePlanId: this.schedulePlanId,
                request: this._buildRequest()
            })
                .then(onResponse)
                .catch(showError('message', 'Не удалось обновить тип участия'))
                .finally(() => {
                    setTimeout(() => {
                        this.sendInProgress = false;
                    }, 300);
                });
        }
    }
};
</script>
