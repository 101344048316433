<template>
    <el-popover
        :disabled="disablePopover"
        class="schedule-event-box__popover-block"
        placement="bottom"
        trigger="hover"
    >
        <!--eslint-disable-->
        <div
            v-loading="registrationFetching"
            v-html="registrationFetchingInfo.errorMessage"
        />
        <!--eslint-enable-->
        <div
            slot="reference"
            :class="classes"
            @click="_onClick"
            @mouseenter="_onMouseEnter"
        >
            <div class="schedule-event-box__places">
                <span class="schedule-event-box__places-taken">{{ takenPlaces }}</span>&nbsp;/&nbsp;{{
                    availablePlaces
                }}
            </div>
            <div class="schedule-event-box__time">
                {{ time }}
            </div>
            <div
                v-if="isRemoveAvailable"
                class="schedule-event-box__remove fas fa-times"
                @click.stop="_remove"
            />
            <div
                v-if="isServiceTitleVisible"
                class="schedule-event-box__title"
            >
                {{ serviceTitle }}
            </div>
            <div
                v-if="isTitleVisible"
                class="schedule-event-box__title"
            >
                {{ title }}
            </div>
        </div>
    </el-popover>
</template>

<script>
import money from '@/filters/money';
import { mapState } from 'vuex';
import { EVENT } from '@/enums';

export default {
    name: 'ScheduleEventPlan',

    props: {
        /** @type {ScheduleInfoResource} */
        scheduleInfo: {
            type: Object,
            default: null
        },

        /** @type {SchedulePlanResource} */
        plan: {
            type: Object,
            default: null
        },

        isRegistrationMode: {
            type: Boolean,
            default: false
        },

        isTitleVisible: {
            type: Boolean,
            default: true
        },

        isServiceTitleVisible: {
            type: Boolean,
            default: true
        },

        serviceTitle: {
            type: String,
            default: null
        },

        isTimeAlreadyTaken: {
            type: Boolean,
            default: false
        },

        takenByParticipantSchedules: {
            type: Object,
            default: () => ({})
        },

        active: Boolean
    },

    computed: {
        ...mapState('event/service/schedule', [
            'mode',
            'registrationFetching',
            'registrationFetchingInfo'
        ]),

        classes() {
            return {
                'schedule-event-box': true,
                'schedule-event-box--active': this.active,
                'schedule-event-box--taken': this.isRegistrationMode && this.isTakenBySelectedParticipant,
                'schedule-event-box--free': this.isRegistrationMode && this.hasFreePlaces,
                'schedule-event-box--busy': this.isRegistrationMode && !this.hasFreePlaces,
                'schedule-event-box--is-time-already-taken': this.isRegistrationMode && this.isTimeAlreadyTaken
            };
        },

        disablePopover() {
            return !(this.isRegistrationMode && this.isTimeAlreadyTaken);
        },

        hasFreePlaces() {
            return this.availablePlaces > this.takenPlaces;
        },

        time() {
            let time = this.$prop('scheduleInfo.attributes.time');
            return time ? time.slice(0, 5) : time;
        },

        title() {
            return this.$prop('plan.attributes.title');
        },

        description() {
            return this.$prop('plan.attributes.description');
        },

        availablePlaces() {
            return this.$prop('plan.attributes.places');
        },

        takenPlaces() {
            return this.$prop('plan.meta.participantsCount');
        },

        price() {
            const price = parseFloat(this.$prop('plan.attributes.price', 0));
            return price ? money(price) : 'Бесплатно';
        },

        isTakenBySelectedParticipant() {
            return this.takenByParticipantSchedules && !!this.takenByParticipantSchedules[this.scheduleId];
        },

        isModeService() {
            return this.mode === EVENT.SCHEDULE.MODE.SERVICE;
        },

        isEditingAvailable() {
            return this.isModeService;
        },

        /**
         * Нельзя удалять расписание, на которое есть записи.
         * @returns {Boolean}
         */
        isRemoveAvailable() {
            return this.isEditingAvailable && this.takenPlaces === 0;
        }
    },

    methods: {
        _onClick() {
            if (this.isTimeAlreadyTaken) {
                return this.$message.error('Данный участник уже записан на это время.');
            }
            this.$emit('click', {
                plan: this.plan
            });
        },

        _onMouseEnter() {
            if (this.isRegistrationMode && this.isTimeAlreadyTaken) {
                this.$emit('taken-participant-hover', {
                    plan: this.plan
                });
            }
        },

        _remove: function() {
            this.$emit('remove', {
                plan: this.plan
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.schedule-event-box {
  font-size: 12px;
  line-height: 18px;
  padding: 7px 10px;
  background: #c0eabc;
  color: #239f18;
  position: relative;

  &__popover-block {
    display: block;
  }

  &__remove {
    color: #eb2929;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    transition: transform .05s;

    &:hover {
      color: #bd2121;
    }
  }

  &--removing {
    opacity: .5;
    cursor: default;
  }

  &__places {
    line-height: 18px;
    font-size: 9px;
    float: right;
    color: #239f18;
  }

  &__title {
    font-size: 9px;
    line-height: 14px;
  }

  &--free {
    cursor: pointer;
    background: #239f18;
    color: #fff;

    &:hover {
      background: #29b11a;
    }

    .schedule-event-box__title,
    .schedule-event-box__places {
      color: #c0eabc;

    }

    .schedule-event-box__time,
    .schedule-event-box__places-taken {
      color: #fff;
    }
  }

  &--taken {
    background: #eb2929;

    .schedule-event-box__title,
    .schedule-event-box__places {
      color: #c0eabc;

    }

    .schedule-event-box__time,
    .schedule-event-box__places-taken {
      color: #fff;
    }
  }

  &--busy {
    background: #d8d8d8;
  }

  &--is-time-already-taken {
    background: #7a64ff;

    .schedule-event-box__title,
    .schedule-event-box__places {
      color: #c0eabc;

    }

    .schedule-event-box__time,
    .schedule-event-box__places-taken {
      color: #fff;
    }
  }

  &--active {
    background: #eb2929;

    .schedule-event-box__title,
    .schedule-event-box__places {
      color: #c0eabc;

    }

    .schedule-event-box__time,
    .schedule-event-box__places-taken {
      color: #fff;
    }
  }
}
</style>
