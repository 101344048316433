<template>
    <el-dropdown @command="_handleDropdownCommand">
        <el-button
            class="event-transfer-info__top-button"
            size="default"
            type="text"
            title="Выгрузки в xlsx"
        >
            <i class="fas fa-download" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="arrival">
                Сводная прибытие
            </el-dropdown-item>
            <el-dropdown-item command="departure">
                Сводная отбытие
            </el-dropdown-item>
            <el-dropdown-item command="full">
                Полная выгрузка
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import CONFIG_ROUTES from '@/config';
import api from '@/api/managerApi';

export default {
    name: 'TransferListDownload',
    computed: {
        eventId() {
            return this.$route.params.eventId;
        }
    },

    methods: {
        _handleDropdownCommand(command) {
            if (command === 'full') {
                this._download();
                return;
            }

            this._handleTransferCount(command);
        },

        _handleTransferCount(type) {
            const url = `${CONFIG_ROUTES.API_HOST}/api/event/${this.eventId}/transfer/count/${type}/link`;
            api.get(url).then(response => {
                const xlsUrl = response.data;
                window.open(xlsUrl, '_blank');
            });
        },

        _onSaveBtnClick() {
            this._download();
        },

        _download() {
            const url = `${CONFIG_ROUTES.API_HOST}/api/event/${this.eventId}/transfer/download-link`;
            api.get(url).then(response => {
                const xlsUrl = response.data;
                window.open(xlsUrl, '_blank');
            });
        }
    }
};
</script>

<style scoped>

</style>
