<template>
    <div
        v-loading="isLoading"
        class="event-participant-list"
    >
        <app-table
            app-selectable
            paginatable
            :rows="participants"
            :config="config"
            :pagination="pagination"
            :table-row-style="tableRowStyle"
            :clear-selection="areParticipantsNotSelected"
            :selected-rows="selectedParticipants"
            :searchable="false"
            class="event-participant-list__table"
            @paginatorChange="_onPaginatorChange"
            @rowSelected="_onRowSelected"
            @rowRemoved="_onRowRemoved"
            @headerClick="_onHeaderClick"
        >
            <div slot="filter">
                <el-col :span="6">
                    <el-input
                        v-model="filter.fio"
                        prefix-icon="el-icon-search"
                        placeholder="ФИО"
                    />
                </el-col>
                <el-col :span="6">
                    <el-input
                        v-model="filter.search"
                        placeholder="Телефон, город"
                    />
                </el-col>
                <el-col :span="5">
                    <el-select
                        v-model="filter.status"
                        placeholder="Фильтр по статусу"
                    >
                        <el-option
                            v-for="status in statusList"
                            :key="status.id"
                            :value="status.id"
                            :label="status.attributes.title"
                        />
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Сбросить поиск"
                    >
                        <el-button
                            plain
                            type="danger"
                            class="event-participant-list__clear-filter"
                            icon="el-icon-close"
                            @click="_onResetFiltersClick"
                        />
                    </el-tooltip>
                </el-col>
            </div>
            <div slot="actionButtons">
                <el-button
                    type="primary"
                    plain
                    @click="_onAddParticipantClick"
                >
                    <i class="fas fa-user-plus" /> Добавить персонал
                </el-button>
            </div>
        </app-table>

        <participants-actions-popup :participants="selectedParticipants" />
        <event-add-participant-dialog
            :is-visible="isParticipantDialogVisible"
            @onSuccess="_onAddParticipantSuccess"
            @onClose="_onAddParticipantDialogClosed"
        />
    </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';
import { ROUTES } from '@/enums';
import { getParticipantActualPhone } from '@/modules/participant/utils';
import AppTable from '@/components/table/AppTable';
import ParticipantsActionsPopup from '@/modules/event/components/card/ParticipantsActionsPopup';
import EventAddParticipantDialog from '@/modules/event/components/card/EventAddParticipantDialog';
import { Participant } from '@/api';

/**
 * @param {ParticipantResource} participant
 * @returns {string}
 */
const nameTemplate = participant => {
    const name = _.get(participant, 'attributes.fullname');
    const age = _.get(participant, 'attributes.age', '? лет');
    const places = _.get(participant, 'relationships.places', []);
    let checkInButton = `<li><el-link type="primary" size="mini"  @click="onCheckInClick">Заселить</el-link></li>`;
    let checkOutButton = ``;
    if (places && places.length) {
        checkInButton = `<li><el-link type="primary" @click="onReCheckInClick" size="mini" >Переселить</el-link></li>`;
        checkOutButton = `<li><el-link type="primary" @click="onCheckOutClick" size="mini" >Выселить</el-link></li>`;
    }

    const statusDropDown = `
      <el-dropdown
            trigger="click"
            @command="_onSubmenuStatusChanged"
            class="event-participant-list__submenu-dropdown"
      >
            <el-link >
            Сменить статус <i class="el-icon-arrow-down el-icon--right"></i>
            </el-link>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="status in participantStatuses()"
                    :key="status.id"
                    :command="status.id"
                >
                    {{status.title}}
                </el-dropdown-item>
            </el-dropdown-menu>
      </el-dropdown>`;

    const moveToEventDropDown = `
      <el-dropdown
            trigger="click"
            @command="_onMoveToEventSubmenuChanged"
            class="event-participant-list__submenu-dropdown"
      >
            <el-link >
            Перенести в <i class="el-icon-arrow-down el-icon--right"></i>
            </el-link>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="event in eventsToMoveList()"
                    :key="event.id"
                    :command="event.id"
                >
                    {{event.title}}
                </el-dropdown-item>
            </el-dropdown-menu>
      </el-dropdown>`;

    const registerSubmenu = () => {
        return `
        <el-popover class="event-participant-list__submenu-trigger" trigger="click">
          <ul class="event-participant-list__submenu">
            ${checkInButton}
            ${checkOutButton}
            <li>${statusDropDown}</li>
            <li>${moveToEventDropDown}</li>
            <li><el-link disabled>Записать на трансфер</el-link></li>
            <li><el-link @click="onSubmenuServiceClick">Записать на услугу</el-link></li>
            <li><el-link disabled>Отправить смс</el-link></li>
            <li><el-link disabled>Отправить ссылку на кабинет</el-link></li>
            <li><el-link disabled>В черный список</el-link></li>
          </ul>
          <div slot="reference" class="event-participant-list__popover-menu">
            <i class="fas fa-ellipsis-h"></i>
          </div>
        </el-popover>
    `;
    };

    let childIcon = _.get(participant, 'attributes.has_small_children_in_group', false)
        ? `
          <span class="event-participant-list__child" @click.prevent>
            <el-tooltip content="В группе есть маленькие дети">
             <i class="app-table__fas fas fa-baby"></i>
            </el-tooltip>
          </span>
          `
        : '';
    return `<div class="event-participant-list__participant">
              <span>
                <router-link :to="participantCardRoute()" class="event-participant-list__name event-participant-list__name--no-link">
                    <span class="event-participant-list__name-link">
                    ${name}
                    </span>
                    <span class="event-participant-list__age" @click.prevent>${age}</span>
                    ${childIcon}
                </router-link>
                ${registerSubmenu()}
              </span>
        </div>`;
};

const popoverTemplate = (neighbours, room, building) => {
    return typeof room !== 'undefined' && typeof building !== 'undefined'
        ? `<el-popover trigger="hover">` +
        neighbours.map(neighbour => {
            const name = _.get(neighbour, 'attributes.appellation');
            const routerLink = `{name: '${ROUTES.EVENT.PARTICIPANT.CARD}',params: {eventId: '${_.get(neighbour, 'attributes.event_id')}',participantId: '${neighbour.id}'}}`;
            return `<router-link :to="${routerLink}" class="event-participant-list__name event-participant-list__nameline">
                      ${name}
                      </router-link><br>`;
        })
            .join('') +
        `<div slot="reference" class="popover-trigger"><i class="far fa-building"></i>&thinsp;${building}, №${room}</div>
        </el-popover>
    ` : null;
};

/**
 * @param {ParticipantResource} participant
 * @returns {string}
 */
const checkInInfoTemplate = participant => {
    /** @type {PlaceResource[]} */
    const places = _.get(participant, 'relationships.places', []);
    if (places && places.length) {
        return `<div>` + places
            .map(place => {
                const room = _.get(place, 'relationships.room.attributes.title');
                const building = _.get(place, 'relationships.building.attributes.title');
                const neighbours = _.get(place, 'relationships.room.relationships.places', [])
                    .map(place => place.relationships.participants[0])
                    .filter(Boolean);
                return popoverTemplate(neighbours, room, building);
            }) + `</div>`;
    }
    return `<el-button type="primary" size="mini" @click="onCheckInClick">Заселить</el-button>`;
};

/**
 * @param {ParticipantResource} participant
 * @returns {string}
 */
const phoneTemplate = participant => {
    let { phone, isGroupLeaderPhone } = getParticipantActualPhone(participant);
    return isGroupLeaderPhone
        ? `<el-tooltip content="Телефон лидера группы">
                                    <div class="text-secondary">${phone}</div>
                                   </el-tooltip>`
        : `<div>${phone}</div>`;
};

const hasPhonedTemplate = participant => {
    let hasPhoned = _.get(participant, 'attributes.has_phoned');
    let template = `
    <div @click="onHasPhonedClick">
        <i class="far fa-check-square check-check-square" v-if=${hasPhoned}  />
        <i class="far fa-square check-square" v-else />
   </div>
`;
    return template;
};

const registerTemplate = participant => {
    const date = moment(_.get(participant, 'attributes.created_at'));
    return date && moment.isMoment(date)
        ? `<div>${date.format('D MMMM')}</div>`
        : null;
};

const HAS_PHONED_COL_PROP = 'HAS_PHONED';

export default {
    name: 'EventParticipantsList',

    components: {
        EventAddParticipantDialog,
        ParticipantsActionsPopup,
        AppTable
    },

    data() {
        return {
            /** @type {EventParticipantsListFilter} */
            filter: {
                status: null,
                fio: null,
                search: null
            },
            isParticipantDialogVisible: false
        };
    },

    computed: {
        ...mapState('event/participants', [
            'participants',
            'participantsMeta',
            'participantPage',
            'selectedParticipants',
            'isLoading'
        ]),

        ...mapState('event', {
            event: 'resource',
            eventsToMove: 'eventsToMove'
        }),

        ...mapState('participant', [
            'statusList'
        ]),

        areParticipantsNotSelected() {
            return Array.isArray(this.selectedParticipants) && this.selectedParticipants.length <= 0;
        },

        eventId() {
            return this.$route.params.eventId;
        },

        pagination() {
            return {
                totalRecords: this.$prop('participantsMeta.total'),
                perPage: this.$prop('participantsMeta.per_page'),
                lastPage: this.$prop('participantsMeta.last_page'),
                currentPage: this.participantPage
            };
        },

        config() {
            return {
                methods: row => ({
                    participantCardRoute: this._participantCardRoute.bind(this, row),
                    onCheckInClick: this._onCheckInClick.bind(this, row),
                    onReCheckInClick: this._onReCheckInClick.bind(this, row),
                    onCheckOutClick: this._onCheckOutClick.bind(this, row),
                    eventsToMoveList: this.eventsToMoveList.bind(this, row),
                    participantStatuses: this.participantStatuses.bind(this, row),
                    _onSubmenuStatusChanged: this._onSubmenuStatusChanged.bind(this, status, row),
                    _onMoveToEventSubmenuChanged: this._onMoveToEventSubmenuChanged.bind(this, row),
                    onSubmenuServiceClick: this._onSubmenuServiceClick.bind(this, row),
                    onHasPhonedClick: this._onHasPhonedClick.bind(this, row)
                }),
                columns: [
                    {
                        title: 'ФИО',
                        template: nameTemplate,
                        props: {
                            width: '270px'
                        }
                    },
                    {
                        title: 'Город',
                        render: row => row.attributes.city,
                        className: 'city',
                        props: {
                            width: '110px'
                        }
                    },
                    {
                        title: 'Телефон',
                        template: phoneTemplate,
                        props: {
                            width: '150px'
                        }
                    },
                    {
                        title: `✆`,
                        template: hasPhonedTemplate,
                        props: {
                            width: '50px',
                            prop: HAS_PHONED_COL_PROP,
                            'label-class-name': 'column__has-phoned'
                        }
                    },
                    {
                        title: 'Дата рожд.',
                        render: row => {
                            const date = moment(_.get(row, 'attributes.date_of_birth'));
                            return date && moment(date)
                                .isValid()
                                ? date.calendar()
                                : 'Не указана';
                        },
                        props: {
                            width: '110px'
                        }
                    },
                    {
                        title: 'Заселен в',
                        template: checkInInfoTemplate,
                        props: {
                            width: '110px'
                        }
                    },
                    {
                        title: 'Доплатить',
                        render: row => {
                            return Number(parseInt(_.get(row, 'relationships.transactions.debt', 10)))
                                .toLocaleString('ru-RU') + ' ₽';
                        },
                        props: {
                            width: '90px'
                        }
                    },
                    {
                        title: 'Статус',
                        render: row => {
                            return _.get(row, 'attributes.status');
                        },
                        props: {
                            width: '85px'
                        }
                    },
                    {
                        title: 'Регистр.',
                        template: registerTemplate,
                        props: {
                            width: '95px'
                        }
                    }
                ]
            };
        }
    },

    watch: {
        '$route'(to) {
            this.setParticipantPage(to.query.page);
        },

        eventId: {
            handler() {
                this._loadParticipants(this.participantPage);
            },
            immediate: true
        },

        participantPage: {
            handler() {
                this._loadParticipants(this.participantPage);
            },
            immediate: true
        },

        filter: {
            /**
             * @param {EventParticipantsListFilter} filter
             */
            handler(filter) {
                this._updateRouteFilter(filter);
                this._applyFilters(filter);
            },
            deep: true
        }
    },

    mounted() {
        let routerQueryPage = this.$route.query.page;
        if (!routerQueryPage) {
            this.$router.push({ query: { page: 1 } });
        }
        this.setParticipantPage(parseInt(routerQueryPage, 10));
        this._initFilter();
        this.getStatusList();
        this.getEventsToMove({ eventId: this.$route.params.eventId });
    },

    destroyed() {
        this._reset();
    },

    methods: {
        ...mapActions('event/participants', [
            'getParticipants',
            'getParticipantGroup',
            'updateStatus',
            'participantCheckout',
            'moveParticipantsToEvent',
            'removeHasPhonedOfEvent'
        ]),

        ...mapActions('participant', [
            'getStatusList'
        ]),

        ...mapActions('event', [
            'getEventsToMove'
        ]),

        ...mapMutations('event/participants', [
            'setSelectedParticipants',
            'setParticipantPage',
            'setParticipants',
            'addSelectedParticipants',
            'removeParticipant',
            'setRecheckInMode'
        ]),

        participantStatuses() {
            if (Array.isArray(this.statusList) && this.statusList.length > 0) {
                return this.statusList
                    .map(value => {
                        return {
                            id: value.id,
                            title: value.attributes.title
                        };
                    });
            }
        },

        eventsToMoveList() {
            return this.eventsToMove;
        },

        /**
         * Инициализирует состояние фильтра.
         * Учитывается УРЛ.
         *
         * @private
         */
        _initFilter() {
            const search = _.trim(this.$route.query.search);
            const fio = _.trim(this.$route.query.fio);
            const status = _.trim(this.$route.query.status);
            const filter = {};
            if (search) {
                filter.search = search;
            }
            if (fio) {
                filter.fio = fio;
            }
            if (status) {
                filter.status = status;
            }
            this.filter = filter;
        },

        /**
         * @param {EventParticipantsListFilter} filter
         * @private
         */
        _updateRouteFilter(filter) {
            let query = {
                page: 1
            };
            let status = _.trim(filter.status);
            if (status) {
                query.status = status;
            }
            let search = _.trim(filter.search);
            if (search) {
                query.search = search;
            }
            let fio = _.trim(filter.fio);
            if (fio) {
                query.fio = fio;
            }
            this.$router.push({ query })
                .catch(() => {
                });
        },

        _onSubmenuServiceClick(participant) {
            this.setSelectedParticipants({
                selectedParticipants: [participant]
            });
            this.$router.push({
                name: ROUTES.EVENT.CARD.SERVICE.ADD_PARTICIPANT
            });
        },

        _onHasPhonedClick(participant) {
            let participantId = participant.id;
            let hasPhoned = !!_.get(participant, 'attributes.has_phoned');
            let result = Participant.updateHasPhoned({ participantId, hasPhoned });
            result.then(resp => {
                this.$message.success(resp);
                this.getParticipants({
                    eventId: this.eventId,
                    page: this.participantPage
                });
            }).catch(resp => {
                this.$message.error(resp);
            });
        },

        _prepareRequest(status) {
            let participants = this.selectedParticipants.map(value => {
                return value.id;
            });
            return {
                participants,
                status
            };
        },

        async _onSubmenuStatusChanged(...args) {
            this.setSelectedParticipants({
                selectedParticipants: [args[1]]
            });
            let requestBody = this._prepareRequest(args[2]);
            let response = await this.updateStatus(requestBody);
            response.success
                ? this.$message.success(response.data)
                : this.$message.error(response.data);
            this.setSelectedParticipants({
                selectedParticipants: []
            });
            this.getParticipants({
                eventId: this.eventId,
                page: this.participantPage
            });
        },

        _onMoveToEventSubmenuChanged(row, eventId) {
            const onSuccess = (response) => {
                if (Array.isArray(response) && response.length > 0) {
                    this.$router.push({
                        name: ROUTES.EVENT.CARD.HOTEL,
                        params: { eventId }
                    });
                } else {
                    this.$message.warning('Гость уже были перенесен на выбранный фестиваль');
                }
            };
            this.moveParticipantsToEvent({ eventId, participants: [row.id] })
                .then(onSuccess)
                .catch(e => this.$message.error(e));
        },

        _onAddParticipantDialogClosed() {
            this.isParticipantDialogVisible = false;
        },

        _onAddParticipantSuccess() {
            this.isParticipantDialogVisible = false;
            this._loadParticipants(this.participantPage);
        },

        _onReCheckInClick(selectedParticipant) {
            this.setSelectedParticipants({
                selectedParticipants: [selectedParticipant]
            });

            this.setRecheckInMode({ mode: true });

            this.$router.push({
                name: ROUTES.EVENT.CARD.HOTEL
            });
        },

        _onCheckInClick(selectedParticipant) {
            this.setRecheckInMode({ mode: false });

            this.setSelectedParticipants({
                selectedParticipants: [selectedParticipant]
            });

            this.$router.push({
                name: ROUTES.EVENT.CARD.HOTEL
            });
        },

        async _onCheckOutClick(selectedParticipant) {
            let response = await this.participantCheckout({
                participantId: selectedParticipant.id
            });
            if (response.success) {
                this.$message.success(response.data);
                await this.getParticipants({
                    eventId: this.eventId,
                    page: this.participantPage
                });
            } else {
                this.$message.error(response.data);
            }
        },
        _reset() {
            this.setParticipants({
                participants: [],
                meta: {}
            });
        },

        _applyFilters() {
            this._loadParticipants(1);
        },

        _onPaginatorChange(page) {
            this.$router.push({ query: { page } });
        },

        _onSelectionChange(selectedParticipants) {
            this.setSelectedParticipants({
                selectedParticipants
            });
        },

        /**
         * @param {ParticipantResource} item
         * @private
         */
        _participantCardRoute(item) {
            return {
                name: ROUTES.EVENT.PARTICIPANT.CARD,
                params: {
                    eventId: this.eventId,
                    participantId: item.id
                }
            };
        },

        /**
         * @param {Number} page
         * @private
         */
        _loadParticipants: _.debounce(function(page) {
            if (!this.eventId) {
                return;
            }
            this.getParticipants({
                eventId: this.eventId,
                page,
                filter: this.filter
            });
        }, 300),

        tableRowStyle({ row, rowIndex }) {
            let color = _.get(row, 'relationships.participant_status.attributes.color');
            return { background: color };
        },

        async _onRowSelected(row) {
            let response = await this.getParticipantGroup({
                participantId: row.id
            });
            if (response.success) {
                this.addSelectedParticipants(response.data);
            } else {
                this.$message.error('Ошибка получения данных!');
            }
        },
        _onRowRemoved(row) {
            this.removeParticipant(row.id);
        },

        _onAddParticipantClick() {
            this.isParticipantDialogVisible = true;
        },

        _onResetFiltersClick() {
            this._resetFilter();
        },

        _tryResetHasPhonedOfEvent() {
            const onYesBtnClick = () => {
                this.removeHasPhonedOfEvent(this.eventId)
                    .then(response => {
                        this.$message.success(response);
                        this._loadParticipants();
                    })
                    .catch(e => this.$message.error(e));
            };
            this.$confirm('Сбросить галочки?', 'Внимание', {
                confirmButtonText: 'Да',
                cancelButtonText: 'Нет',
                type: 'warning'
            }).then(onYesBtnClick)
                .catch(() => {
                });
        },

        _onHeaderClick(column) {
            if (column.property === HAS_PHONED_COL_PROP) {
                this._tryResetHasPhonedOfEvent();
            }
        },

        _resetFilter() {
            this.filter = {
                userInput: '',
                status: ''
            };
            this.$router.push({ query: { page: 1 } });
            this._loadParticipants(1);
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.popover-trigger {
  cursor: pointer;
}

.event-participant-list {
  max-width: 1200px;

  &__clear-filter {
    width: 100%;
  }

  &__table {
    tr {
      &:hover {
        .event-participant-list__submenu-block {
          dispay: block;
        }
      }
    }
    th .column__has-phoned {
      cursor: pointer;
    }
  }

  &__participant {
    //white-space: nowrap;
    display: flex;
    align-items: center;

    .fa-baby {
      margin-left: 5px;
    }

    .event-participant-list__popover-menu {
      margin-left: 10px;
      display: none;
      cursor: pointer;
      clear: both;
    }

    &:hover {
      .event-participant-list__popover-menu {
        display: block;
      }
    }
  }

  &__name {
    max-width: 220px;
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-word;

    &--no-link {
      text-decoration: none;

      .event-participant-list__name-link {
        text-decoration: underline;
      }
    }
  }

  &__nameline {
    line-height: 30px;
  }

  &__age {
    color: #606266;
    display: inline-block;
    font-size: .8em;
    position: relative;
    top: -.5em;
  }

  &__child {
    color: #000;
    display: inline-block;
    font-size: .8em;
    position: relative;
    top: -.5em;

    i {
      top: 0;
    }
  }

  &__date-info {
    display: flex;
    justify-content: space-between;
  }

  &__submenu-trigger {
    position: absolute;
    right: 0;
    top: calc(50% - 15px);
  }

  &__popover-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

  }

  &__submenu-block {
    display: none;
    cursor: pointer;
  }

  &__submenu {
    list-style-type: none;
    padding: 0;
    margin: 5px 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    li {
      margin-top: 10px;
    }

    a {
      display: inline-block;
      padding: 1px 0;
    }
  }

  .el-table {
    td {
      padding: 6px 0;
    }
  }
}
</style>
