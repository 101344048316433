<template>
    <el-dropdown
        trigger="click"
        @command="_onRoomCancelReedimClick"
    >
        <el-button
            :disabled="disabled"
            type="text"
        >
            Номер выкуплен
            <!--            <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
                Отменить выкуп
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'RoomCardCancelRedeemBtn',
    props: {
        roomId: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {

        ...mapState('event/hotel', [
            'selectedBuildingId'
        ]),

        ...mapState('event', {
            event: 'resource'
        }),

        eventId() {
            return this.$route.params.eventId;
        },

        hotelId() {
            return this.$prop('event.attributes.hotel_id');
        }
    },

    methods: {
        ...mapActions('participant', [
            'cancelredeemRoom'
        ]),

        ...mapActions('event/hotel', [
            'getBuildingData'
        ]),

        async _onRoomCancelReedimClick() {
            let response = await this.cancelredeemRoom({
                roomId: this.roomId,
                eventId: this.$route.params.eventId
            });
            if (response.success) {
                this.$message.success(response.data);
                await this.getBuildingData({
                    eventId: this.eventId,
                    hotelId: this.hotelId,
                    buildingId: this.selectedBuildingId
                });
            } else {
                this.$message.error('Ошибка');
            }
        }
    }
};
</script>
