/**
 * @param {Number} value
 * @param {Number} f
 * @param {String} c
 * @return {*}
 */
export default (value, f = 0, c = 'руб.') => {
    let val = (value / 1).toFixed(f);
    return val.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;') + (c ? '&nbsp;' + c : '');
};
