<template>
    <el-dropdown
        trigger="click"
        @command="_onRoomRedeemClick"
    >
        <el-button
            type="text"
            :disabled="disabled"
            class="redeem-btn"
            :class="{'redeem-btn--disabled': disabled}"
        >
            Выкупает целиком<i
                v-if="!disabled"
                class="el-icon-arrow-down el-icon--right"
            />
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="participant in participants"
                :key="participant.id"
                :command="participant.id"
            >
                {{ participant.appellation }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'RoomCardRedeemBtn',
    props: {
        participants: {
            type: Array,
            default: () => []
        },
        roomId: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {

        ...mapState('event/hotel', [
            'selectedBuildingId'
        ]),

        ...mapState('event', {
            event: 'resource'
        }),

        eventId() {
            return this.$route.params.eventId;
        },

        hotelId() {
            return this.$prop('event.attributes.hotel_id');
        }
    },

    methods: {
        ...mapActions('participant', [
            'redeemRoom'
        ]),

        ...mapActions('event/hotel', [
            'getBuildingData'
        ]),

        async _onRoomRedeemClick(participantId) {
            let response = await this.redeemRoom({
                roomId: this.roomId,
                participantId,
                eventId: this.eventId
            });

            if (response.success) {
                this.$message.success(response.data);
                await this.getBuildingData({
                    eventId: this.eventId,
                    hotelId: this.hotelId,
                    buildingId: this.selectedBuildingId
                });
            } else {
                this.$message.error('Ошибка');
            }

        }
    }
};
</script>

<style lang="scss">
.redeem-btn {
    color: #0486FE !important;
    &--disabled {
        color: #A3D0FD !important;
    }
}
</style>
