<template>
    <div
        class="event-stats"
    >
        <h1 class="app-h1">
            Статистика
        </h1>
        <el-row :gutter="20">
            <el-col :span="6">
                <registration-link
                    :event-id="eventId"
                />
                <stats-exports
                    :event-id="eventId"
                />
            </el-col>
            <el-col :span="6">
                <participants-by-status-and-event
                    :event-id="eventId"
                />
            </el-col>
            <el-col :span="10">
                <participants-age-widget />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import ParticipantsByStatusAndEvent from '@/modules/stats/components/ParticipantsByStatusAndEvent';
import RegistrationLink from '@/modules/stats/components/RegistrationLink';
import StatsExports from '@/modules/stats/components/StatsExports';
import ParticipantsAgeWidget from '@/containers/events/widgets/participantAgeWidget/ParticipantsAgeWidget';

export default {
    name: 'EventStats',

    components: {
        ParticipantsAgeWidget,
        RegistrationLink,
        ParticipantsByStatusAndEvent,
        StatsExports
    },

    computed: {

        eventId() {
            return this.$route.params.eventId;
        }
    }

};
</script>

<style lang="scss">
@import "@vars";

</style>
