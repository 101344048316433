export default [
    {
        id: '130a1641-180c-4234-9fcd-112382221ce9',
        coordX: 443,
        coordY: 116
    },
    {
        id: '20a5756d-4381-4844-9f11-d6a96b344809',
        coordX: 491,
        coordY: 116
    },
    {
        id: 'd755766b-8833-44db-b834-774448454749',
        coordX: 535,
        coordY: 116
    },
    {
        id: 'ca374ba8-7e02-4b6c-8aa9-6792deb569fe',
        coordX: 590,
        coordY: 116
    },
    {
        id: '99a3e4a8-efa3-4b18-b118-977d40deef7d',
        coordX: 638,
        coordY: 116
    },
    {
        id: '295e4e0e-e258-4454-af8a-830484fdb773',
        coordX: 685,
        coordY: 116
    },
    {
        id: '5f4c151b-0ca8-4aba-94b5-3b9671e9c6a6',
        coordX: 761,
        coordY: 116
    },
    {
        id: '8711b04a-5f5d-43c2-b0ea-3d9b30534683',
        coordX: 814,
        coordY: 116
    },
    {
        id: 'c0b59972-7022-4714-8276-68a9e478be23',
        coordX: 864,
        coordY: 296
    },
    {
        id: '9cc97d70-0105-4ad0-a158-135381a6db50',
        coordX: 808,
        coordY: 323
    },
    {
        id: '6d9af801-dcf6-4e35-bffd-d9a0de39ad24',
        coordX: 761,
        coordY: 350
    },
    {
        id: 'f642e9f9-e9aa-4961-9bab-42828baed0f2',
        coordX: 685,
        coordY: 395
    },
    {
        id: '76d4b2da-6e6c-48e1-8dbb-e4953d5df2ef',
        coordX: 641,
        coordY: 432
    },
    {
        id: '15c8280b-bbfd-4b51-bd85-4a20420217f9',
        coordX: 515,
        coordY: 457
    },
    {
        id: 'ded02eee-8571-4812-9ff4-bf7f771a189c',
        coordX: 521,
        coordY: 408
    },
    {
        id: '71f34e23-7afd-4897-8af8-9ce909c152f7',
        coordX: 442,
        coordY: 374
    },
    {
        id: 'bca6dc7f-ad3c-4f8f-8548-47283fd4b8bb',
        coordX: 446,
        coordY: 328
    },
    {
        id: '5943cbc1-9283-42b0-9a9e-3d379746a6e3',
        coordX: 393,
        coordY: 295
    },
    {
        id: 'f16746fc-c190-4c9f-b5c2-820d25d50b2c',
        coordX: 332,
        coordY: 280
    },
    {
        id: '17df0283-091a-4a7d-9c0c-de1943fddf34',
        coordX: 765,
        coordY: 235
    },
    {
        id: '5e83ed36-63b0-4548-be63-d22fa49e808e',
        coordX: 685,
        coordY: 275
    },
    {
        id: '35c8e341-8326-4371-b69c-81942b5e0b3f',
        coordX: 646,
        coordY: 309
    },
    {
        id: 'e3b0d10a-0651-45b8-a627-4823244a70cd',
        coordX: 595,
        coordY: 341
    },
    {
        id: '6c1ef65a-cc8a-460b-a974-455cf385c507',
        coordX: 974,
        coordY: 17
    },
    {
        id: '15787ce0-1ecb-4044-8185-b51f1dd68a24',
        coordX: 1002,
        coordY: 68
    },
    {
        id: '90c9bc91-97a6-425e-b49f-43ff34523708',
        coordX: 945,
        coordY: 90
    },
    {
        id: '47d17594-c508-43ed-b534-4cfbd903ccd6',
        coordX: 1030,
        coordY: 127
    },
    {
        id: '20bbb5c4-b230-4d68-9892-12e42a11aa05',
        coordX: 1054,
        coordY: 296
    },
    {
        id: 'dc5760fb-0d85-49ca-a761-3227c1ecf129',
        coordX: 937,
        coordY: 391
    },
    {
        id: 'd2e09cb3-7fc2-459a-9e16-13ad0e43ad6f',
        coordX: 237,
        coordY: 300
    },
    {
        id: '0cd4e62c-262f-45f4-b592-c32ffc3a6abf',
        coordX: 216,
        coordY: 346
    },
    {
        id: 'a29622a3-57b5-498b-8bf7-9aabbdd950c5',
        coordX: 198,
        coordY: 391
    },
    {
        id: '39e309b6-1884-4d99-91b9-d01c9eb42443',
        coordX: 180,
        coordY: 447
    },
    {
        id: '4c12d78f-361e-4b96-8f73-4ee97e37ba5f',
        coordX: 125,
        coordY: 500
    },
    {
        id: '3de39eb6-1bc1-474f-a0f0-79020c28ff3f',
        coordX: 323,
        coordY: 367
    },
    {
        id: 'e21f6282-e395-4a04-9106-aa769cd7c17d',
        coordX: 322,
        coordY: 427
    },
    {
        id: '4533aa62-4a42-4dc0-81d8-44b5daa2100d',
        coordX: 387,
        coordY: 446
    },
    {
        id: '35c52ed6-beda-4758-b98b-8ef065fe16ed',
        coordX: 406,
        coordY: 515
    },
    {
        id: 'aa8e8417-f3a4-49aa-b4a6-57d689e5a72f',
        coordX: 477,
        coordY: 539
    },
    {
        id: '8affa54c-3e0b-4f3b-a8c0-0b09aa0269a0',
        coordX: 913,
        coordY: 271
    },
    {
        id: 'd1dd9231-1232-4923-91cc-dc7f00f01059',
        coordX: 995,
        coordY: 352
    }
];
