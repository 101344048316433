<template>
    <div class="event-service-list">
        <h1 class="app-h1">
            Список услуг
        </h1>

        <el-button
            type="primary"
            @click="_addServiceToEvent"
        >
            Добавить услугу
        </el-button>

        <el-table
            v-loading="loading"
            :data="preparedData"
            style="width: 100%"
        >
            <el-table-column
                :width="400"
                label="Название"
            >
                <template slot-scope="scope">
                    <router-link :to="_servicePrintLink(scope.row.serviceId)">
                        {{ scope.row.serviceTitle }}
                    </router-link>
                    <el-tag
                        v-if="scope.row.group"
                        size="mini"
                        effect="dark"
                        style="margin-left:5px; line-height: 16px;"
                    >
                        {{ scope.row.group }}
                    </el-tag>
                    <i
                        class="el-icon-edit-outline event-service-list__icon event-service-list__edit"
                        @click="_onEventServiceRowEdit(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column
                label="Специалист"
                prop="specialistName"
            />
            <el-table-column label="Место проведения">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.locationName }}
                    </div>
                    <div style="color:#888; font-size: .8em;">
                        {{ scope.row.locationDescription }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Цена">
                <template slot-scope="scope">
                    <!--eslint-disable-->
                    <div v-html="scope.row.servicePrice" />
                    <!--eslint-enable-->
                </template>
            </el-table-column>
            <el-table-column
                prop="participants_registered"
                :width="70"
                label="Кол-во"
            />
            <el-table-column
                :width="60"
                align="center"
            >
                <template slot-scope="scope">
                    <i
                        class="el-icon-delete event-service-list__icon event-service-list__remove"
                        @click="_onEventServiceRowRemove(scope)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <event-service-form
            ref="form"
            @added="_onEventServiceAdded"
            @updated="_onEventServiceUpdated"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import money from '@/filters/money';
import { mapActions, mapState, mapMutations } from 'vuex';
import EventServiceForm from '@/modules/event/components/card/service/EventServiceForm';
import ROUTES from '@/enums/routes';

/**
 * @typedef {Object} EventServiceListRow
 * @property {String} locationName
 * @property {String} serviceTitle
 * @property {Number} servicePrice
 * @property {String} specialistName
 * @property {String} locationDescription
 * @property {String} group
 * @property {UUID} serviceId
 * @property {EventServiceResource} resource
 */

export default {
    name: 'EventServiceList',

    components: {
        EventServiceForm
    },

    props: {
        eventId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('event/service', [
            'services'
        ]),

        /**
         *
         * @returns {EventServiceListRow[]}
         */
        preparedData() {
            /** @param {EventServiceResource} x */
            const mapper = x => {
                /** @type {EventServicePlanResource[]} */
                const plans = _.get(x, 'relationships.plans', []);
                /** @param {EventServicePlanResource} plan */
                const mapper = plan => {
                    return `${plan.attributes.title}: ${money(plan.attributes.default_price, 0)}`;
                };
                const planInfo = plans.map(mapper);
                return {
                    serviceId: _.get(x, 'attributes.service_id'),
                    serviceTitle: _.get(x, 'attributes.service_title'),
                    group: _.get(x, 'attributes.group'),
                    specialistName: _.get(x, 'attributes.specialist_name') || 'Не указан',
                    servicePrice: planInfo.join('<br>') || 'Не указана',
                    locationName: _.get(x, 'attributes.location_title', 'Не указано'),
                    locationDescription: _.get(x, 'attributes.location_description') || '',
                    participants_registered: _.get(x, 'attributes.participants_registered'),
                    resource: x
                };
            };
            return this.services.map(mapper);
        },

        config() {
            return {
                methods: row => ({
                    servicePrintLink: this._servicePrintLink.bind(this, row)
                }),
                columns: [
                    {
                        title: 'Место проведения',
                        render: row => `<div>
                                ${row.locationName}
                            </div>
                            <div style="color:#888; font-size: .8em;">
                                ${row.locationDescription}
                            </div>`
                    },
                    {
                        title: 'Стоимость',
                        render: row => row.servicePrice
                    }
                ]
            };
        }
    },

    mounted() {
        this._loadServices();
    },

    methods: {
        ...mapActions('event/service', [
            'getServices'
        ]),

        ...mapMutations('event/service', [
            'setEventServiceToEdit'
        ]),

        /**
         * @param {{row: EventServiceListRow}} scope
         * @private
         */
        _onEventServiceRowRemove(scope) {
            this._doRemove(scope.row);
        },

        /**
         * @param {EventServiceListRow} row
         */
        _doRemove(row) {
            this.$message.info('Пока не реализовано');
        },

        /**
         * @param {EventServiceListRow} row
         * @private
         */
        _onEventServiceRowEdit(row) {
            this._doEdit(row);
        },

        /**
         * @param {EventServiceListRow} row
         */
        _doEdit(row) {
            this.setEventServiceToEdit({
                eventServiceToEdit: row.resource
            });
            this.$refs.form.open();
        },

        _loadServices() {
            this.loading = true;
            this.getServices({ eventId: this.eventId })
                .then(() => {
                    this.loading = false;
                })
            ;
        },

        /**
         * @param {UUID} serviceId
         * @returns {{name: string, params: {serviceId: *}}}
         * @private
         */
        _servicePrintLink(serviceId) {
            return {
                name: ROUTES.EVENT.CARD.SERVICE.PARTICIPANTS,
                params: {
                    serviceId
                }
            };
        },

        _onEventServiceAdded() {
            this._loadServices();
        },

        _onEventServiceUpdated() {
            this._loadServices();
        },

        _addServiceToEvent() {
            this.setEventServiceToEdit({
                eventServiceToEdit: null
            });
            this.$refs.form.open();
        }
    }
};
</script>

<style lang="scss">
.event-service-list {
  &__icon {
    font-size: 1.2em;
    cursor: pointer;
    visibility: hidden;
  }

  &__edit {
    color: #0486fe;
    margin-left: 10px;
  }

  &__remove {
    color: #eb2929;
  }

  .el-table__row {
    &:hover {
      .event-service-list__icon {
        visibility: visible;
      }
    }
  }
}

</style>
