<template>
    <el-card
        :class="cardClasses"
        shadow="none"
        class="room-card-small"
    >
        <div
            slot="header"
            class="room-card-small__header"
        >
            <el-tooltip
                v-if="hasComment"
                :content="comment"
            >
                <div class="room-card-small__comment" />
            </el-tooltip>
            <div class="room-card-small__title">
                {{ title }}
            </div>
            <div class="room-card-small__view">
                <i class="far fa-eye" />
                {{ viewShort }}
            </div>
            <div class="room-card-small__size">
                {{ size }}
            </div>
        </div>
        <div class="room-card-small__places">
            <place-link
                v-for="place in places"
                :key="place.id"
                :place="place"
                :participants-to-check-in="participantsToCheckIn"
                :is-room-redeemed="isRoomRedeemed"
                @checkIn="_onCheckIn"
            />
        </div>
        <div
            class="room-card-small__footer"
            :class="{'room-card-small__footer--is-redeemed': isRoomRedeemed}"
        >
            <room-card-cancel-redeem-btn
                v-if="isRoomRedeemed"
                :room-id="room.id"
            />
            <room-card-redeem-btn
                v-else
                :participants="participants"
                :room-id="room.id"
                :disabled="!isRoomSettled || isRoomFull"
            />
        </div>
    </el-card>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import PlaceLink from '@/modules/event/components/common/PlaceLink';
import RoomCardRedeemBtn from '@/modules/event/components/card/hotel/RoomCardRedeemBtn';
import RoomCardCancelRedeemBtn from '@/modules/event/components/card/hotel/RoomCardCancelRedeemBtn';

export default {
    name: 'RoomCardSmall',

    components: {
        RoomCardCancelRedeemBtn,
        RoomCardRedeemBtn,
        PlaceLink
    },

    props: {
        room: {
            type: Object,
            default: null
        },

        /**
         * @type {ParticipantResource[]}
         */
        participantsToCheckIn: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        ...mapState('enums', [
            'roomCategories'
        ]),

        roomCardClass() {
            // Если выбрано более одного заселенца либо все заселено - то не подсвечиваем потенциального соседа, т.к. кейс на одного
            if (!this.participantsToCheckIn || this.participantsToCheckIn.length === 0 || this.isRoomFull) {
                return false;
            }
            let checkingParticipant = this.participantsToCheckIn[0];
            let isPotentialNeighbour = false;
            // Находим являются ли заселенцы схожи гендерно или +- по возрасту в диапазоне 5 лет
            this.places.forEach(place => {
                place.relationships.participants.find(participant => {
                    let sameGender = participant.attributes.gender === checkingParticipant.attributes.gender;
                    let nearestAge = (participant.attributes.age <= checkingParticipant.attributes.age + 5) ||
                      (participant.attributes.age >= checkingParticipant.attributes.age - 5);
                    isPotentialNeighbour = (sameGender && nearestAge);
                });
            });
            return isPotentialNeighbour;
        },

        title() {
            return this.$prop('room.attributes.title');
        },

        isRoomRedeemed() {
            return this.$prop('room.attributes.is_redeemed');
        },

        view() {
            return this.$prop('room.attributes.window_view', '');
        },

        viewShort() {
            return _.truncate(this.view, {
                length: 15,
                separator: /,? +/
            });
        },

        size() {
            return this.$prop('room.attributes.size');
        },

        comment() {
            return this.$prop('room.attributes.comment');
        },

        hasComment() {
            return !!this.comment;
        },

        places() {
            return this.$prop('room.relationships.places', []);
        },

        primaryPlacesOnly() {
            return this.places.filter(place => {
                return place.attributes.is_secondary !== true;
            });
        },

        isRoomSettled() {
            return this.participants.length > 0;
        },

        isRoomFull() {
            return this.primaryPlacesOnly.length <= this.participants.length;
        },

        participants() {
            let participants = [];
            this.places.forEach(place => {
                let participant = _.get(place, 'relationships.participants', []);
                if (participant.length > 0) {
                    participants.push({
                        id: _.get(participant[0], 'id'),
                        appellation: _.get(participant[0], 'attributes.appellation')
                    });
                }
            });
            return participants;
        },

        roomCategoryId() {
            return this.$prop('room.attributes.room_category_id');
        },

        cardClasses() {
            return {
                'room-card-small--comfort': this.roomCategoryId === this.roomCategories.comfort.id,
                'room-card-small--comfort-plus': this.roomCategoryId === this.roomCategories.comfort_plus.id,
                'room-card-small--potential': this.roomCardClass
            };
        }
    },

    methods: {
        _onCheckIn(e) {
            this.$emit('checkIn', e);
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.room-card-small {
    min-width: 150px;
    overflow: initial;
    .el-card__header {
        padding: 10px 20px 5px;
        border-radius: 6px 6px 0 0;
    }

    &--comfort {
        .el-card__header {
            background: #A3D0FD;
            border-color: darken(#A3D0FD, 10%) !important;
        }
    }

    &--comfort-plus {
        .el-card__header {
            background: $color-highlight;
            border-color: darken($color-highlight, 10%) !important;
        }
    }

    &--potential {
        .el-card__body {
            background: #E6FBDC;
        }
    }

    &__header {
        @include flex-row-centered;
        position: relative;

    }

    &__comment {
        position: absolute;
        top: -20px;
        right: -30px;
        z-index: 10;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: red;
    }

    &__title {
        flex: 1 0 auto;
        text-align: left;
        font-size: 16pt;
    }

    &__size {
        flex: 1 0 auto;
        text-align: right;
        font-size: 14pt;
    }

    &__places {
        min-height: 100px;
    }

    &__footer {

        background: #E6F1FC;
        box-shadow: 0 0 0 1px darken($color-primary-bg--light, 10%) inset;
        margin: 25px -20px -20px -20px;
        padding: 0;
        border-radius: 0 0 6px 6px;
        .el-button {
            font-family: "PT Sans", sans-serif;
        }
        &:hover {
            background: darken($color-primary-bg--light, 3%);
        }

        &.room-card-small__footer--is-redeemed {
            background: #FFE9E9;
            border: 1px solid #FDBBBB;
            box-sizing: border-box;
            border-radius: 0 0 6px 6px;
            color: #EB2929;
            box-shadow: none;
            //background: $color-error;
            //box-shadow: 0 0 0 1px darken($color-error, 10%) inset;

            &:hover {
                //background: darken($color-error, 3%);
            }

            .el-button {
                //color: rgba(0, 0, 0, .7);
                color: red;

            }
        }

        .el-dropdown {
            display: block;
        }

        .el-button {
            color: rgba(0, 0, 0, .7);
            display: block;
            width: 100%;
        }
    }
}
</style>
