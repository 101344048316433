<template>
    <el-dialog
        title="Добавить гостя"
        :visible="isVisible"
        :close-on-click-modal="false"
        @close="_onDialogClose"
    >
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
        >
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item
                        label="Фамилия"
                        prop="surname"
                    >
                        <el-input v-model="form.surname" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        label="Имя"
                        prop="name"
                    >
                        <el-input v-model="form.name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        label="Отчество"
                        prop="patronymic"
                    >
                        <el-input v-model="form.patronymic" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item
                        label="Город"
                        prop="city"
                    >
                        <el-autocomplete
                            v-model="form.city"
                            style="width: 100%;"
                            :fetch-suggestions="_getCitySuggestion"
                            placeholder="Город"
                            :trigger-on-focus="false"
                            @select="_onCitySuggestionSelect"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        label="Дата рождения"
                        prop="date_of_birth"
                        :rules="[{ required: true, message: 'Укажите дату рождения'}]"
                    >
                        <el-date-picker
                            v-model="form.date_of_birth"
                            :picker-options="dateOfBirthOptions"
                            :format="datepickerDateFormat"
                            style="width: 100%;"
                            value-format="dd.MM.yyyy"
                            type="date"
                            placeholder="Выберите дату"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        label="Пол"
                        prop="gender"
                        required
                    >
                        <el-select
                            v-model="form.gender"
                            style="width: 100%;"
                            placeholder="Укажите пол"
                        >
                            <el-option
                                v-for="{title, value} in sexOptions"
                                :key="value"
                                :label="title"
                                :value="value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item
                        label="Номер телефона"
                        prop="phone"
                    >
                        <app-phone-input
                            v-model="form.phone"
                            placeholder="Номер телефона"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        label="Электронная почта"
                        prop="email"
                    >
                        <el-input v-model="form.email" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        prop="status"
                        label="Статус"
                        required
                    >
                        <el-select
                            v-model="form.status"
                            disabled
                        >
                            <el-option
                                v-for="status in statusList"
                                :key="status.id"
                                :label="status.attributes.title"
                                :value="status.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span
            slot="footer"
            class="dialog-footer"
        >
            <el-button @click="_onDialogClose">Отмена</el-button>
            <el-button
                type="primary"
                @click="_onAddClick"
            >Добавить</el-button>
        </span>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import client from '@/utils/dadataClient';
import { mapState, mapActions } from 'vuex';
import AppPhoneInput from '@/components/form/AppPhoneInput';
import { PARTICIPANTS } from '@/enums';

export default {
    name: 'EventAddParticipantDialog',
    components: {
        AppPhoneInput

    },

    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {
                status: PARTICIPANTS.STATUSES.STAFF,
                phone: '',
                date_of_birth: '01.01.1980'
            },
            areSuggestionLoading: false,
            client
        };
    },

    computed: {
        ...mapState('enums', [
            'sexOptions'
        ]),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('participant', [
            'statusList'
        ]),

        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите имя участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Имя не должно быть короче 2 символов',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: 'Выберите статус',
                        trigger: 'blur'
                    }
                ],
                gender: [
                    {
                        required: true,
                        message: 'Укажите пол',
                        trigger: 'blur'
                    }
                ]
            };
        },

        dateOfBirthOptions() {
            const maxAllowedAge = this.maxAllowedAge;
            return {
                disabledDate(date) {
                    const diffInYears = moment()
                        .diff(date, 'years');
                    const diff = moment()
                        .diff(date);
                    return diffInYears > maxAllowedAge || diff < 0;
                }
            };
        }
    },
    methods: {

        ...mapActions('participant', [
            'addParticipant'
        ]),

        _onAddClick() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this._addParticipantRequest();
                } else {
                    this.$message.error('Введите все поля');
                }
            });
        },

        async _addParticipantRequest() {
            let response = await this.addParticipant({
                ...this.form,
                event_id: this.$route.params.eventId
            });
            if (response.success) {
                this.$message.success(response.data);
                this._onSuccess();
            } else {
                this.$message.error(JSON.stringify(response.error));
            }
        },

        _onDialogClose() {
            this._resetForm();
            this.$emit('onClose');
        },

        _onSuccess() {
            this.$emit('onSuccess');
        },

        _resetForm() {
            this.form = {
                status: PARTICIPANTS.STATUSES.STAFF,
                phone: '',
                date_of_birth: '01.01.1980'
            };
            this.$refs.form.resetFields();
        },
        /**
         * @param {{
         *     suggestion: DaDataSuggestion,
         *     value: String,
         * }} data
         */
        _onCitySuggestionSelect(data) {
            this.form.location_provider = 'dadata';
            this.form.location_payload = data.suggestion;
            this.form.location = data.suggestion.unrestricted_value;
            this.form.city = data.suggestion.data.city;
            this.form.country = data.suggestion.data.country;
            this.$refs.form.validateField('city');
        },

        /**
         * @param {String} query - Например, 'Моск'
         * @param {Function} cb
         */
        _getCitySuggestion(query, cb) {
            this.form.location_provider = null;
            this.form.location_payload = null;
            this.form.country = null;
            this.form.location = null;
            this.areSuggestionLoading = true;
            const onError = e => {
                const errorMessage = _.get(e, 'message', 'Не удалось получить список городов для подсказки.');
                this.$message.error(errorMessage);
            };
            this._suggest(query)
                .then(suggestions => {
                    /**
                     * При ошибке все равно резолвится,
                     * нет возможности ошибку отдельно обработать
                     */
                    if (!suggestions || !suggestions.length) {
                        // onError({ message: 'Ничего не найдено' });
                    }
                    cb(suggestions.map(suggestion => ({
                        value: suggestion.value,
                        suggestion
                    })));
                })
                .catch(onError)
                .finally(() => {
                    this.areSuggestionLoading = false;
                });
        },

        _suggest(query) {
            return this.client.suggest(
                'address',
                query,
                5,
                {
                    from_bound: {
                        value: 'city'
                    },
                    to_bound: {
                        value: 'city'
                    },
                    locations: [
                        {
                            country: '*',
                            city_type_full: 'город'
                        }
                    ]
                }
            );
        }
    }

};
</script>
