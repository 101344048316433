<template>
    <el-card
        class="app-widget participants-age-widget stats-box-card"
        shadow="hover"
    >
        <div class="participants-age-widget__header">
            По возрасту
        </div>
        <div class="participants-age-widget__filters">
            <el-row>
                <el-col :span="12">
                    <el-select
                        v-model="filter.birth_dates"
                        :value="filter.birth_dates"
                        filterable
                        multiple
                        clearable
                        size="small"
                        @change="_applyFilter"
                    >
                        <el-option
                            v-for="(age, index) in ageSettings"
                            :key="index"
                            :value="age.value"
                            :label="_ageTitle(age.title)"
                        />
                    </el-select>
                </el-col>
                <el-col :span="12">
                    <el-select
                        v-model="filter.status"
                        filterable
                        clearable
                        :value="filter.status"
                        size="small"
                        @change="_applyFilter"
                    >
                        <el-option
                            v-for="(status, index) in statusList"
                            :key="index"
                            :value="status.id"
                            :label="status.attributes.title"
                        />
                    </el-select>
                </el-col>
            </el-row>
        </div>
        <el-table
            v-loading="loading"
            class="participants-age-widget__table"
            :data="ageTable"
            :show-header="false"
        >
            <el-table-column
                prop="title"
            >
                <template slot-scope="scope">
                    {{ scope.row.age }} лет
                </template>
            </el-table-column>
            <el-table-column
                prop="count"
                align="center"
            >
                <template slot-scope="scope">
                    {{ scope.row.total }}
                </template>
            </el-table-column>
        </el-table>
        <div class="participants-age-widget__total">
            <div>Итого:</div>
            <div>{{ totalCount }}</div>
        </div>
    </el-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    name: 'ParticipantsAgeWidget',
    data() {
        return {
            isLoading: false,
            tableData: [],
            mockFilterAge: [],
            filter: {
                birth_dates: [],
                status: ''
            }
        };
    },
    computed: {
        ...mapState('participant', [
            'statusList'
        ]),
        ...mapGetters('widgets/participantAgeWidget', [
            'loading',
            'ageSettings',
            'ageTable'
        ]),
        totalCount() {
            let total = 0;
            this.ageTable.forEach(item => {
                total += item.total;
            });
            return total;
        }
    },
    mounted() {
        this.getStatusList();
        this.getSettings({ eventId: this.$route.params.eventId });
        this.fetchData({ eventId: this.$route.params.eventId, data: this.filter });
    },
    methods: {
        ...mapActions('participant', [
            'getStatusList'
        ]),
        ...mapActions('widgets/participantAgeWidget', [
            'getSettings',
            'fetchData'
        ]),
        _ageTitle(age) {
            return age > 0 ? age : 'Меньше года';
        },

        _applyFilter() {
            this.fetchData({
                eventId: this.$route.params.eventId,
                data: this.filter
            });
        }
    }
};
</script>

<style lang="scss">
.participants-age-widget {
  &__header {
    font-size: 24px;
    margin-bottom: 10px;
  }
  &__table {
    max-height: 350px;
    width: 100%;
    overflow-y: auto;
    &:before {
      content: none;
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: #239F18;
    div {
      flex:1;
      &:last-child {
        text-align: center;
      }
    }
  }
}
</style>
