<template>
    <div
        ref="mapContainer"
        class="event-hotel-info-map"
    >
        <div
            ref="map"
            class="event-hotel-info-map__map"
            :class="{'event-hotel-info-map__map--scrollable': beginScroll}"
            :style="'transform: translate(' + mapPos.x + 'px,'+ mapPos.y + 'px)'"
            @click="_onMouseMove"
            @mousedown="_onMouseDown"
            @mouseup="_onMouseUp"
            @mousemove="_scrollMap"
        >
            <hotel-map-marker
                v-for="building in remappedBuildings"
                :key="building.id"
                :building="building"
                @select="_onSelect"
            />
        </div>
        <el-dialog
            :visible.sync="selectedBuildingDialogVisible"
            width="80%"
            center
            @close="_onBuildingDialogClose"
        >
            <event-hotel-building-card />
            <el-button @click="_onBuildingDialogCancel()">
                Отмена
            </el-button>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import HotelMapMarker from '@/modules/event/components/card/hotel/HotelMapMarker';
import building from '@/modules/hotel/api/building';
import EventHotelBuildingCard from '@/modules/event/components/card/hotel/EventHotelBuildingCard';
import mapPoints from './hotelMapConfig';
export default {
    name: 'EventHotelMap',
    components: { EventHotelBuildingCard, HotelMapMarker },
    props: {
        buildings: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedBuildingDialogVisible: false,
            mapPoints,
            beginScroll: false,
            mapPos: {
                x: 0,
                y: 0
            },
            cursorPosition: {
                x: 0,
                y: 0
            }
        };
    },
    computed: {
        ...mapState('event', {
            // todo: rename
            event: 'resource'
        }),

        remappedBuildings() {
            let remapArray = [];
            this.buildings.forEach(arr => remapArray.push(...arr));
            remapArray.forEach(element => {
                let coord = this.mapPoints.find(point => element.id === point.id);
                if (coord) {
                    element.x = coord.coordX;
                    element.y = coord.coordY;
                }
            });
            return remapArray;
        },
        eventId() {
            return this.$prop('event.id');
        },

        hotelId() {
            return this.$prop('event.attributes.hotel_id');
        }
    },
    watch: {
        buildings: function(value) {
            let computeArray = [];
            value.forEach(ar => computeArray.push(...ar));
        }
    },
    methods: {
        ...mapActions('event/hotel', [
            'getBuildingData',
            'getEventPlacesWithParticipants'
        ]),

        ...mapMutations('event/hotel', [
            'setSelectedBuildingId'
        ]),

        _onBuildingDialogCancel() {
            this.selectedBuildingDialogVisible = false;
        },

        _onBuildingDialogClose() {
            this._clearSelectedBuilding();
        },

        _clearSelectedBuilding() {
            this.setSelectedBuildingId({
                selectedBuildingId: null
            });
        },

        _onReady() {
            this._loadEventHotelBuildings();
            this._loadPlacesWithParticipants();
        },

        /**
         * На основе этих данных считаются, например,
         * участники типа "Один в номере".
         * @private
         */
        _loadPlacesWithParticipants() {
            this.getEventPlacesWithParticipants({
                eventId: this.eventId,
                hotelId: this.hotelId
            });
        },

        _loadEventHotelBuildings() {
            this.areBuildingsLoading = true;
            /** @param {BuildingResource[]} buildings */
            const onResponse = buildings => {
                this.buildings = buildings;
            };
            Event.Hotel.getBuildings({
                eventId: this.eventId,
                hotelId: this.hotelId
            })
                .then(onResponse)
                .catch(e => {
                    this.$message.error('Не удалось получить информацию о корпусах');
                })
                .finally(() => {
                    this.areBuildingsLoading = false;
                });
        },

        /**
         * @param {BuildingResource} building
         * @private
         */
        async _onSelect({ building }) {
            let selectedBuildingId = building ? building.id : null;
            if (this.selectedBuildingId === selectedBuildingId) {
                /** При повторном клике на выбранный корпус */
                selectedBuildingId = null;
            }
            await this.getBuildingData({
                eventId: this.eventId,
                hotelId: this.hotelId,
                buildingId: selectedBuildingId
            });
            this.setSelectedBuildingId({ selectedBuildingId });
            this.selectedBuildingDialogVisible = true;
        },

        _onMouseDown(event) {
            this.beginScroll = true;
            this.cursorPosition.x = event.pageX;
            this.cursorPosition.y = event.pageY;
        },

        _onMouseUp(event) {
            this.beginScroll = false;
        },

        _onMouseMove(event) {
            // TODO если клиент захочет - сделать модалку с координатами клика
            return;
            let x = event.offsetX;
            let y = event.offsetY;
            x = x - 20;
            y = y - 20;
        },

        _scrollMap(event) {
            if (this.beginScroll &&
                this.$refs.map.offsetWidth > this.$refs.mapContainer.offsetWidth) {
                let xDiff = event.pageX - this.cursorPosition.x;
                let yDiff = event.pageY - this.cursorPosition.y;
                let xNew = xDiff + this.mapPos.x;
                let yNew = yDiff + this.mapPos.y;

                if (xNew > 0) xNew = 0;
                if (yNew > 0) yNew = 0;
                if (xNew < -(this.$refs.map.offsetWidth - this.$refs.mapContainer.offsetWidth)) {
                    xNew = -(this.$refs.map.offsetWidth - this.$refs.mapContainer.offsetWidth);
                }
                if (yNew < -(this.$refs.map.offsetHeight - this.$refs.mapContainer.offsetHeight)) {
                    yNew = -(this.$refs.map.offsetHeight - this.$refs.mapContainer.offsetHeight);
                }
                this.mapPos.x = xNew;
                this.mapPos.y = yNew;
            }
        }
    }
};
</script>

<style lang="scss">
.event-hotel-info-map {
  overflow: hidden;
  margin-bottom: 10px;
  &__map {
    width: 1145px;
    height:818px;
    background: url("/assets/img/map 1.png");
    transition: none 0s ease 0s;
    &--scrollable {
      cursor: grab;
    }
  }
}
</style>
