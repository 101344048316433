<template>
    <div class="building-card-small">
        <el-card
            shadow="none"
            class="building-card"
            :class="{'building-card--selected': isSelected}"
            @click.native="_select"
        >
            <div
                slot="header"
                class="building-card__header"
            >
                <el-row>
                    <el-col :span="16">
                        <!-- eslint-disable -->
                        <span v-html="title" />
                        <!-- eslint-enable -->
                    </el-col>
                    <el-col
                        :span="8"
                        align="right"
                    >
                        <div class="building-card__header2">
                            <i class="far fa-building" />
                            {{ building.attributes.floors_count }}
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div
                v-for="info in roomCategoriesInfo"
                :key="info.category"
            >
                {{ info.category }}: {{ info.count }}
            </div>
            <!-- Даже если 0, не скрываю текст, чтобы при получении данных не "скакало" всё -->
            <div :class="{'text-secondary': !roomsWithSingleParticipantCount}">
                Один в номере: {{ roomsWithSingleParticipantCount }}
            </div>
            <div
                v-if="hasDescription"
                class="building-card__description"
            >
                {{ building.attributes.description }}
            </div>
        </el-card>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
    name: 'BuildingCardSmall',
    props: {
        building: {
            type: Object,
            default: null
        },
        rowIndex: {
            type: Number,
            default: 0
        }
    },

    computed: {
        ...mapState('event/hotel', [
            'eventPlacesWithParticipants',
            'selectedBuildingId'
        ]),

        roomsWithSingleParticipantText() {
            return 'Количество номеров, в которых занято только одно место';
        },

        isSelected() {
            return this.selectedBuildingId === this.buildingId;
        },

        buildingPlaces() {
            /** @param {EventPlaceResource} x */
            const filter = x => x.attributes.building_id === this.buildingId;
            return this.$prop('eventPlacesWithParticipants', [])
                .filter(filter);
        },

        groupedBuildingPlaces() {
            return _.groupBy(this.buildingPlaces, 'attributes.room_id');
        },

        /**
         * @return {{roomId, participantAppellation}[]}
         */
        roomsWithSingleParticipant() {
            return Object.keys(this.groupedBuildingPlaces)
                .reduce((acc, roomId) => {
                    const group = this.groupedBuildingPlaces[roomId];
                    if (group && group.length === 1) {
                        acc.push({
                            roomId,
                            participantAppellation: _.get(group, '0.relationships.participants.0.attributes.appellation')
                        });
                    }
                    return acc;
                }, []);
        },

        roomsWithSingleParticipantCount() {
            return this.roomsWithSingleParticipant.length;
        },

        buildingId() {
            return this.$prop('building.id');
        },

        isReady() {
            return !!this.building;
        },

        hasDescription() {
            return !!this.$prop('building.attributes.description');
        },

        title() {
            return `Корпус ${this.$prop('building.attributes.title')}`;
        },

        rooms() {
            return this.$prop('building.relationships.rooms');
        },

        roomCategoriesInfo() {
            const info = [];
            const groups = _.groupBy(this.rooms, 'attributes.room_category_id');
            const keys = Object.keys(groups);
            keys.forEach(key => {
                info.push({
                    count: groups[key].length,
                    category: _.get(groups[key], '0.relationships.category.attributes.title')
                });
            });
            return info;
        }
    },

    methods: {
        _select() {
            this.$emit('select', {
                building: this.building,
                rowIndex: this.rowIndex
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.building-card-small {
  max-width: 220px;
  transition: all .3s ease-in-out;
  font-family: "PT Sans", sans-serif !important;
  color: #000 !important;

  &:hover {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }

  .building-card {
    cursor: pointer;
    min-height: 300px;

    &--selected {
      border-color: darken($color-primary-bg--light, 5%);
      margin-top: 10px;

      .el-card__header {
        border-bottom-color: darken($color-primary-bg--light, 5%);
      }
    }

    &__description {
      background: #fff;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .2) inset;
      padding: 20px;
      border-radius: 0 0 4px 4px;
      margin: 20px -20px -20px -20px;
    }

    &__header {
      font-size: 24px;

      .el-row {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &__header2 {
      font-size: 18px;
      margin: 5px 0 0 0;
    }

    .el-card {
      &__body {
        background: #e6f1fc;
      }
    }
  }
}
</style>
