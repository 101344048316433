<template>
    <el-tabs
        v-model="currentTab"
        @tab-click="_select"
    >
        <el-tab-pane
            v-for="item in tabs"
            :key="item.route"
            :name="item.route"
            :label="item.title"
        />
    </el-tabs>
</template>

<script>
import { ROUTES } from '@/enums';
import { mapState } from 'vuex';
import ROLES from '@/modules/manager/enums/roles';

export default {
    name: 'EventServiceTabs',

    props: {
        tab: {
            type: String,
            default: ROUTES.EVENT.CARD.SERVICE.LIST
        }
    },

    data() {
        return {
            currentTab: null
        };
    },

    computed: {
        ...mapState('auth', [
            'manager'
        ]),

        tabs() {
            return this.userRole === ROLES.ADMIN ? this.tabsForAdmin : [];
        },

        userRole() {
            return this.$prop('manager.user.role');
        },

        tabsForAdmin() {
            return [
                {
                    title: 'Список услуг',
                    route: ROUTES.EVENT.CARD.SERVICE.LIST
                },
                {
                    title: 'Расписание услуг',
                    route: ROUTES.EVENT.CARD.SERVICE.SCHEDULE
                },
                {
                    title: 'Записать гостя на услугу',
                    route: ROUTES.EVENT.CARD.SERVICE.ADD_PARTICIPANT
                },
                {
                    title: 'Специалисты',
                    route: ROUTES.EVENT.CARD.SERVICE.SPECIALISTS
                }
            ];
        }
    },

    mounted() {
        this.currentTab = this.tab;
    },

    methods: {
        _select(tab) {
            this.$router.push({
                name: tab.name
            });
        }
    }
};
</script>
