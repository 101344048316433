<template>
    <div class="place-link">
        <div
            v-if="hasParticipants"
            class="place-link__participants"
        >
            <div
                v-for="participant in participants"
                :key="participant.id"
                class="place-link__participant"
            >
                <router-link :to="participantLink(participant.id)">
                    {{ participant.attributes.appellation }}
                    <span class="place-link__age">{{ participant.attributes.age }}</span>
                </router-link>
            </div>
        </div>
        <template v-else>
            <div v-if="isRoomRedeemed">
                {{ placeType }}
            </div>
            <div
                v-else
                class="place-link__is-free"
                @click="_checkIn"
            >
                {{ placeType }}
            </div>
        </template>
    </div>
</template>

<script>
import { ROUTES } from '@/enums';

export default {
    name: 'PlaceLink',

    props: {
        /**
         * @type {PlaceResource}
         */
        place: {
            type: Object,
            default: null
        },

        /**
         * @type {ParticipantResource[]}
         */
        participantsToCheckIn: {
            type: Array,
            default: () => []
        },
        isRoomRedeemed: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasParticipants() {
            return !!this.participants.length;
        },

        participants() {
            return this.$prop('place.relationships.participants', []);
        },

        isSecondary() {
            return this.$prop('place.attributes.is_secondary', false);
        },

        placeType() {
            return this.isSecondary ? 'Дополнительное место' : 'Место свободно';
        }

    },

    methods: {

        participantLink(participantId) {
            return {
                name: ROUTES.EVENT.PARTICIPANT.CARD,
                params: {
                    eventId: this.$route.params.eventId,
                    participantId
                }
            };
        },

        _checkIn() {
            if (!this.participantsToCheckIn || !this.participantsToCheckIn.length) {
                this.$message('Сначала выберите участника для заселения');
                return;
            }
            this.$emit('checkIn', {
                place: this.place,
                participants: this.participantsToCheckIn
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.place-link {
    &__participants {
        margin: .5em 0;
    }

    &__participant {
        font-family: PT Sans;
        font-size: 14px;
        line-height: 1.3em;
        a {
            color: #000000;
            &:hover {
                color: $color-primary;
                text-decoration: none;
            }
        }
    }

    &__age {
        display: inline-block;
        font-size: .8em;
        position: relative;
        top: -.3em;
    }

    &__is-free {
        text-decoration: underline;
        cursor: pointer;
        font-family: PT Sans;
        font-size: 14px;
        margin: .5em 0;

        &:hover {
            color: $color-primary;
            text-decoration: none;
        }
    }
}
</style>
