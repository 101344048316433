<template>
    <el-dialog
        :title="title"
        :visible.sync="isVisible"
        :close-on-click-modal="false"
        width="60%"
        @close="_onDialogClose"
    >
        <el-form
            ref="form"
            v-loading="loading"
            :rules="rules"
            :model="form"
            label-position="top"
            @submit.native.prevent="_send"
        >
            <el-row :gutter="16">
                <el-col :span="18">
                    <el-form-item
                        label="Услуга"
                        prop="service_id_or_title"
                    >
                        <el-select
                            v-model="form.service_id_or_title"
                            filterable
                            allow-create
                            default-first-option
                            placeholder="Введите название услуги"
                            style="width: 100%;"
                        >
                            <el-option
                                v-for="service in servicesSelectData"
                                :key="service.serviceId"
                                :label="service.title"
                                :value="service.serviceId"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item
                        label="Метка для группировки"
                        prop="group"
                    >
                        <el-input v-model="form.group" />
                    </el-form-item>
                </el-col>
            </el-row>

            <p>
                Ниже указываются данные по умолчанию.
                Позже, создавая расписание, менеджер может указать на конкретное время
                других специалиста, место или стоимость.
            </p>

            <el-form-item
                label="Специалист"
                prop="default_specialist_id"
            >
                <el-select
                    v-model="form.default_specialist_id"
                    filterable
                    placeholder="Укажите специалиста"
                    style="width: 100%;"
                >
                    <el-option
                        v-for="specialist in specialistsSelectData"
                        :key="specialist.specialistId"
                        :label="specialist.specialistName"
                        :value="specialist.specialistId"
                    />
                </el-select>
            </el-form-item>

            <el-form-item
                label="Место проведения"
                prop="default_location_id_or_title"
            >
                <el-select
                    v-model="form.default_location_id_or_title"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="Укажите место проведения"
                    style="width: 100%;"
                >
                    <el-option
                        v-for="location in locationsSelectData"
                        :key="location.locationId"
                        :label="location.locationTitle"
                        :value="location.locationId"
                    />
                </el-select>
            </el-form-item>

            <event-service-plan-form-item
                v-for="(plan, index) in form.plans"
                :key="index + plan.title + plan.default_price"
                v-model="form.plans[index]"
                :index="index"
                :is-first="index === 0"
                :is-last="index === form.plans.length - 1"
                @remove="_onPlanRemove(index, plan)"
            />

            <el-form-item>
                <el-button @click="_addFirstPlan">
                    Добавить тип участия
                </el-button>
            </el-form-item>

            <el-button
                type="primary"
                @click="_send"
            >
                {{ buttonText }}
            </el-button>

            <el-button
                type="text"
                @click="_cancel"
            >
                Отмена
            </el-button>
        </el-form>
    </el-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import showError from '@/utils/showError';
import { Event } from '@/api';
import EventServicePlanFormItem from '@/modules/event/components/card/service/EventServicePlanFormItem';

const emptyPlan = {
    title: 'Стоимость',
    default_description: null,
    default_price: 0,
    default_places: 1
};

const emptyForm = {
    service_id_or_title: null,
    default_specialist_id: null,
    default_location_id_or_title: null,
    event_id: null,
    plans: [],
    group: null
};

export default {
    name: 'EventServiceForm',

    components: { EventServicePlanFormItem },

    props: {},

    data() {
        return {
            /** @type {AddServiceToEventRequest} */
            form: { ...emptyForm },
            loading: false,
            isVisible: false
        };
    },

    computed: {

        ...mapState('event/service', [
            /** @type {EventServiceResource[]} */
            'services',
            /** @type {ServiceResource[]} */
            'allServices',
            'locations',
            'specialists',
            /** @type {EventServiceResource | null} */
            'eventServiceToEdit'
        ]),

        emptyPlans() {
            return !this.form || !this.form.plans || !this.form.plans.length;
        },

        eventId() {
            return this.$route.params.eventId;
        },

        servicesSelectData() {
            /** @param {ServiceResource} service */
            const mapper = service => {
                return {
                    title: service.attributes.title,
                    serviceId: service.id
                };
            };
            return this.allServices.map(mapper);
        },

        specialistsSelectData() {
            /** @param {EventSpecialistResource} resource */
            const mapper = resource => {
                return {
                    specialistName: _.get(resource, 'relationships.specialist.attributes.name'),
                    specialistId: _.get(resource, 'attributes.specialist_id')
                };
            };
            return this.specialists.map(mapper);
        },

        locationsSelectData() {
            /** @param {EventLocationResource} resource */
            const mapper = resource => {
                return {
                    locationTitle: resource.attributes.title,
                    locationId: resource.id
                };
            };
            return this.locations.map(mapper);
        },

        eventServiceToEditId() {
            return this.$prop('eventServiceToEdit.id', null);
        },

        eventServiceToEditTitle() {
            return this.$prop('eventServiceToEdit.attributes.service_title');
        },

        title() {
            return this.isEditMode
                ? `Редактирование услуги "${this.eventServiceToEditTitle}"`
                : 'Добавление новой услуги';
        },

        buttonText() {
            return this.isEditMode
                ? `Сохранить`
                : `Добавить`;
        },

        rules() {
            return {
                service_id_or_title: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите услугу'
                    }
                ]
            };
        },

        eventServiceId() {
            return _.get(this, 'eventServiceToEdit.id');
        },

        isEditMode() {
            return !!this.eventServiceId;
        }
    },

    watch: {
        isVisible(isVisible) {
            if (isVisible) {
                this.loading = true;
                this._prepareData()
                    .then(() => {
                        this._fillFormFromEventServiceToEdit();
                        this.loading = false;
                    });
            }
        }
    },

    methods: {
        ...mapActions('event/service', [
            'getServices',
            'getAllServices',
            'getEventSpecialists',
            'getLocations'
        ]),

        open() {
            this.isVisible = true;
        },

        close() {
            this.isVisible = false;
        },

        _addFirstPlan() {
            this._addPlan({ ...emptyPlan });
        },

        _onPlanRemove(index, plan) {
            this.$confirm('Удалить?')
                .then(() => {
                    if (!plan || !plan.id) {
                        this._removePlanByIndex(index);
                    } else {
                        this._removePlan(plan)
                            .then(response => {
                                if (response && response.success) {
                                    this._removePlanByIndex(index);
                                    this.$message.success('Удалено успешно');
                                } else {
                                    this.$message.error('Не удалось удалить');
                                }
                            })
                            .catch(showError('Не удалось удалить'));
                    }
                })
                .catch(() => {
                });
        },

        /**
         * @param {{id:UUID}} plan
         * @private
         */
        _removePlan(plan) {
            return Event.Service.Plan.removeServicePlan({
                eventId: this.eventId,
                eventServiceId: this.eventServiceToEditId,
                planId: plan.id
            });
        },

        _removePlanByIndex(index) {
            this.form.plans.splice(index, 1);
        },

        _addPlan(plan) {
            this.form.plans.push(plan);
        },

        _fillFormFromEventServiceToEdit() {
            /** @type {EventServiceResource | null} */
            const service = this.eventServiceToEdit;
            if (!service) {
                this.form = { ...emptyForm };
                this.form.plans = [];
                return;
            }
            /** @type {AddServiceToEventRequest} */
            this.form = {
                ...emptyForm,
                default_location_id_or_title: _.get(service, 'attributes.location_id', null),
                default_specialist_id: _.get(service, 'attributes.specialist_id', null),
                event_id: _.get(service, 'attributes.event_id', null),
                service_id_or_title: _.get(service, 'attributes.service_id', null),
                // todo: plans
                plans: this._extractPlansFromEventService(service),
                group: _.get(service, 'attributes.group')
            };
        },

        /**
         * @param {EventServiceResource} service
         * @private
         */
        _extractPlansFromEventService(service) {
            const plans = _.get(service, 'relationships.plans', []);
            /** @param {EventServicePlanResource} plan */
            const mapper = plan => {
                return {
                    id: plan.id,
                    title: plan.attributes.title,
                    default_description: plan.attributes.default_description,
                    default_places: plan.attributes.default_places,
                    default_price: plan.attributes.default_price
                };
            };
            return plans.map(mapper);
        },

        _prepareData() {
            if (!this.eventId) {
                return;
            }
            return Promise.all([
                this._loadSpecialists(),
                this._loadEventLocations(),
                this._loadAllServices()
            ]);
        },

        _loadAllServices() {
            return this.getAllServices();
        },

        _loadSpecialists() {
            this.getEventSpecialists({
                eventId: this.eventId
            });
        },

        _loadEventLocations() {
            return this.getLocations({
                eventId: this.eventId
            });
        },

        _onDialogClose() {
            this._resetForm();
        },

        _resetForm() {
            this.form = { ...emptyForm };
            this.form.plans = [];
        },

        _send() {
            this.$refs.form.validate()
                .then(() => this._doSend())
                .catch(showError('Проверьте форму'));
        },

        _doSend() {
            this.loading = true;
            if (this.isEditMode) {
                return this._sendEditRequest();
            } else {
                return this._sendCreateRequest();
            }
        },

        _sendCreateRequest() {
            /**
             * @param {EventServiceResource} eventService
             */
            const onStoreResponse = eventService => {
                this.$emit('added', { eventService });
                this.close();
            };
            const setLoadingFalse = () => (this.loading = false);
            /** @type {AddServiceToEventRequest} */
            const request = this._formToCreateRequest();
            Event.Service.addServiceToEvent({
                eventId: this.eventId,
                request
            })
                .then(onStoreResponse)
                .catch(showError('Не удалось добавить услугу'))
                .finally(setLoadingFalse);
        },

        _sendEditRequest() {
            /** @type {UpdateEventServiceRequest} */
            const request = this._formToUpdateRequest();
            /**
             * @param {EventServiceResource} service
             */
            const onUpdateResponse = service => {
                this.$emit('updated', { service });
                this.close();
            };
            const setLoadingFalse = () => (this.loading = false);
            return Event.Service.updateEventService({
                eventId: this.eventId,
                eventServiceId: this.eventServiceToEditId,
                request
            })
                .then(onUpdateResponse)
                .catch(showError('Не удалось обновить услугу'))
                .finally(setLoadingFalse);
        },

        /**
         * @returns {AddServiceToEventRequest}
         * @private
         */
        _formToCreateRequest() {
            return {
                ...this.form,
                ...{
                    event_id: this.eventId
                }
            };
        },

        /**
         * @returns {UpdateEventServiceRequest}
         * @private
         */
        _formToUpdateRequest() {
            return this._formToCreateRequest();
        },

        _cancel() {
            this._resetForm();
            this.close();
        }
    }
};
</script>
