<template>
    <div
        class="event-hotel-info-map-marker"
        :style="{left: building.x + 'px', top: building.y + 'px'}"
    >
        <el-popover
            trigger="hover"
            placement="right"
        >
            <building-card-small
                :building="building"
                @select="_onSelectPopover"
            />
            <span
                slot="reference"
                class="event-hotel-info-map-marker__number"
                @click="_onSelect(building)"
            >
                {{ title }}
            </span>
        </el-popover>
    </div>
</template>

<script>
import BuildingCardSmall from '@/modules/event/components/card/hotel/BuildingCardSmall';
export default {
    name: 'HotelMapMarker',
    components: { BuildingCardSmall },
    props: {
        building: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        title() {
            return this.building ? this.building.attributes.title : '';
        }
    },
    methods: {
        _onSelect(building) {
            this.$emit('select', { building });
        },
        _onSelectPopover(data) {
            this.$emit('select', data);
        }
    }
};
</script>

<style lang="scss">
.event-hotel-info-map-marker {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #A3D0FD;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: absolute;
  &:hover {
    border-color: #1871F8;
  }
  &__number {
    font-family: "PT Sans", sans-serif;
    font-size: 18px;
    width:100%;
    color: #000000;
    text-align: center;
  }
}
</style>
