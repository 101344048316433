<template>
    <el-dialog
        title="Расписание"
        :visible.sync="isVisible"
        width="60%"
        :close-on-click-modal="false"
        @close="_onDialogClose"
        @open="_onDialogOpen"
    >
        <div class="schedule-form">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-position="top"
                @submit.native.prevent
            >
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item
                            label="Даты"
                            prop="dates"
                        >
                            <el-date-picker
                                v-model="form.dates"
                                type="daterange"
                                range-separator="—"
                                start-placeholder="С"
                                end-placeholder="По"
                                :picker-options="dateOptions"
                                value-format="yyyy-MM-dd"
                                :format="datepickerDateFormat"
                                :default-value="eventStartsAt"
                                style="width: 100%;"
                                placeholder="Выберите дату"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item
                            label="Место проведения"
                            prop="event_location_id"
                        >
                            <el-select
                                v-model="form.event_location_id"
                                style="width: 100%;"
                            >
                                <el-option
                                    v-for="location in locations"
                                    :key="location.id"
                                    :value="location.id"
                                    :label="location.attributes.title"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row
                    style="margin-top: 0; margin-bottom: 0;"
                    :gutter="16"
                >
                    <el-col :span="5">
                        <el-form-item
                            style="margin-top: 0; margin-bottom: 0;"
                            label="Время"
                        />
                    </el-col>
                    <el-col :span="5">
                        <el-form-item
                            style="margin-top: 0; margin-bottom: 0;"
                            label="Прод-ть"
                        />
                    </el-col>
                </el-row>

                <el-row
                    v-for="(time, timeIndex) in form.times"
                    :key="timeIndex + time.time"
                    style="margin-top: 0; margin-bottom: 0;"
                    :gutter="16"
                >
                    <el-col :span="5">
                        <schedule-time-select v-model="form.times[timeIndex].time" />
                        <br>
                    </el-col>
                    <el-col :span="5">
                        <el-input
                            v-model="form.times[timeIndex].duration"
                            type="number"
                        >
                            <div
                                slot="suffix"
                                style="line-height: 38px;"
                            >
                                мин
                            </div>
                        </el-input>
                        <br>
                    </el-col>
                    <el-col :span="1">
                        <el-button
                            v-if="timeIndex === form.times.length - 1"
                            type="text"
                            @click="_addTime"
                        >
                            <div class="el-icon-plus" />
                        </el-button>
                        <el-button
                            v-else
                            type="text"
                            @click="_dropTime(timeIndex)"
                        >
                            <div class="el-icon-close" />
                        </el-button>
                    </el-col>
                    <el-col :span="13">
                        <el-alert
                            v-if="timeErrors[timeIndex]"
                            :closable="false"
                            :title="timeErrors[timeIndex]"
                            style="margin: 0 0 4px 0;"
                            type="error"
                        />
                        <el-alert
                            v-if="timeWarnings[timeIndex]"
                            :closable="false"
                            :title="timeWarnings[timeIndex]"
                            style="margin: 0 0 4px 0;"
                            type="warning"
                        />
                    </el-col>
                </el-row>

                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item
                            label="Специалист"
                            prop="main_specialist_id"
                        >
                            <el-select v-model="form.main_specialist_id">
                                <el-option
                                    v-for="specialist in specialistsPrepared"
                                    :key="specialist.id"
                                    :value="specialist.id"
                                    :label="specialist.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item
                            label="Услуга"
                            prop="service_id"
                        >
                            <el-select
                                v-model="form.service_id"
                                style="width: 100%;"
                            >
                                <el-option
                                    v-for="service in servicesPrepared"
                                    :key="service.id"
                                    :value="service.id"
                                    :label="service.title"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <event-service-plan-form-item
                    v-for="(plan, index) in form.plans"
                    :key="index + plan.title + plan.default_price"
                    v-model="form.plans[index]"
                    :index="index"
                    :is-first="index === 0"
                    :is-last="index === form.plans.length - 1"
                    @remove="_onPlanRemove(index, plan)"
                />

                <el-form-item prop="plans">
                    <el-button @click="_addFirstPlan">
                        Добавить тип участия
                    </el-button>
                </el-form-item>

                <el-button
                    type="primary"
                    @click="_send"
                >
                    Добавить
                </el-button>
                <el-button @click="_cancel">
                    Отмена
                </el-button>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { Event } from '@/api';
import ScheduleTimeSelect from '@/modules/event/components/card/service/schedule/ScheduleTimeSelect';
import showError from '@/utils/showError';
import EventServicePlanFormItem from '@/modules/event/components/card/service/EventServicePlanFormItem';

const defaultTime = {
    time: '09:00',
    duration: 60
};

const emptyPlan = {
    title: 'Обычный участник',
    default_description: null,
    default_price: 0,
    default_places: 1
};

const emptyForm = {
    times: [
        { ...defaultTime }
    ],
    plans: []
};

export default {
    name: 'ScheduleForm',

    components: {
        EventServicePlanFormItem,
        ScheduleTimeSelect
    },

    data() {
        return {
            isVisible: false,
            /** @type {EventServiceResource | null} */
            eventService: null,
            form: { ...emptyForm },
            timeErrors: [],
            timeWarnings: []
        };
    },

    computed: {
        ...mapState('event', {
            event: 'resource'
        }),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('event/service', [
            /** @type {EventServiceResource[]} */
            'services',
            /** @type {EventSpecialistResource[]} */
            'specialists',
            'locations'
        ]),

        ...mapState('event/service/schedule', [
            /** @type {SpecialistResource} */
            'selectedSpecialist',
            /** @type {ServiceResource} */
            'selectedService'
        ]),

        rules() {
            return {
                date: [
                    {
                        required: true,
                        message: 'Укажите дату расписания'
                    }
                ],
                event_location_id: [
                    {
                        required: true,
                        message: 'Укажите место проведения'
                    }
                ],
                main_specialist_id: [
                    {
                        required: true,
                        message: 'Укажите специалиста'
                    }
                ],
                service_id: [
                    {
                        required: true,
                        message: 'Укажите услугу'
                    }
                ],
                plans: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!value || !value.length) {
                                return callback(new Error('Укажите хотя бы один тип участия'));
                            }
                            const error = value.reduce((acc, plan) => {
                                if (acc) {
                                    return acc;
                                }
                                if (plan.default_price < 0) {
                                    return new Error('Цена не может быть меньше 0');
                                }
                                if (plan.default_places < 1) {
                                    return new Error('Мест не может быть меньше 1');
                                }
                                if (!plan.title) {
                                    return new Error('Укажите название типа участия');
                                }
                                return null;
                            }, null);
                            if (error) {
                                return callback(error);
                            }
                            callback();
                        }
                    }
                ]
            };
        },

        /**
         * @return {DateObject}
         */
        eventStartsAt() {
            return this.$prop('event.attributes.start_at');
        },

        /**
         * @return {DateObject}
         */
        eventEndsAt() {
            return this.$prop('event.attributes.end_at');
        },

        dateOptions() {
            return {
                disabledDate: function(date) {
                    const startsAt = moment(this.eventStartsAt);
                    const endsAt = moment(this.eventEndsAt);
                    return !moment(date)
                        .isBetween(startsAt, endsAt);
                }.bind(this)
            };
        },

        specialistId() {
            return this.$prop('selectedSpecialist.id');
        },

        serviceId() {
            return this.$prop('selectedService.id');
        },

        eventId() {
            return this.$route.params.eventId;
        },

        specialistsPrepared() {
            return this.specialists.map(spec => {
                return {
                    id: _.get(spec, 'relationships.specialist.id'),
                    name: _.get(spec, 'relationships.specialist.attributes.name')
                };
            });
        },

        /**
         * @returns {EventServiceResource}
         */
        formSelectedService() {
            return (this.services || []).find(service => service.attributes.service_id === this.form.service_id);
        },

        /**
         * @returns {EventServicePlanResource[]}
         */
        formSelectedServicePlans() {
            return this.$prop('formSelectedService.relationships.plans', []);
        },

        servicesPrepared() {
            return this.services.map(service => {
                return {
                    id: _.get(service, 'attributes.service_id'),
                    title: _.get(service, 'attributes.service_title')
                };
            });
        }
    },

    watch: {
        selectedService() {
            this._loadEventService();
        },

        eventService() {
            this._setFormEventServiceDefaultData();
        },

        serviceId() {
            this._setFormService();
        },

        eventId() {
            this._loadData();
        },

        formSelectedServicePlans() {
            this._fillFormPlans();
        },

        form: {
            deep: true,
            handler: function() {
                this._check();
            }
        }
    },

    methods: {
        ...mapActions('event/service', [
            'getLocations',
            'getServices',
            'getEventSpecialists'
        ]),

        /**
         * @param {moment | null} date
         * @param {ScheduleInfoResource[] | null} day
         */
        open(date = null, day = null) {
            if (date) {
                this.form.date = date.format('Y-MM-DD');
            }
            this.isVisible = true;
        },

        close() {
            this.$emit('close');
            this.isVisible = false;
        },

        _addFirstPlan() {
            this._addPlan({ ...emptyPlan });
        },

        _onPlanRemove(index, plan) {
            this.$confirm('Удалить?')
                .then(() => {
                    this._removePlanByIndex(index);
                })
                .catch(() => {
                });
        },

        _removePlanByIndex(index) {
            this.form.plans.splice(index, 1);
        },

        _addPlan(plan) {
            this.form.plans.push(plan);
        },

        _fillFormPlans() {
            const plans = this.formSelectedServicePlans;
            /**
             * @param {EventServicePlanResource} plan
             */
            const mapper = plan => {
                return {
                    title: plan.attributes.title,
                    default_description: plan.attributes.default_description,
                    default_places: plan.attributes.default_places,
                    default_price: plan.attributes.default_price,
                    id: plan.attributes.id
                };
            };
            this.form.plans = plans.map(mapper);
        },

        _setFormService() {
            this.form.service_id = this.serviceId;
        },

        _setFormEventServiceDefaultData() {
            if (this.eventService) {
                this.form.event_location_id = this.eventService.attributes.default_event_location_id;
            }
        },

        _loadData() {
            if (!this.eventId) {
                return;
            }
            return Promise.all([
                this._loadLocations(),
                this._loadEventSpecialists(),
                this._loadEventServices(),
                this._loadEventService()
            ]);
        },

        _loadEventService() {
            if (!this.selectedService) {
                return;
            }
            return Event.Service.getEventService({
                eventId: this.eventId,
                serviceId: this.serviceId
            })
                .then(eventService => {
                    this.eventService = eventService;
                })
                .catch(showError('Не удалось получить информацию об услуге'));
        },

        _loadLocations() {
            return this.getLocations({
                eventId: this.eventId
            });
        },

        _loadEventSpecialists() {
            return this.getEventSpecialists({
                eventId: this.eventId
            });
        },

        _loadEventServices() {
            return this.getServices({
                eventId: this.eventId
            });
        },

        /**
         * @return {CreateScheduleRequest}
         * @private
         */
        _buildRequest() {
            return {
                service_id: this.form.service_id,
                main_specialist_id: this.form.main_specialist_id,
                date: this.form.date,
                event_location_id: this.form.event_location_id,
                times: this.form.times,
                plans: this.form.plans.map(plan => ({
                    event_service_plan_id: plan.id,
                    price: plan.default_price,
                    places: plan.default_places,
                    description: plan.default_description,
                    title: plan.title
                }))
            };
        },

        _check: _.debounce(function() {
            const request = this._buildRequest();
            if (!request.date ||
                !request.times ||
                !request.times.length ||
                !request.main_specialist_id) {
                return;
            }
            /**
             * @param {{
             *  results: {
             *      success: Boolean,
             *      schedule: Object,
             *      status: {errorMessage, error}
             *  }[],
             *  created: Number,
             *  failed: Number,
             *  success: Boolean,
             * }} response
             */
            const onResponse = response => {
                this.timeErrors = [];
                this.timeWarnings = [];
                if (response && response.success) {
                    this.$emit('checked', response);
                } else {
                    const message = 'Проверьте расписание';
                    this.$message.warning(message);
                    this.$emit('checked', response);
                    this._convertCheckResponseToTimeMessages(response.results);
                }
            };
            Event.Service.Schedule.checkScheduleStoreRequest({
                eventId: this.eventId,
                request
            })
                .then(onResponse)
                .catch(() => {
                    this.timeErrors = [];
                    this.timeWarnings = [];
                });
        }, 300),

        _cancel() {
            this.close();
        },

        _send() {
            if (this.sendInProgress) {
                return;
            }
            this.$refs.form.validate()
                .then(this._doSend.bind(this))
                .catch(() => {
                    this.$message.warning('Проверьте форму');
                });
        },

        _doSend() {
            if (this.sendInProgress) {
                return;
            }
            this.sendInProgress = true;
            this.timeWarnings = [];
            this.timeErrors = [];
            /**
             * @param {{
             *  results: {
             *      success: Boolean,
             *      schedule: Object,
             *      status: {errorMessage, error}
             *  }[],
             *  created: Number,
             *  failed: Number,
             *  success: Boolean,
             * }} response
             */
            const onResponse = response => {
                if (response && response.success) {
                    const message = `Добавлено расписаний: ${response.created}`;
                    this.$message.success(message);
                    this.$emit('created');
                    this.close();
                } else {
                    const message = 'Не удалось создать расписание';
                    this.$message.error(message);
                    this.$emit('failed');
                    this._convertErrorResponseToTimeMessages(response.results);
                }
            };
            Event.Service.Schedule.createSchedule({
                eventId: this.eventId,
                request: this._buildRequest()
            })
                .then(onResponse)
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось создать расписание');
                    this.$message.error(message);
                })
                .finally(() => {
                    this.sendInProgress = false;
                });
        },

        /**
         * @param {{time: {}, status: {}}[]} errors
         * @private
         */
        _convertCheckResponseToTimeMessages(errors) {
            /**
             * @param {{duration, time}} time
             * @return {string | null}
             */
            const mapper = time => {
                const errorForThisTime = errors.find(x => x.time.time === time.time);
                return errorForThisTime ? errorForThisTime.status.errorMessage : null;
            };
            this.timeWarnings = this.form.times.map(mapper);
        },

        /**
         * @param {{time: {}, status: {}}[]} errors
         * @private
         */
        _convertErrorResponseToTimeMessages(errors) {
            /**
             * @param {{duration, time}} time
             * @return {string | null}
             */
            const mapper = time => {
                const errorForThisTime = errors.find(x => x.time.time === time.time);
                return errorForThisTime ? errorForThisTime.status.errorMessage : null;
            };
            this.timeErrors = this.form.times.map(mapper);
        },

        _onDialogOpen() {
            this._loadData().then(() => {
                let form = { ...this.form };
                if (this.specialistId) {
                    form = { ...form, ...{ main_specialist_id: this.specialistId } };
                }
                if (this.serviceId) {
                    form = { ...form, ...{ service_id: this.serviceId } };
                }
                this.form = form;
            });
        },

        _onDialogClose() {
            this._resetForm();
        },

        _resetForm() {
            this.form = { ...emptyForm };
            this.form.times = [
                { ...defaultTime }
            ];
            this.timeErrors = [];
            this.timeWarnings = [];
            this.$refs.form.clearValidate();
        },

        _addTime() {
            this.form.times.push({ ...defaultTime });
        },

        _dropTime(index) {
            this.$confirm('Вы уверены?')
                .then(() => {
                    this.form.times.splice(index, 1);
                })
                .catch(() => {
                });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.schedule-form {
}

</style>
