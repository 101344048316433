<template>
    <div
        v-loading="isLoading"
        class="event-add-view"
    >
        <event-card-tabs />
        <event-card-full :event-id-to-load="routerEventId" />
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import EventCardFull from '@/modules/event/components/card/EventCardFull';
import { ROUTES } from '@/enums';
import EventCardTabs from '@/modules/event/components/common/EventCardTabs';

export default {
    name: 'EventCardView',

    components: {
        EventCardTabs,
        EventCardFull
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('event', [
            'resource',
            'activeTabRoute',
            'isLoading'
        ]),

        tab() {
            return this.$route.meta.tab;
        },

        title() {
            return this.$prop('resource.attributes.title');
        },

        $breadcrumbs() {
            const bc = [
                ['События', ROUTES.EVENT.LIST]
            ];
            if (this.title) {
                // bc.push([this.title, this.tab, { eventId: this.routerEventId }]);
                bc.push([this.title, ROUTES.EVENT.CARD.PARTICIPANTS, { eventId: this.routerEventId }]);
            }
            return bc;
        },

        routerEventId() {
            return this.$route.params.eventId;
        }
    },

    watch: {
        tab: {
            handler() {
                this._onTabChange();
            },
            immediate: true
        }
    },

    methods: {
        ...mapMutations('event', [
            'setActiveTabRoute'
        ]),

        _onTabChange() {
            this.setActiveTabRoute({ activeTabRoute: this.tab });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
