<template>
    <div class="event-services">
        <event-service-tabs :tab="serviceTab" />
        <component
            :is="component"
            v-bind="payload"
        />
    </div>
</template>

<script>
import { ROUTES } from '@/enums';
import EventServiceTabs from '@/modules/event/components/card/service/EventServiceTabs';
import EventServiceSchedule from '@/modules/event/components/card/service/EventServiceSchedule';
import EventServiceList from '@/modules/event/components/card/service/EventServiceList';
import EventServiceSpecialists from '@/modules/event/components/card/service/EventServiceSpecialists';
import EventServiceAddParticipant from '@/modules/event/components/card/service/EventServiceAddParticipant';

export default {
    name: 'EventServices',

    components: {
        EventServiceTabs,
        EventServiceSchedule,
        EventServiceList,
        EventServiceSpecialists,
        EventServiceAddParticipant
    },

    computed: {
        eventId() {
            return this.$route.params.eventId;
        },

        serviceTab() {
            return this.$route.meta.serviceTab;
        },

        component() {
            switch (this.serviceTab) {
            case ROUTES.EVENT.CARD.SERVICE.SCHEDULE:
                return 'EventServiceSchedule';
            case ROUTES.EVENT.CARD.SERVICE.ADD_PARTICIPANT:
                return 'EventServiceAddParticipant';
            case ROUTES.EVENT.CARD.SERVICE.SPECIALISTS:
                return 'EventServiceSpecialists';
            default:
                return 'EventServiceList';
            }
        },

        payload() {
            switch (this.serviceTab) {
            case ROUTES.EVENT.CARD.SERVICE.SCHEDULE:
                return {
                    eventId: this.eventId
                };
            case ROUTES.EVENT.CARD.SERVICE.SPECIALISTS:
                return {
                    eventId: this.eventId
                };
            default:
                return {
                    eventId: this.eventId
                };
            }
        }
    },

    mounted() {
    },

    methods: {}
};
</script>

<style lang="scss">
@import "@vars";

</style>
