<template>
    <div
        class="schedule-event"
        @dblclick="_doDblclick"
    >
        <schedule-event-plan
            v-for="plan in plans"
            :key="plan.id"
            :schedule-info="scheduleInfo"
            :plan="plan"
            :is-title-visible="isPlanTitleVisible"
            :is-registration-mode="isRegistrationMode"
            :is-time-already-taken="isTimeAlreadyTaken"
            :active="plan.id === participantSchedulePlanId"
            :taken-by-participant-schedules="takenByParticipantSchedules"
            :service-title="serviceTitle"
            :is-service-title-visible="isServiceTitleVisible"
            @click="_onPlanClick"
            @taken-participant-hover="_onPlanClick"
            @remove="_onPlanRemove"
        />
    </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { EVENT } from '@/enums';
import money from '@/filters/money';
import get from 'lodash/fp/get';
import ScheduleEventPlan from '@/modules/event/components/card/service/schedule/ScheduleEventPlan';

export default {
    name: 'ScheduleEvent',
    components: { ScheduleEventPlan },
    props: {
        /** @type {ScheduleInfoResource} */
        scheduleInfo: {
            type: Object,
            default: null
        },

        isRegistrationMode: {
            type: Boolean,
            default: false
        },

        isServiceTitleVisible: {
            type: Boolean,
            default: true
        },

        /**
         * @type {Object<UUID, ScheduleResource>}
         */
        takenByParticipantSchedules: {
            type: Object,
            default: () => ({})
        },

        /**
         * @type {{start: moment.Moment, end: moment.Moment, service_id: UUID}[]}
         */
        takenTimesOfNotShownSchedules: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        ...mapState('event/service/schedule', [
            /** @type {ScheduleInfoResource[]} */
            'scheduleInfos',
            /** @type {ScheduleParticipantInfoResource | null} */
            'participantInfo',
            'scheduleIdToRemove',
            'scheduleRemoveInProgress',
            'mode'
        ]),

        /**
         * @returns {ScheduleResource[]}
         */
        participantSchedules() {
            return this.$prop('participantInfo.relationships.schedules', []);
        },

        isPlanTitleVisible() {
            return this.plans && this.plans.length > 1;
        },

        isTakenBySelectedParticipant() {
            return this.takenByParticipantSchedules &&
                !!this.takenByParticipantSchedules[this.scheduleId];
        },

        /**
         * Это время уже занято где-то в другом расписании.
         */
        isTimeAlreadyTaken() {
            if (this.isTakenBySelectedParticipant) {
                return false;
            }
            // ищем пересечения с другими временами
            /**
             * @param {Boolean} acc
             * @param {{
             *  start: moment.Moment,
             *  end: moment.Moment,
             *  service_id: moment.Moment
             * }} obj
             */
            const reducer = (acc, obj) => {
                if (acc) {
                    return acc;
                }
                return this.end.isBetween(obj.start, obj.end, undefined, '(]') ||
                    this.start.isBetween(obj.start, obj.end, undefined, '[)');
            };
            return this.takenTimesOfNotShownSchedules.reduce(reducer, false);
        },

        participantSchedulePlanId() {
            return this.$prop('participantSchedulePlan.id');
        },

        /**
         * Выбранный план участия.
         * @return {SchedulePlanResource | null}
         */
        participantSchedulePlan() {
            if (!this.isModeParticipant) {
                return null;
            }
            /**
             * @param {SchedulePlanResource} plan
             * @returns {Boolean}
             */
            const filter = plan => plan.attributes.schedule_id === this.scheduleId;
            return this.$prop('participantInfo.relationships.schedulePlans', [])
                .find(filter);
        },

        /**
         * @returns {SchedulePlanResource[]}
         */
        plans() {
            return this.$prop('scheduleInfo.relationships.schedule.relationships.plans', []);
        },

        isModeService() {
            return this.mode === EVENT.SCHEDULE.MODE.SERVICE;
        },

        isModeParticipant() {
            return this.mode === EVENT.SCHEDULE.MODE.PARTICIPANT;
        },

        isEditingAvailable() {
            return this.isModeService;
        },

        classes() {
            return {
                'schedule-event--removing': this.isRemoveInProcess
            };
        },

        isRemoveInProcess() {
            return this.scheduleId === this.scheduleIdToRemove && this.scheduleRemoveInProgress;
        },

        /**
         * @returns {ScheduleResource}
         */
        schedule() {
            return get('scheduleInfo.relationships.schedule', this);
        },

        scheduleId() {
            return get('schedule.id', this);
        },

        /**
         * @returns {ServiceResource}
         */
        service() {
            return get('scheduleInfo.relationships.service', this);
        },

        time() {
            let time = this.$prop('scheduleInfo.attributes.time');
            return time ? time.slice(0, 5) : time;
        },

        serviceTitle() {
            return get('service.attributes.title', this);
        },

        /**
         * @returns {moment.Moment}
         */
        start() {
            return moment(this.$prop('scheduleInfo.attributes.timestamp_start'));
        },

        /**
         * @returns {moment.Moment}
         */
        end() {
            return moment(this.$prop('scheduleInfo.attributes.timestamp_end'));
        },

        availablePlaces() {
            return this.$prop('schedule.meta.available_places');
        },

        takenPlaces() {
            return this.$prop('schedule.meta.taken_places');
        }
    },

    methods: {
        money,

        _doDblclick() {
            if (this.isEditingAvailable) {
                this.$emit('edit', {
                    scheduleInfo: this.scheduleInfo
                });
            }
            this.$emit('dblclick');
        },

        _doEmit(name, payload = {}) {
            this.$emit(name, { scheduleInfo: this.scheduleInfo, ...payload });
        },

        /**
         * @param {SchedulePlanResource} plan
         * @private
         */
        _onPlanClick({ plan }) {
            if (this.isRegistrationMode) {
                if (this.isTakenBySelectedParticipant) {
                    this._doEmit('unregister');
                } else {
                    this._selectPlan(plan);
                }
            } else if (this.isEditingAvailable) {
                this._doEmit('edit');
            }
            this._doEmit('click');
        },

        /**
         * @param {SchedulePlanResource} plan
         * @private
         */
        _onPlanRemove({ plan }) {
            this._doEmit('schedulePlanRemove', { plan });
        },

        /**
         * @param {SchedulePlanResource} plan
         * @private
         */
        _selectPlan(plan) {
            this._doEmit('register', { plan });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.schedule-event {
    position: relative;
    margin: 0 0 8px 0;

    .schedule-event-box {
        margin: 0 0 1px 0;

        &:first-child {
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;
        }

        &:first-child:last-child {
            border-radius: 4px;
        }
    }
}
</style>
