<template>
    <div>
        <schedule-plan-edit-form
            v-for="plan in plans"
            :key="plan.id"
            :schedule-plan="plan"
            :schedule="schedule"
        />
        <el-button
            :disabled="loading"
            @click="_addPlan"
        >
            Добавить тип участия
        </el-button>
    </div>
</template>

<script>
import showError from '@/utils/showError';
import { Event } from '@/api';
import SchedulePlanEditForm from '@/modules/event/components/card/service/schedule/SchedulePlanEditForm';
import { mapState } from 'vuex';

export default {
    name: 'SchedulePlansEditForm',
    components: { SchedulePlanEditForm },
    props: {
        /** @type {SchedulePlanResource[]} */
        plans: { type: Array, default: () => [] },
        /** @type {ScheduleResource | null} */
        schedule: { type: Object, default: null }
    },

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('event', {
            event: 'resource'
        }),

        eventId() {
            return this.$prop('event.id');
        },

        scheduleId() {
            return this.$prop('schedule.id', null);
        }
    },

    watch: {},

    methods: {
        _addPlan() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            /**
             * @param {SchedulePlanResource} schedulePlan
             */
            const onResponse = schedulePlan => {
                this.$emit('added', { schedulePlan });
            };
            Event.Service.Plan.addNewSchedulePlan({
                scheduleId: this.scheduleId,
                eventId: this.eventId
            })
                .then(onResponse)
                .catch(showError('Не удалось создать новый тип участия'))
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
