<template>
    <schedule />
</template>

<script>
import { mapMutations } from 'vuex';
import Schedule from '@/modules/event/components/card/service/schedule/Schedule';

export default {
    name: 'EventServiceSchedule',

    components: {
        Schedule
    },

    mounted() {
        this.setScheduleMode({ mode: 'service' });
    },

    methods: {
        ...mapMutations('event/service/schedule', [
            'setScheduleMode'
        ])
    }
};
</script>

<style lang="scss">
@import "@vars";
</style>
