<template>
    <el-dropdown @command="_handleDropdownCommand">
        <el-button
            class="event-transfer-info__top-button"
            size="default"
            type="text"
        >
            <i class="fas fa-print" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="arrival">
                Распечатать прибытие
            </el-dropdown-item>
            <el-dropdown-item command="departure">
                Распечатать отбытие
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import _ from 'lodash';
import JSONStorage from '@/utils/storage';
import { mapState } from 'vuex';
import { STATUSES } from '@/modules/event/components/card/transfer/config';
import api from '@/modules/event/api/transferParticipants';

export default {
    name: 'TransferListPrint',

    computed: {
        ...mapState('transfer', [
            'bookedFilter'
        ]),

        eventId() {
            return this.$route.params.eventId;
        }
    },

    methods: {
        _handleDropdownCommand(printType) {
            this._print(printType);
        },
        _getTransferList(params) {
            return api.getParticipantTransferList(this.eventId, params);
        },
        _print(printType) {
            const appliedFilter = {};
            const bookedFilter = { ...this.bookedFilter };
            Object.keys(bookedFilter).forEach(key => {
                if (_.isEmpty(bookedFilter[key])) {
                    return;
                }
                appliedFilter[_.snakeCase(key)] = bookedFilter[key];
            });
            let params = {
                status: STATUSES.BOOKED_STATUS,
                print: 1,
                ...appliedFilter
            };
            this._getTransferList(params)
                .then(response => {
                    JSONStorage.put('transferPrintData', response.data.data);
                    let printRoute = this.$router.resolve({
                        name: 'transfer.print'
                    });
                    window.open(`${printRoute.href}?mode=${printType}`, '_blank');
                })
                .catch(() => this.$message.error('Ошибка при формировании страницы печать'));
        }
    }

};
</script>

<style scoped>

</style>
