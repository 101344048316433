<template>
    <div
        v-if="isVisible"
        v-draggable="draggableOptions"
        class="participant-actions-popup"
        :style="style"
    >
        <el-card
            v-if="isCurrentParticipantsCard"
            shadow="always"
            :body-style="cardStyles"
        >
            <el-table
                width="100%"
                max-height="300"
                :data="participantsListPrepared"
            >
                <el-table-column
                    min-width="150px"
                >
                    <template slot-scope="scope">
                        {{ scope.row.fullname }}
                        <span class="event-participant-list__age"> {{ scope.row.age }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Скидка"
                    min-width="50px"
                >
                    <template slot-scope="scope">
                        {{ scope.row.discount.toLocaleString('ru-RU') }} ₽
                    </template>
                </el-table-column>

                <el-table-column
                    label="Доплата"
                    min-width="50px"
                >
                    <template slot-scope="scope">
                        {{ scope.row.debt.toLocaleString('ru-RU') }} ₽
                    </template>
                </el-table-column>

                <el-table-column min-width="30px">
                    <template slot-scope="scope">
                        <i
                            class="el-icon-close room-checkout"
                            @click="_removeParticipant(scope.row.id)"
                        />
                    </template>
                </el-table-column>
            </el-table>
            <el-table
                width="100%"
                max-height="300"
                :data="myData"
                :show-header="false"
            >
                <el-table-column min-width="150px" />

                <el-table-column min-width="50px">
                    <template slot-scope="scope">
                        {{ scope.row.discount.toLocaleString('ru-RU') }} ₽
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="80px"
                    prop="debt"
                >
                    <template slot-scope="scope">
                        <span
                            class="participant-actions-popup-totalrow__debt"
                        >
                            {{ scope.row.debt.toLocaleString('ru-RU') }} ₽
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="participant-actions-popup__toolbar">
                <el-row :gutter="24">
                    <el-col :span="18">
                        <el-button
                            size="default"
                            type="danger"
                            circle
                            plain
                            icon="el-icon-close"
                            @click="_close"
                        />
                        <el-dropdown
                            trigger="click"
                            class="participant-actions-popup__dropdown"
                            @command="_onEventsToMoveChanged"
                        >
                            <el-button
                                size="default"
                                type="primary"
                                plain
                            >
                                <i class="fas fa-helicopter" /> <i class="el-icon-arrow-down el-icon--right" />
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="event in eventsToMove"
                                    :key="event.id"
                                    :command="event.id"
                                >
                                    {{ event.title }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown
                            trigger="click"
                            class="participant-actions-popup__dropdown"
                            @command="_onStatusChanged"
                        >
                            <el-button
                                size="default"
                                type="primary"
                                plain
                            >
                                Статус <i class="el-icon-arrow-down el-icon--right" />
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="status in paricipantStatuses"
                                    :key="status.id"
                                    :command="status.id"
                                >
                                    {{ status.title }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button
                            size="default"
                            type="primary"
                            plain
                            @click="_onCheckInClick"
                        >
                            <i class="far fa-building" />
                        </el-button>
                        <el-button
                            size="default"
                            type="text"
                            @click="_onClickPrintBtn"
                        >
                            <i class="fas fa-print" />
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button
                            type="primary"
                            size="default"
                            @click="_onArrivedClickBtn"
                        >
                            Приехали
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card
            v-if="isCurrentHaveArrivedCard"
            shadow="always"
        >
            <div class="participant-actions-popup-arrived-dialog">
                <div class="participant-actions-popup-arrived-dialog__text">
                    Деньги взяли?
                </div>
                <el-button
                    type="primary"
                    class="participant-actions-popup-arrived-dialog__agree-btn"
                    @click="_onMoneyTakenBtn"
                >
                    Да
                </el-button>
            </div>
            <div class="participant-actions-popup-arrived-dialog__toolbar">
                <el-button
                    type="text"
                    @click="_onBackToSelectedParticipantsBtn"
                >
                    <i class="participant-actions-popup-arrived-dialog__btn-back el-icon-back" />
                </el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import _ from 'lodash';
import ROUTES from '@/enums/routes';
import { mapState, mapMutations, mapActions } from 'vuex';
import { Draggable } from 'draggable-vue-directive';
import windowSizeHandler from '@/mixins/windowSizeHandler';
import CARDS from '../../enums/dialogCards';
import JSONStorage from '@/utils/storage';
import { PARTICIPANTS } from '@/enums';

export default {
    name: 'ParticipantsActionsPopup',

    directives: {
        Draggable
    },

    mixins: [
        windowSizeHandler
    ],

    props: {
        participants: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            currentCard: CARDS.SELECTED_PARTICIPANT_CARD,
            isVisible: false,
            isArrivedDialogVisible: false,
            draggableOptions: {
                initialPosition: {
                    left: window.innerWidth / 3,
                    top: window.innerHeight / 4
                }
            }
        };
    },

    computed: {

        ...mapState('participant', [
            'statusList'
        ]),

        ...mapState('event/participants', [
            'selectedParticipants',
            'participantPage'
        ]),

        ...mapState('event', {
            event: 'resource',
            eventsToMove: 'eventsToMove'
        }),

        isCurrentParticipantsCard() {
            return this.currentCard === CARDS.SELECTED_PARTICIPANT_CARD;
        },

        isCurrentHaveArrivedCard() {
            return this.currentCard === CARDS.HAVE_ARRIVED_CARD;
        },

        eventId() {
            return this.$prop('event.id');
        },

        style() {
            return {
                minWidth: '500px'
            };
        },

        cardStyles() {
            return {
                maxHeight: '600px'
            };
        },

        myData() {
            return [
                {
                    discount: this.selectedParticipantsTotalDiscount,
                    debt: this.selectedParticipantsTotalDebt
                }
            ];
        },

        selectedParticipantsTotalDiscount() {
            return this.selectedParticipants.reduce((acc, participant) => {
                return acc + parseFloat(_.get(participant, 'relationships.summaryTransactions.discount', 0));
            }, 0);
        },

        selectedParticipantsTotalDebt() {
            return this.selectedParticipants.reduce((acc, participant) => {
                return acc + parseFloat(_.get(participant, 'relationships.summaryTransactions.debt', 0));
            }, 0);
        },

        participantsListPrepared() {
            return this.participants.map(participant => {
                return {
                    id: participant.id,
                    fullname: participant.attributes.fullname,
                    age: participant.attributes.age,
                    discount: parseInt(_.get(participant, 'relationships.summaryTransactions.discount', 0)),
                    debt: parseInt(_.get(participant, 'relationships.summaryTransactions.debt', 0))
                };
            });
        },

        paricipantStatuses() {
            let statusList = _.defaultTo(this.statusList, []);
            return statusList.map(value => {
                return {
                    id: value.id,
                    title: value.attributes.title
                };
            });
        }
    },

    watch: {
        participants() {
            this._checkIfVisible();
            this.currentCard = CARDS.SELECTED_PARTICIPANT_CARD;
        }
    },

    mounted() {
        this._checkIfVisible();
    },

    methods: {

        ...mapActions('event/participants', [
            'updateStatus',
            'getParticipants',
            'moveParticipantsToEvent'
        ]),

        ...mapActions('participant', [
            'participantsArrived'
        ]),

        ...mapMutations('event/participants', [
            'setSelectedParticipants',
            'removeParticipant',
            'setParticipants'
        ]),

        _onEventsToMoveChanged(eventId) {
            let participants = this.selectedParticipants.map(p => p.id);
            const onSuccess = (response) => {
                if (Array.isArray(response) && response.length > 0) {
                    this.$router.push({
                        name: ROUTES.EVENT.CARD.HOTEL,
                        params: { eventId }
                    });
                } else {
                    this.$message.warning('Гости уже были перенесены на выбранный фестиваль');
                }
            };
            this.moveParticipantsToEvent({ eventId, participants })
                .then(onSuccess)
                .catch(e => this.$message.error(e));
        },

        _onArrivedClickBtn() {
            this.currentCard = CARDS.HAVE_ARRIVED_CARD;
        },

        _onClickPrintBtn() {
            this._prepareCheckingList();
            this._pushPrintPage();
        },

        _pushPrintPage() {
            let printRouter = this.$router.resolve({ name: ROUTES.PRINT.PARTICIPANTS.CHECKING_LIST });
            window.open(printRouter.href, '_blank');
        },

        _removeParticipant(participantId) {
            this.removeParticipant(participantId);
        },

        async _onStatusChanged(status) {
            let requestBody = this._prepareRequest(status);
            let response = await this.updateStatus(requestBody);
            response.success
                ? this.$message.success(response.data)
                : this.$message.error(response.data);
            this.getParticipants({
                eventId: this.eventId,
                page: this.participantPage
            });
        },

        _prepareRequest(status) {
            let participants = this.selectedParticipants.map(value => {
                return value.id;
            });
            return {
                participants,
                status
            };
        },

        _onCheckInClick() {
            this.$router.push({
                name: ROUTES.EVENT.CARD.HOTEL
            });
        },

        $resized({ windowWidth, windowHeight }) {
            this.draggableOptions.initialPosition = {
                left: windowWidth / 2,
                top: windowHeight / 2
            };
        },

        _nope() {
            this.$message('Не готово');
        },

        _close() {
            this._resetSelection();
        },

        _resetSelection() {
            this.setSelectedParticipants({ selectedParticipants: [] });
        },

        _onBackToSelectedParticipantsBtn() {
            this.currentCard = CARDS.SELECTED_PARTICIPANT_CARD;
        },

        _checkIfVisible() {
            this.isVisible = !!this.participants.length && this.participants.length >= 1;
        },

        async _onMoneyTakenBtn() {
            let participants = this.selectedParticipants.map(item => {
                return {
                    id: item.id
                };
            });
            let response = await this.participantsArrived({ participants });
            if (response.success) {
                this._prepareCheckingList();
                this.getParticipants({
                    eventId: this.eventId,
                    page: this.participantPage
                });
                this._reset();
                // Должно быть в самом конце
                this._pushPrintPage();
            } else {
                this.$message.error('Ошибка');
            }
        },

        /**
         * Подготавливаем лист для печати бланка
         * @private
         */
        _prepareCheckingList() {
            let checkingList = _(this.selectedParticipants)
                .filter(participant => _.has(participant, 'relationships.checking'))
                .filter(participant => _.get(participant, 'attributes.status') !== PARTICIPANTS.STATUSES.REFUSED)
                .map(participant => {
                    return {
                        fullname: participant.attributes.fullname,
                        building: participant.relationships.checking.building,
                        room: participant.relationships.checking.room
                    };
                })
                .groupBy('room')
                .value();
            JSONStorage.put('checkingList', checkingList);
        },

        _reset() {
            this.currentCard = CARDS.SELECTED_PARTICIPANT_CARD;
            this.setSelectedParticipants({
                selectedParticipants: []
            });
        },
        onClose() {
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.participant-actions-popup {
  z-index: 1000;

  &-totalrow {
    &__debt {
      font-size: 22px;
      font-weight: normal;
    }
  }

  .el-table .cell {
    word-break: normal;
  }

  &__toolbar {
    margin: 30px 0 -20px 0;
    max-width: 440px;
  }

  &__dropdown {
    margin: 0 3px;
  }

  &-arrived-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    flex-direction: column;

    &__text {
      font-size: 36px;
      margin-bottom: 45px;
    }

    &__agree-btn {
      width: 100px;

      span {
        font-size: 18px;
      }
    }

    &__btn-back {
      font-size: 40px;
      color: #f00;
      margin-left: 10px;
      font-weight: bold;
      cursor: pointer;

    }
  }
}
</style>
