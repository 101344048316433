<template>
    <div class="schedule-time-select">
        <el-time-select
            v-model="model"
            :clearable="false"
            prefix-icon="none"
            :picker-options="options"
            placeholder="Время"
        />
    </div>
</template>

<script>
export default {
    name: 'ScheduleTimeSelect',

    props: {
        value: {
            type: String,
            default: null
        },

        options: {
            type: Object,
            default: () => ({
                step: '00:05',
                start: '00:00',
                end: '23:55'
            })
        }
    },

    data() {
        return {
            model: null
        };
    },

    watch: {
        model() {
            this.$emit('input', this.model);
        },

        value() {
            if (this.value !== this.model) {
                this.model = this.value;
            }
        }
    },

    mounted() {
        this.model = this.value;
    }
};
</script>

<style lang="scss">
@import "@vars";

.schedule-time-select {
    display: block;
    max-width: 100%;

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: auto;
        max-width: 100%;
    }

    .el-input__inner {
        padding: 0 15px !important;
    }
}
</style>
