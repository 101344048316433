<template>
<!--    eslint-disable-->
    <div
        :style="style"
        class="event-selected-participants-indicator"
        v-html="content"
    />
<!--    eslint-enable-->
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'EventSelectedParticipantsIndicator',

    data() {
        return {
            boundedMouseMoveListener: null,
            x: 0,
            y: 0
        };
    },

    computed: {
        ...mapState('event/participants', [
            'selectedParticipants',
            'reCheckInMode'
        ]),

        style() {
            return {
                transform: `translate3d(${this.x + 5}px, ${this.y + 5}px, 0)`
            };
        },

        content() {
            const mapper = x => {
                let prevCheckin = _.get(x, 'attributes.previousCheckin', '') + ' ';
                let fullname = _.get(x, 'attributes.fullname', '');
                let age = _.get(x, 'attributes.age', '');
                return `${prevCheckin}${fullname}, ${age}`;
            };

            let items = this.$prop('selectedParticipants', []);
            let sorter = (a, b) => {
                let ageA = _.get(a, 'attributes.age', 0);
                let ageB = _.get(b, 'attributes.age', 0);
                return ageA > ageB ? -1 : ageA < ageB ? 1 : 0;
            };
            items.sort(sorter);

            let mode = this.reCheckInMode ? 'Переселить' : 'Заселить';
            let isMoveToEventMode = Boolean(_.get(items[0], 'attributes.previousCheckin', false));
            if (isMoveToEventMode) {
                mode = 'ПЕРЕСЕЛЯЕМ';
            }
            let list = items.map(mapper)
                .join(`<br>`);
            return `${mode}: </br> ${list}`;
        }
    },

    mounted() {
        this.boundedMouseMoveListener = this._mouseMoveListener.bind(this);
        window.addEventListener('mousemove', this.boundedMouseMoveListener);
    },

    destroyed() {
        window.removeEventListener('mousemove', this.boundedMouseMoveListener);
    },

    methods: {
        _mouseMoveListener(event) {
            this.x = event.clientX;
            this.y = event.clientY;
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-selected-participants-indicator {
    padding: 6px 15px;
    color: #fff;
    background: rgba(0, 0, 0, .9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    border-radius: 3px;
    font-size: 12px;
    line-height: 18px;
}
</style>
