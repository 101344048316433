<template>
    <el-row
        type="flex"
        justify="center"
        align="middle"
    >
        <el-col
            :span="5"
            :offset="1"
        >
            <div> {{ localForm.fullname }}</div>
        </el-col>
        <el-col :span="16">
            <el-form
                :model="form"
                :disabled="formDisabled"
            >
                <el-form-item>
                    <el-col
                        :span="8"
                        :offset="2"
                    >
                        <el-form-item
                            label="Прибытие"
                            prop="arrivalTransferPlaceId"
                        >
                            <el-select
                                v-model="localForm.arrival_transfer_place_id"
                            >
                                <el-option
                                    v-for="place in transferPlaces"
                                    :key="place.id"
                                    :label="place.name"
                                    :value="place.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="8"
                        :offset="2"
                    >
                        <el-form-item
                            label="Отбытие"
                            prop="departureTransferPlaceId"
                        >
                            <el-select
                                v-model="localForm.departure_transfer_place_id"
                            >
                                <el-option
                                    v-for="place in transferPlaces"
                                    :key="place.id"
                                    :label="place.name"
                                    :value="place.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col
                        :span="8"
                        :offset="2"
                    >
                        <el-form-item
                            label="Во сколько"
                            prop="arrivalTime"
                        >
                            <flat-pickr
                                v-model="localForm.arrival_time"
                                placeholder="        :        "
                                class="el-input__inner app-lk-participant-modal__date-input"
                                :config="timePickerConfig"
                                :disabled="formDisabled"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="8"
                        :offset="2"
                    >
                        <el-form-item
                            label="Во сколько"
                            prop="departureTime"
                        >
                            <flat-pickr
                                v-model="localForm.departure_time"
                                :disabled="formDisabled"
                                placeholder="        :        "
                                class="el-input__inner app-lk-participant-modal__date-input"
                                :config="timePickerConfig"
                            />
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="5">
            <el-form>
                <el-switch
                    v-model="localForm.status"
                    active-color="#13ce66"
                    active-text="Записан"
                    inactive-color="#ff4949"
                    inactive-text="Не нужен"
                    :active-value="activeValue"
                    :inactive-value="refusedValue"
                />
            </el-form>
        </el-col>
    </el-row>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { mapState } from 'vuex';

const BOOKED_STATUS = 'BOOKED';
const REFUSED_STATUS = 'REFUSED';

const TRANSFER_NOT_NEEDED = 'TRANSFER_NOT_NEEDED';

export default {
    name: 'EditTransferFormItem',
    components: {
        flatPickr
    },
    model: {
        prop: 'form',
        event: 'change'
    },
    props: {
        form: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            timePickerConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i'
            },
            localForm: { ...this.form }
        };
    },
    computed: {
        ...mapState('transfer', [
            'places'
        ]),
        transferPlaces() {
            let places = this.places.map(item => ({
                id: item.id,
                name: item.name
            }));
            places.push({
                id: TRANSFER_NOT_NEEDED,
                name: 'Не нужен'
            });
            return places;
        },
        formDisabled() {
            return this.localForm.status !== BOOKED_STATUS;
        },
        activeValue() {
            return BOOKED_STATUS;
        },
        refusedValue() {
            return REFUSED_STATUS;
        }
    },
    watch: {
        localForm: {
            handler: function(newValue) {
                const formData = { ...newValue };
                if (formData.arrival_transfer_place_id === TRANSFER_NOT_NEEDED) {
                    formData.arrival_transfer_place_id = null;
                    formData.arrival_time = null;
                }

                if (formData.departure_transfer_place_id === TRANSFER_NOT_NEEDED) {
                    formData.departure_transfer_place_id = null;
                    formData.departure_time = null;
                }
                this.$emit('change', formData);
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">

</style>
