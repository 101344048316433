<template>
    <div class="event-service-selector">
        <el-dropdown
            trigger="click"
            placement="bottom"
            @command="select"
            @visible-change="_onToggle"
        >
            <div class="event-service-selector__link">
                <!--eslint-disable-->
                <span v-html="label" />
                <!--eslint-enable-->
                <i
                    class="event-service-selector__icon"
                    :class="{'el-icon-arrow-up': isOpened, 'el-icon-arrow-down':!isOpened}"
                />
            </div>
            <el-dropdown-menu
                slot="dropdown"
                class="event-service-selector-menu"
            >
                <el-dropdown-item
                    v-for="item in preparedItems"
                    :key="item.id"
                    :command="item"
                    :divided="item.divided"
                >
                    <el-row
                        :gutter="0"
                        class="event-service-selector-item"
                    >
                        <el-col :span="12">
                            <div class="event-service-selector-item__title">
                                {{ item.serviceTitle }}
                            </div>
                        </el-col>
                        <el-col :span="7">
                            <div class="event-service-selector-item__specialist">
                                {{ item.specialistName }}
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="event-service-selector-item__price">
                                {{ item.price }}
                            </div>
                        </el-col>
                    </el-row>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import _ from 'lodash';
import { Event } from '@/api';
import showError from '@/utils/showError';

/**
 * @typedef {Object} ServiceSelectorItem
 * @property {String} id
 * @property {String} group
 * @property {Boolean} divided
 * @property {String} serviceTitle
 * @property {String} price
 * @property {String} specialistName
 * @property {UUID} specialistId
 * @property {UUID} serviceId
 * @property {ServiceSelectorItemResource} item
 */

export default {
    name: 'EventServiceSelector',

    components: {},

    props: {
        eventId: {
            type: String,
            default: null
        },

        placeholder: {
            type: String,
            default: 'Выберите услугу'
        }
    },

    data() {
        return {
            /**
             * @type {ServiceSelectorItemResource[]}
             */
            items: [],
            isOpened: false,
            /**
             * @type {ServiceSelectorItem | null}
             */
            selectedItem: null
        };
    },

    computed: {
        label() {
            if (this.selectedItem) {
                return [
                    this.selectedItem.serviceTitle,
                    ' &mdash; ',
                    this.selectedItem.specialistName
                ].join('');
            }
            return this.placeholder;
        },

        /**
         * @returns {ServiceSelectorItem}
         */
        preparedItems() {
            /**
             * @param {String} priceString
             */
            const priceMapper = priceString => {
                let price = parseFloat(priceString);
                if (price === 0) {
                    price = 'Бесплатно';
                }
                return price;
            };
            let lastGroup = null;
            /**
             * @param {ServiceSelectorItemResource} item
             */
            const mapper = item => {
                let prices = _.get(item, 'relationships.prices', []);
                let price = prices.map(priceMapper)
                    .join(' / ');
                return {
                    item,
                    price,
                    id: item.id,
                    group: _.get(item, 'attributes.group'),
                    specialistName: _.get(item, 'relationships.specialist.attributes.name', 'Без имени'),
                    serviceTitle: _.get(item, 'relationships.service.attributes.title', 'Без названия'),
                    specialistId: _.get(item, 'relationships.specialist.id'),
                    serviceId: _.get(item, 'relationships.service.id')
                };
            };
            return (Array.isArray(this.items) ? this.items : [])
                .map(mapper)
                .sort(function(a, b) {
                    return a.group > b.group ? 1 : a.group === b.group ? 0 : -1;
                })
                .map(item => {
                    item.divided = lastGroup && lastGroup !== item.group;
                    lastGroup = item.group;
                    return item;
                });
        }
    },

    watch: {
        eventId() {
            this.loadData();
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        loadData: _.debounce(function() {
            if (!this.eventId) {
                return;
            }
            /**
             * @param {ServiceSelectorItemResource[]} items
             */
            const onResponse = items => {
                this.items = items;
            };
            Event.Service.Schedule.getServiceSelector({ eventId: this.eventId })
                .then(onResponse)
                .catch(showError('Не удалось загрузить данные для выбора услуги'));
        }, 200),

        /**
         * @param {ServiceSelectorItem} item
         */
        select(item) {
            this.$emit('select', { item });
            this.selectedItem = item;
        },

        _onToggle(isOpened) {
            this.isOpened = isOpened;
        }
    }
};
</script>

<style lang="scss">
.event-service-selector-menu {
  border-radius: 0;
  border: none;
  padding: 19px 0;
  height: 300px;
  overflow: auto;

  .popper__arrow {
    display: none;
  }

  .el-dropdown-menu__item {
    padding: 0 22px;

    &--divided {
      border-top: 1px solid #979797;
    }
  }
}

.event-service-selector {

  &__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate3d(0, -50%, 0);
    font-size: 20px;
    color: #d8d8d8;
  }

  &__link {
    margin: 14px 0 0 6px;
    position: relative;
    border-bottom: 2px solid #eee;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 500px;
    font-size: 18px;
    line-height: 23px;
    color: #000;
    padding: 0 30px 0 10px;
    outline: none;
    cursor: pointer;
  }

  &-item {
    color: #000;
    width: 560px;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 0;
    margin: 0;

    &__title {
    }

    &__price {
    }
  }
}
</style>
