<template>
    <div class="event-service-specialists">
        <h1 class="app-h1">
            Список специалистов
        </h1>
        <el-transfer
            v-model="specialistIds"
            v-loading="isLoading"
            filterable
            filter-placeholder="ФИО Специалиста"
            :titles="['Недоступные на событии', 'Доступные на событии']"
            :data="transferData"
            @change="_onTransferChange"
        />
        <br>
        <el-alert
            show-icon
            :closable="false"
            type="info"
            :title="infoText"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'EventServiceSpecialists',

    components: {},

    props: {
        eventId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            /** @type {UUID[]} */
            specialistIds: [],
            isLoading: false
        };
    },

    computed: {
        ...mapState('event/service', [
            'specialists'
        ]),

        ...mapState('event/service/specialist', [
            'allSpecialists'
        ]),

        transferData() {
            /**
             * @param {SpecialistResource} specialist
             * @return {Object}
             */
            const mapper = specialist => ({
                key: specialist.id,
                label: specialist.attributes.name
            });
            return Array.isArray(this.allSpecialists) ? this.allSpecialists.map(mapper) : [];
        },

        infoText() {
            return `Если специалист не перенесен в колонку "Доступные на событии",
            его нельзя будет выбрать при создании расписаний.`;
        }
    },

    watch: {
        specialists() {
            this._buildSpecialistIds();
        }
    },

    mounted() {
        this.isLoading = true;
        Promise.all([
            this._loadAllSpecialists(),
            this._loadEventSpecialists()
        ]).then(() => {
            this.isLoading = false;
        });
    },

    methods: {
        ...mapActions('event/service', [
            'getEventSpecialists'
        ]),

        ...mapMutations('event/service', [
            'setSpecialists'
        ]),

        ...mapActions('event/service/specialist', [
            'getAllSpecialists',
            'setEventSpecialists'
        ]),

        ...mapMutations('event/service/specialist', [
            'setAllSpecialists'
        ]),

        _save: _.debounce(function() {
            this.setEventSpecialists({
                eventId: this.eventId,
                specialistIds: this.specialistIds
            });
        }, 200),

        _buildSpecialistIds() {
            /** @param {EventSpecialistResource} specialist */
            const mapper = specialist => specialist.attributes.specialist_id;
            this.specialistIds = this.specialists.map(mapper);
        },

        _loadEventSpecialists() {
            return this.getEventSpecialists({ eventId: this.eventId });
        },

        _loadAllSpecialists() {
            return this.getAllSpecialists();
        },

        _onTransferChange() {
            this._save();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-service-specialists {
    .el-transfer {
        @include flex-row-centered;
    }

    .el-transfer-panel {
        flex: 10;
    }

    .el-transfer__buttons {
        width: 36px;
    }
}

</style>
