<template>
    <div
        class="schedule-add-event-button"
        @click="$emit('click')"
    >
        +
    </div>
</template>

<script>
export default { name: 'AddScheduleButton' };
</script>

<style lang="scss">
.schedule-add-event-button {
    cursor: pointer;
    padding: 0;
    display: block;
    border-radius: 4px;
    background: #e6f1fc;
    border: 1px solid #a3d0fd;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #a3d0fd;

    &:hover {
        background: #dfe9f4;
        border: 1px solid #a0ccf8;
        color: #95bfe8;
    }
}
</style>
