export default {
    data() {
        return {
            $_boundResizeHandler: null
        };
    },

    mounted() {
        this.$_boundResizeHandler = this.$_resizeHandler.bind(this);
        window.addEventListener('resize', this.$_boundResizeHandler);
    },

    destroyed() {
        window.removeEventListener('resize', this.$_boundResizeHandler);
    },

    methods: {
        $resized({ windowWidth, windowHeight }) {
        },

        $_resizeHandler() {
            const windowWidth = window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            const windowHeight = window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;
            this.$resized({ windowWidth, windowHeight });
        }
    }
};
