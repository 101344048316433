<template>
    <el-card
        class="stats-box-card"
        shadow="hover"
    >
        <div class="stats-box-card__title">
            Скачать
        </div>
        <el-button
            type="primary"
            @click="_onListBtnClick"
        >
            Список участников в XLS
        </el-button>
    </el-card>
</template>

<script>
import api from '@/api/managerApi';
import ROUTES from '@/enums/routes';
import CONFIG_ROUTES from '@/config';
import urlBuilder from '@/utils/urlBuilder';

export default {
    name: 'StatsExports',
    props: {
        eventId: {
            type: String,
            default: null
        }
    },
    computed: {
        exportXlsLink() {
            return CONFIG_ROUTES.API_HOST + '/api' + urlBuilder(ROUTES.EVENT.API.EXPORTS.XLS, {
                eventId: this.eventId
            });
        }
    },
    methods: {
        _onListBtnClick() {
            api({
                url: this.exportXlsLink,
                method: 'GET',
                responseType: 'blob' // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'participants.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        }
    }
};
</script>

<style scoped>

</style>
