<template>
    <transition
        building-cart-wrapper__animation
        @after-enter="_onEnter"
    >
        <div
            v-if="show"
            ref="inner"
            class="building-cart-wrapper__inner"
        >
            <div class="building-cart-wrapper__header">
                <i
                    class="building-cart-wrapper__icon-close fas fa-times"
                    @click="_onCloseComponent"
                />
            </div>
            <div class="building-cart-wrapper__inner-container">
                <event-hotel-building-card />
            </div>
        </div>
    </transition>
</template>

<script>
import EventHotelBuildingCard from '@/modules/event/components/card/hotel/EventHotelBuildingCard';
export default {
    name: 'BuildingCardWrapper',
    components: { EventHotelBuildingCard },
    data() {
        return {
            show: false
        };
    },
    methods: {
        _showComponent() {
            this.show = true;
        },
        _hideComponent() {
            this.show = false;
        },
        _componentStatus() {
            return this.show;
        },
        _onCloseComponent() {
            this.$emit('component-close');
        },
        _onEnter(el, done) {
            this.$emit('component-ready');
        }
    }
};
</script>

<style lang="scss">
.building-cart-wrapper {
  &__inner {
    border: 1px solid #DADADA;
    border-radius: 6px;
  }
  &__header {
    background-color: #F5F7FA;
    border-radius: 6px 6px 0 0;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
  &__inner-container {
    background-color: #F5F7FA;
    padding: 10px;
  }
  &__icon-close {
    cursor: pointer;
    width: 26px;
    height: 26px;
    font-size: 26px;
  }
  &__animation {
    &-enter-active {
      transition: all .3s ease;
    }

    &-leave-active {
      transition: all .3s ease;
    }

    &-enter,
    &-leave-to {
      transform: translateY(-10px);
      opacity: 0;
    }
  }
}
</style>
