import _ from 'lodash';

/**
 * Вернет номер лидера группы, если у участника номера нет.
 *
 * @param {ParticipantResource} p
 * @return {{phone: String, isGroupLeaderPhone: Boolean} | null}
 */
export const getParticipantActualPhone = p => {
    if (!p) {
        return null;
    }
    const phone = _.get(p, 'attributes.phone');
    const phone2 = _.get(p, 'attributes.phone2');
    const groupLeaderPhone = _.get(p, 'attributes.group_leader_phone');
    return phone
        ? { phone, isGroupLeaderPhone: false }
        : phone2
            ? { phone: phone2, isGroupLeaderPhone: false }
            : { phone: groupLeaderPhone || '', isGroupLeaderPhone: true };
};
