<template>
    <div>
        <div class="schedule-participant-enrol-list">
            <div class="schedule-participant-enrol-list-header">
                <div class="schedule-participant-enrol-list-header__title">
                    {{ title }}
                </div>
                <a
                    v-if="hasItems"
                    class="schedule-participant-enrol-list-header__icon"
                    @click="_printList"
                >
                    <i class="fas fa-print" />
                </a>
            </div>
            <div class="schedule-participant-enrol-list-price">
                <template v-if="hasItems">
                    Всего: {{ totalPrice }}
                </template>
            </div>
            <div class="schedule-participant-enrol-list-items">
                <schedule-participant-enrol-list-item
                    v-for="item in list"
                    :key="item.id"
                    :item="item"
                    :is-selected="isItemSelected(item)"
                    @itemClick="_onItemClick(item)"
                    @removeFromSchedule="_onRemoveFromSchedule"
                />
            </div>
        </div>
        <div
            v-if="hasPastListItems"
            class="schedule-participant-enrol-list"
        >
            <div class="schedule-participant-enrol-list-header">
                <div class="schedule-participant-enrol-list-header__title">
                    {{ pastTitle }}
                </div>
            </div>
            <div class="schedule-participant-enrol-list-items">
                <schedule-participant-enrol-list-item
                    v-for="item in pastList"
                    :key="item.id"
                    :item="item"
                    @removeFromSchedule="_onRemoveFromSchedule"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import ScheduleParticipantEnrolListItem
    from '@/modules/event/components/card/service/schedule/ScheduleParticipantEnrolListItem';
import { mapState, mapActions } from 'vuex';
import JSONStorage from '@/utils/storage';
import ROUTES from '@/enums/routes';

export default {
    name: 'ScheduleParticipantEnrolList',
    components: {
        ScheduleParticipantEnrolListItem
    },

    data() {
        return {
            loading: false,
            list: [],
            pastList: [],
            selectedItems: [],
            cachedParticipantId: null
        };
    },

    computed: {
        ...mapState('event/service/schedule', [
            'participantInfo',
            'scheduleInfos'
        ]),

        participantId() {
            return this.$prop('participantInfo.id');
        },

        participantFullname() {
            return this.$prop('participantInfo.relationships.participant.attributes.fullname');
        },

        title() {
            return this.hasItems > 0 ? 'Уже записан' : 'Не записан';
        },

        pastTitle() {
            return 'Прошли';
        },

        hasItems() {
            return this.list.length > 0;
        },

        hasPastListItems() {
            return this.pastList.length > 0;
        },

        hasSelectedItems() {
            return this.selectedItems.length > 0;
        },

        eventId() {
            return this.$route.params.eventId;
        },

        totalPrice() {
            let price = 0;
            if (this.hasSelectedItems) {
                price = this.selectedItems.reduce((acc, item) => acc + item.plan.price, 0);
            } else if (this.hasItems) {
                price = this.list.reduce((acc, item) => acc + item.plan.price, 0);
            }
            return price > 0 ? price + ' ₽' : 'Бесплатно';
        }

    },

    mounted() {
        this._loadEnrollList();
        if (this.participantId) {
            this.cachedParticipantId = this.participantId;
            this.$echo.private('Participant.' + this.participantId)
                .listen('ParticipantRegisteredOnServiceEcho', (payload) => {
                    this.selectedItems = [];
                    this._prepareList(payload.data);
                })
            ;
        }
    },

    destroyed() {
        if (this.cachedParticipantId) {
            this.$echo.leave('Participant.' + this.cachedParticipantId);
        }
    },
    methods: {
        ...mapActions('participant', [
            'getEnrollList'
        ]),

        isItemSelected(item) {
            return _.some(this.selectedItems, item);
        },

        async _loadEnrollList() {
            this.selectedItems = [];
            if (this.participantId) {
                let response = await this.getEnrollList({ participantId: this.participantId });
                if (response.success) {
                    const data = response.data;
                    this._prepareList(data);
                }
            }
        },

        _prepareList(list) {
            const mapper = (item) => {
                return {
                    id: item.id,
                    dateStart: _.get(item, 'attributes.datetime_start'),
                    serviceTitle: _.get(item, 'relationships.service.attributes.title'),
                    location: _.get(item, 'relationships.location.attributes.title'),
                    specialists: _.get(item, 'relationships.specialists'),
                    isPast: _.get(item, 'attributes.is_past'),
                    plan: {
                        title: _.get(item, 'relationships.scheduleplan.attributes.title'),
                        price: parseInt(_.get(item, 'relationships.scheduleplan.attributes.price'), 10)
                    }
                };
            };
            const mappedList = list.map(mapper);
            this.list = mappedList.filter(item => Boolean(!item.isPast));
            this.pastList = mappedList.filter(item => Boolean(item.isPast));
        },

        _onRemoveFromSchedule(schedule) {
            this.selectedItems = [];
            this.$emit('unregisterFromSchedule', schedule.id);
        },

        _onItemClick(item) {
            this.selectedItems = _.xor(this.selectedItems, [item]);
        },

        _printList: _.debounce(function() {
            let list = this.hasSelectedItems ? this.selectedItems : this.list;
            JSONStorage.put('service-participant-enrollment', {
                list,
                participantFullname: this.participantFullname
            });
            let printRoute = this.$router.resolve({ name: ROUTES.PRINT.PARTICIPANTS.SERVICE_ENROLLMENT });
            setTimeout(() => window.open(printRoute.href, '_blank'), 150);
        }, 500)

    }

};
</script>

<style lang="scss">
.schedule-participant-enrol-list {
    border: 1px solid #d8dce6;
    border-radius: 4px;
    max-width: 264px;
    margin-bottom: 40px;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 25px;
        padding-top: 25px;


        &__title {
            font-size: 24px;
        }

        &__icon {
            padding-right: 18px;
            font-size: 18px;
            color: #1871f8;
            cursor: pointer;
        }
    }

    &-price {
        padding-left: 25px;
        padding-bottom: 25px;
    }

}
</style>
