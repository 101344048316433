<template>
    <div class="event-transfer-info">
        <h1 class="app-h1">
            Информация о трансфере
        </h1>
        <section v-loading="isLoading">
            <el-row
                type="flex"
                justify="left"
            >
                <el-col :span="8">
                    <el-radio-group
                        v-model="currentStatus"
                        @change="_onStatusChange"
                    >
                        <el-radio-button :label="statuses.BOOKED_STATUS">
                            Записались
                        </el-radio-button>
                        <el-radio-button :label="statuses.NOT_BOOKED_STATUS">
                            Не записались
                        </el-radio-button>
                        <el-radio-button :label="statuses.REFUSED_STATUS">
                            Не нужен
                        </el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="8">
                    <div class="event-transfer-info-filter">
                        <template v-if="currentStatus === statuses.BOOKED_STATUS">
                            <transfer-list-filter @filterApplyBtnClick="_onFilterApplyBtnClick" />
                            <transfer-list-print />
                        </template>
                        <transfer-list-download />
                    </div>
                </el-col>
                <el-col :span="8">
                    <el-input
                        v-model="searchText"
                        clearable
                        suffix-icon="el-icon-search"
                        @input="_onSearchTextChange"
                    />
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <transfer-list @transferUpdated="_onTransferUpdated" />
                    <div class="app-table__pager">
                        <el-pagination
                            layout="total, prev, pager, next, sizes"
                            :current-page.sync="participantTransferListMeta.currentPage"
                            :page-count="participantTransferListMeta.lastPage"
                            :page-size="Number.parseInt(participantTransferListMeta.perPage)"
                            :total="participantTransferListMeta.total"
                            :page-sizes="[15, 30, 40, 50]"
                            @size-change="_onPageSizeChange"
                            @current-change="_onPageChange"
                        />
                    </div>
                </el-col>
            </el-row>
        </section>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import { STATUSES } from './transfer/config';
import TransferList from '@/modules/event/components/card/transfer/TranfserList';
import TransferListFilter from '@/modules/event/components/card/transfer/TransferListFilter';
import TransferListPrint from '@/modules/event/components/card/transfer/TransferListPrint';
import TransferListDownload from '@/modules/event/components/card/transfer/TransferListDownload';

const defaultQuery = {
    page: 1,
    status: STATUSES.NOT_BOOKED_STATUS
};

export default {
    name: 'EventTransferInfo',
    components: {
        TransferListDownload,
        TransferListPrint,
        TransferListFilter,
        TransferList
    },
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            currentStatus: null,
            statuses: STATUSES,
            searchText: null
        };
    },
    computed: {
        ...mapState('event/transfer', [
            'participantTransferList',
            'participantTransferListMeta'
        ]),
        routeQuery() {
            return this.$route.query;
        }
    },
    watch: {
        routeQuery: {
            handler(query) {
                let status = query.status;
                if (!query.status) {
                    this._goToInitList();
                    return;
                }
                this.currentStatus = status;
                this._loadData();
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions('event/transfer', [
            'getParticipantTransferList'
        ]),
        ...mapMutations('event/transfer', [
            'setParticipantTransferList'
        ]),
        _loadData() {
            this.isLoading = true;
            this.getParticipantTransferList({
                eventId: this.$route.params.eventId,
                params: this.$route.query
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        _onTransferUpdated() {
            this._loadData();
        },
        _onStatusChange(status) {
            this.setParticipantTransferList([]);
            const query = { ...defaultQuery, status };
            this.$router.push({ query });
            this.searchText = null;
        },
        _goToInitList() {
            this.$router.push({ query: { ...defaultQuery } });
        },
        _onSearchTextChange: _.debounce(function(search) {
            let query = { ...this.$route.query, page: 1 };
            if (search) {
                query.search = search;
                this.$router.push({ query });
                return;
            }
            delete query.search;
            this.$router.push({ query });
        }, 500),
        _onPageSizeChange(pageSize) {
            this.$router.push({ query: { ...this.$route.query, limit: pageSize, page: 1 } });
        },
        _onPageChange(page) {
            this.$router.push({ query: { ...this.$route.query, page } });
        },
        _onFilterApplyBtnClick() {
            this._loadData();
        }
    }
};
</script>
<style lang="scss">
.event-transfer-info {
  max-width: 1200px;

  &__top-button {
    margin: 0 10px;
  }

  &-filter {
    display: flex;
    justify-content: left;
  }
}
</style>
