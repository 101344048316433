<template>
    <el-form
        :rules="rules"
        :model="form"
        label-position="top"
        @submit.native.prevent="_send"
    >
        <el-input
            v-model="form.id"
            type="hidden"
        />
        <el-row :gutter="24">
            <el-col :span="7">
                <el-form-item
                    prop="title"
                    label="Тип участия"
                >
                    <el-input
                        v-model="form.title"
                        @change="_onFormChange"
                    />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item
                    prop="default_price"
                    label="Стоимость"
                >
                    <el-input-number
                        v-model="form.default_price"
                        :step="1000"
                        @change="_onFormChange"
                    />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item
                    prop="default_places"
                    label="Мест"
                >
                    <el-input-number
                        v-model="form.default_places"
                        type="number"
                        @change="_onFormChange"
                    />
                </el-form-item>
            </el-col>
            <el-col :span="2">
                <div style="padding-top: 50px;">
                    <el-tooltip content="Добавить описание типа участия">
                        <el-button
                            :type="isDescriptionVisible ? 'primary' : 'default'"
                            icon="el-icon-document"
                            @click="_toggleDescription"
                        />
                    </el-tooltip>
                </div>
            </el-col>
            <el-col :span="2">
                <div style="padding-top: 50px;">
                    <el-tooltip content="Удалить">
                        <el-button
                            icon="el-icon-delete"
                            @click="_remove"
                        />
                    </el-tooltip>
                </div>
            </el-col>
            <el-col
                v-if="isDescriptionVisible"
                :span="24"
            >
                <el-form-item
                    prop="title"
                    label="Описание"
                >
                    <el-input
                        v-model="form.default_description"
                        type="textarea"
                        :rows="2"
                        @change="_onFormChange"
                    />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>

const emptyForm = {
    id: null,
    title: null,
    default_description: null,
    default_price: 0,
    default_places: 1
};

export default {
    name: 'EventServicePlanFormItem',

    props: {
        index: {
            type: Number,
            default: 0
        },
        isFirst: Boolean,
        isLast: Boolean,
        value: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            isDescriptionVisible: false,
            form: { ...emptyForm }
        };
    },

    computed: {
        rules() {
            return {
                default_price: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value < 0) {
                                return callback(
                                    new Error('Укажите стоимость')
                                );
                            }
                            callback();
                        }
                    }
                ],
                default_places: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value <= 0) {
                                return callback(
                                    new Error('Число мест должно быть больше нуля')
                                );
                            }
                            callback();
                        }
                    }
                ]
            };
        }
    },

    watch: {
        value() {
            this._fillData();
        }
    },

    mounted() {
        this._fillData();
    },

    methods: {
        _onFormChange() {
            this.$emit('input', { ...this.form });
        },

        _toggleDescription() {
            this.isDescriptionVisible = !this.isDescriptionVisible;
            if (!this.isDescriptionVisible) {
                this.form.default_description = null;
            }
        },

        _remove() {
            this.$emit('remove');
        },

        _fillData() {
            const value = this.value;
            if (!value) {
                this.form = { ...emptyForm };
                return;
            }
            this.form = {
                id: value.id,
                title: value.title,
                default_description: value.default_description,
                default_price: value.default_price,
                default_places: value.default_places
            };
            this.isDescriptionVisible = Boolean(this.form.default_description);
        }
    }
};
</script>
